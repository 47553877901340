import axios from "axios";
import Config from "../config";

const postClose = ({meetingID}) => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/meeting/close",
        data: { meetingID }
    });
};

export default postClose;
