import React, {useEffect, useState} from 'react';
import Div100vh from 'react-div-100vh';
import CreateGroup from "../../features/Group/Create";
import CreateGroup2 from "../../features/Group/CreateDoubtRoom";
import Panel from "../../features/Panel/doubtspanel";
import Nav from "../../features/Nav/Nav";
import Details from "../../features/Details";
import "./Home.sass";
import {useGlobal, getGlobal, setGlobal} from "reactn";
import Conversation from "../../features/Conversation";
import Meeting from "../../features/Meeting";
import Welcome from "../../features/Welcome";
import NotFound from "../../features/NotFound";
import {Route, Switch, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Admin from "../../features/Admin";
import Logo from '../../img/Logo.svg'
import Concept from '../../img/Concepts.svg'
import { useToasts } from "react-toast-notifications";
const DoubtsHome = () => {
    const location = useLocation();
    const [over, setOver] = useGlobal('over');
    const showPanel = useGlobal('showPanel')[0];
    const showDetails = useGlobal('showDetails')[0];
    const panel = useGlobal('panel')[0];
    const callIncrement = useSelector(state => state.rtc.callIncrement);
    const callData = useSelector(state => state.rtc.callData);
    const [user, setUser] = useGlobal('user');
    const setToken = useGlobal('token')[1];
    const { addToast } = useToasts();
    const history = useHistory();

    const onLogoutClick = async () => {
        const username = user.username;
        localStorage.removeItem('token');
        await setToken(null);
        await setUser({});
        addToast(`User ${username} logged out!`, {
            appearance: 'success',
            autoDismiss: true,
        })
        history.push("/login");
    }

    useEffect(() => {
        if (!callData) return;
        setGlobal({
            audio: true,
            video: false,
            callDirection: 'incoming',
            meeting: {_id: callData.meetingID},
        }).then(() => {
            history.replace('/meeting/' + callData.meetingID);
        });
    }, [callIncrement, callData])

    useEffect(() => {
        if (location.pathname !== '/') setOver(true);
    }, [location]);

    const getPanel = () => {
        switch (panel) {
            case 'createGroup':
                return <CreateGroup/>
            case 'createGroup2':
                return <CreateGroup2/>
            default:
                return <Panel/>
        }
    }

    return (
        <Div100vh>
            <nav className="uk-navbar-container " uk-navbar="mode: click" style={{ backgroundColor: '#15388C' }}>
                <div className="uk-navbar-left">

                    <ul className="uk-navbar-nav">
                        <li className="uk-active">
                            <a href="#">
                                <div className="logo1" style={{ paddingRight: 18 }}>
                                    <img src={Concept} />
                                </div>
                            </a>
                        </li>
                        <li className="uk-active">
                            <a href="#">
                                <div className="col-md-6" style={{ paddingRight: 70 }}>
                                    <img src={Logo} />
                                </div>
                            </a>
                        </li>
                        <li className="uk-active">
                            <a href="#">
                                {/* <input className="uk-input uk-form-width-large" type="text" placeholder="Search here..." /> */}
                            </a>
                        </li>
                    </ul>

                </div>
                <div className="uk-navbar-right">
                    <ul className="uk-navbar-nav">
                        <li>
                            <a href="#"><img src={'https://randomuser.me/api/portraits/men/64.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com" /></a>
                            <div className="uk-navbar-dropdown">
                                <ul className="uk-nav uk-navbar-dropdown-nav">
                                    <li className="uk-active"><a href="#" onClick={onLogoutClick}>Logout</a></li>
                                    {/* <li><a href="#">Item</a></li>
                                    <li><a href="#">Item</a></li> */}
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
            
            <div className="app">
                <Nav />
                {showPanel && getPanel()}
                <div className={`main uk-flex uk-flex-column${over ? ' over' : ''}${over === false ? ' exit' : ''}`}>
                    <Switch>
                        <Route exact path="/doubts" component={Welcome} />
                        <Route exact path="/admin" component={Admin} />
                        <Route exact path="/meeting/:id" component={Meeting} />
                        <Route exact path="/doubts/:id" component={Conversation} />
                        <Route exact path="/doubts/:id/info" component={Details} />
                        <Route path="/" component={NotFound} />
                    </Switch>
                </div>
                {!location.pathname.endsWith('/info') && (showDetails || !location.pathname.startsWith('/meeting')) && <Details/>}
            </div>
        </Div100vh>
    );
}

export default DoubtsHome;
