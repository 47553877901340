import React from 'react';
import { Card } from 'antd';
import QuestionOptionNew from './QuestionOptionNew';
import edit from 'img/edit.svg';
import maximize from 'img/maximize.svg';
import clock from 'img/clock.svg';

function QuestionCardNew({ question, ...props }) {
    const isAnswer = (option) => {
        switch (question.type) {
            case 't/f':
                return question?.answer == option?.option_shortName;
            case 'mcms':
                return !!question?.answer?.find(a => a?.option_name == option?.option_name);
            case 'mcq':
                return question?.answer?.option_name == option?.option_name;
            case 'subjective':
                return true;
            case 'fill in the blanks':
                return false;
            default:
                return true;
        }
    }

    const averageTime = () => {
        const selectedQuestionTime = props.participantsTimeTaken.map(a => a.userTime[props.index])
        const averageTime = (selectedQuestionTime.reduce((a, v) => a + v) / selectedQuestionTime.length)
        return `${parseInt(averageTime)/1000} sec`
    }

    const GetParticipantsDetails = () => {
        return props.participantsTimeTaken.map(a => (
            <>
                <tr>
                    <td>
                        {a.participant.name}
                    </td>
                    <td>
                        <span><img src={clock} />  {a.userTime[props.index]/1000} sec</span> 
                    </td>
                </tr>
                <br />
            </>
        ))
    }

    return (
        <>
            <div className="main_question">
                <div className="main_ques_heading">
                    <h3>{`${question?.sequence || 0}. ${question?.questionText || 'NA'}`}
                        {/* <button><img src={edit} />Maximize</button> */}
                    </h3>
                    <button className="uk-button ques_popup" type="button"  data-uk-toggle="target:#modal-center"><img src={maximize} /> <span>Maximize</span></button>
                </div>
                <table className="uk-table main_qus_table question_newcard">
                    <tbody className="main_ques_body">
                        {
                            question?.data?.options?.length && question?.data?.options.map((option) => (
                                <QuestionOptionNew key={option.option_name} option={option} isAnswer={isAnswer(option)} />
                            ))
                        }
                    </tbody>
                </table>
            </div>


            <div id="modal-center" class="uk-flex-top" data-uk-modal>
                <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                    <button class="uk-modal-close-default cross_btn" type="button" uk-close>
                    <span uk-icon="icon: close"></span>
                    </button>
                    <div className="inner_modal_heading">
                        <h3>{`${question?.sequence || 0}. ${question?.questionText || 'NA'}`}
                            {/* <button><img src={edit} />Maximize</button> */}
                        </h3>
                        <span>Average time taken: {averageTime()}</span>
                    </div>
                    <table className="uk-table main_qus_table question_newcard">
                        <tbody className="main_ques_body">
                            {
                                question?.data?.options?.length && question?.data?.options.map((option) => (
                                    <QuestionOptionNew key={option.option_name} option={option} isAnswer={isAnswer(option)} />
                                ))
                            }
                        </tbody>
                    </table>
                    <hr />
                    <div>
                        <table className="uk-table main_qus_table question_newcard">
                            <tbody className="main_ques_body">
                                {GetParticipantsDetails()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>




        </>
    )
}

export default React.memo(QuestionCardNew);
