import React, { Component } from 'react'
import SiteLayout from 'layouts/SiteLayout';
import axios from 'axios';

export class AssessmentSec extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }
    

    getAssessmentData = async() =>{
        try{
            let userObj = JSON.parse(localStorage.getItem('user'));   
            let teacherId = userObj.id; 
            let schoolId = userObj.schoolId; 
            let reqObj = {
                teacherId: teacherId,
                schoolId: schoolId
            }  
            let url = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/get-assessment-list'
            let result = await axios.post(url,reqObj)
            console.log(result);
            // this.setState({
            //     quizArr: result.data.quiz
            // })
            }
            catch(e){
                console.log(e);
            }
    }

    componentDidMount(){
        this.getAssessmentData();
    }
    
    render() {
        return (
            <SiteLayout>
            <div>
                <h2>pp</h2>
            </div>
            </SiteLayout>
        )
    }
}

export default AssessmentSec
