import React, {useEffect, useState, useRef} from 'react';
import "./TopBar.sass";
import {FiPhone, FiVideo, FiArrowLeft, FiMoreHorizontal, FiExternalLink, FiStar, FiInfo, FiPlusCircle} from 'react-icons/fi';
import {useGlobal} from "reactn";
import Picture from "../../../components/Picture";
import {useDispatch, useSelector} from "react-redux";
import {useToasts} from "react-toast-notifications";
import toggleFavorite from "../../../actions/toggleFavorite";
import {useHistory} from "react-router-dom";
import getMeetingRoom from "../../../actions/getMeetingRoom";
import postCall from "../../../actions/postCall";
import Actions from "../../../constants/Actions";
import moment from "moment";
import {createClass, joinClass} from '../../../actions/getClassRooms';
import message from "../../../actions/message";
import getRooms from "../../../actions/getRooms";

const TopBar = ({back, loading}) => {
    const onlineUsers = useSelector(state => state.io.onlineUsers);
    const room = useSelector(state => state.io.room) || {};
    const user = useGlobal('user')[0];
    const [favorites, setFavorites] = useGlobal('favorites');
    const setNav = useGlobal('nav')[1];
    const setAudio = useGlobal('audio')[1];
    const setVideo = useGlobal('video')[1];
    const setCallDirection = useGlobal('callDirection')[1];
    const setMeeting = useGlobal('meeting')[1];
    const {addToast} = useToasts();
    const dispatch = useDispatch();
    const history = useHistory();

    const stepapp = () => window.open("https://www.stepapp.ai", "_blank");

    let other = {};
    let [services, setServices] = useState('');

    room.people && room.people.forEach(person => {
        if (user.id !== person._id) other = person;
    });

    if (!other.firstName) {
        other = {...other, firstName: 'Deleted', lastName: 'User'};
    }

    const title = (room.isGroup ? room.title : `${other.firstName} ${other.lastName}`).substr(0, 22);

    const warningToast = content => {
        addToast(content, {
            appearance: 'warning',
            autoDismiss: true,
        })
    };

    const errorToast = content => {
        addToast(content, {
            appearance: 'error',
            autoDismiss: true,
        })
    };

    let bbbliveparams={
        services:"",
        onClickFuntionName : '',
        action:'create',
        password : "",
        checksum : '',
        meetingID : ''
    };

    const createLiveClass = async(action) =>{
        if(action==="create"){
            let data={
                type:user.userType,
                name:user.username, 
                room_id:room._id, 
                teacher_id:user.teacherCode, 
                school_id:user.schoolId, 
                date:new Date(), 
                start_time:"", //new Date().getTime()/1000.0 epoch
                end_time:"", 
                board_id:"", 
                grade_id:"", 
                subject_id:"", 
                topic:""
            }
            const res = await createClass(data); 
            setServices(res.data.service);
            bbbliveparams.onClickFuntionName = createLiveClass;
            bbbliveparams.content = "Start Class";
            bbbliveparams.services = res.data.service;
            sendClassMessage(bbbliveparams);   
        }else if(action==="join"){
            let data = {
                username:user.username, 
                //type:"moderator", 
                type:user.userType, 
                room_id:room._id, 
                service: services 
            }
            const res = await joinClass(data);
            window.open(res.data.joinUrl);
            const joinurl = new URL(res.data.joinUrl);
            bbbliveparams.content = "Join Class";
            bbbliveparams.onClickFuntionName = 'joinMeeting';
            bbbliveparams.action = 'join';
            bbbliveparams.password = joinurl.searchParams.get("password");
            bbbliveparams.checksum = joinurl.searchParams.get("checksum");
            bbbliveparams.meetingID = joinurl.searchParams.get("meetingID");
            console.log("inside Join: ", bbbliveparams);
            sendClassMessage(bbbliveparams);
        
        }        
    }
    
    const sendClassMessage = (params) => {
        if(params.action==="join"){
            message({roomID: room._id, authorID: user.id, content: JSON.stringify(params), type: 'bbbLiveClass'}).then(() => {
                getRooms()
                .then(res => {
                    dispatch({
                        type: Actions.SET_ROOMS, 
                        rooms: res.data.rooms
                    })
                })
                .catch(err => console.log(err));
            });     
        }else if(params.action==="create"){
            let newMessage = {
                _id: Math.random(), author: {...user, _id: user.id}, content: params, type: 'bbbLiveClass', date: moment()
            };
            dispatch({type: Actions.MESSAGE, message: newMessage});
        }
    };

    const call = async isVideo => {
        if (onlineUsers.filter(u => u.id === other._id).length === 0 && !room.isGroup)
            return warningToast("Can't call user because user is offline");
        await setAudio(true);
        await setVideo(isVideo);
        await setCallDirection('outgoing');
        dispatch({type: Actions.RTC_SET_COUNTERPART, counterpart: other});
        try {
            const res = await getMeetingRoom({startedAsCall: true, caller: user.id, callee: other._id, callToGroup: room.isGroup, group: room._id});
            await setMeeting(res.data);
            history.replace('/meeting/' + res.data._id);
            await postCall({roomID: room._id, meetingID: res.data._id});
        }
        catch (e) {
            errorToast('Server error. Unable to initiate call.');
        }
    };

    const favorite = async () => {
        const res = await toggleFavorite(room._id);
        setNav('favorites');
        setFavorites(res.data.favorites);
    };

    const isFavorite = () => {
        for (let favorite of favorites) {
            if (favorite._id === room._id) return true;
        }
        return false;
    }

    const roomInfo = () => {
        history.replace('/room/' + room._id + '/info');
    };

    const Online = ({other}) => {
        const onlineUsers = useSelector(state => state.io.onlineUsers);
        const prevStatusRef = useRef(false);
        const [lastOnline, setLastOnline] = useState(null);

        useEffect(() => {
            if (prevStatusRef.current && onlineUsers.filter(u => u.id === other._id).length === 0) setLastOnline(moment().valueOf());
            prevStatusRef.current = onlineUsers.filter(u => u.id === other._id).length > 0;
        }, [onlineUsers, other]);

        if (onlineUsers.filter(u => u.id === other._id && u.status === 'busy').length > 0) return 'busy';
        if (onlineUsers.filter(u => u.id === other._id && u.status === 'online').length > 0) return 'online';
        if (onlineUsers.filter(u => u.id === other._id && u.status === 'away').length > 0) return 'away';
        if (lastOnline) return `Last online: ${moment(lastOnline).fromNow()}`;
        return `Last online: ${other.lastOnline ? moment(other.lastOnline).fromNow() : 'Never'}`;
    };

    const getStatus = () => {
        if (room.isGroup) return null;
        if (onlineUsers.filter(u => u.id === other._id && u.status === 'busy').length > 0) return 'busy';
        if (onlineUsers.filter(u => u.id === other._id && u.status === 'online').length > 0) return 'online';
        if (onlineUsers.filter(u => u.id === other._id && u.status === 'away').length > 0) return 'away';
        return null;
    };

    return (
        <div className="top-bar uk-flex uk-flex-between uk-flex-middle">
            <div className="nav uk-flex uk-flex-middle">
                <div className="button mobile" onClick={back}>
                    <FiArrowLeft/>
                </div>
                {!loading &&
                <div className="uk-flex uk-flex-middle">
                    <div className="profile conversation">
                        <Picture user={other} group={room.isGroup} picture={room.picture} title={room.title}/>
                    </div>
                    {getStatus() && <div className={`dot ${getStatus()}`} />}
                </div>
                }
                {!loading &&
                <div className="text">
                    <div className="title">{title}{title.length > 22 && '...'}</div>
                    <div className="message">{room.isGroup ? `Group: ${room.people.length} members` : <Online other={other} />}</div>
                </div>
                }
            </div>
            <div className="nav">
                {user.userType==="teacher" && services==='' && <div className="button" onClick={() => createLiveClass("create")}>
                    <FiPlusCircle/>
                </div>}
                {/* {user.userType==="teacher" && services!=='' && <div onClick={() => createVClass("join")}>
                   Start <FiVideo/>
                </div>} */}
                {user.userType==="teacher" && <div className="button" onClick={() => call(true)}>
                    <FiVideo/>
                </div>}
                {user.userType==="teacher" && <div className="button" onClick={() => call(false)}>
                    <FiPhone/>
                </div>}
                <div className={`button${isFavorite() ? ' active' : ''}`} onClick={favorite}>
                    <FiStar/>
                </div>
                <div className="uk-inline">
                    <div className="button" type="button">
                        <FiMoreHorizontal/>
                    </div>
                    <div data-uk-dropdown="mode: click; offset: 5; boundary: .top-bar; pos: bottom-right">
                        <div className="link" onClick={roomInfo}>Room Info <div className="icon"><FiInfo/>
                        </div></div>
                        <div className="divider"/>
                        <div className="link" onClick={stepapp}>STEPapp <div className="icon"><FiExternalLink/>
                        </div></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBar;
