import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import challenge from 'img/Challenge.svg';
import edit from 'img/edit.svg';
import pencilWhite from 'img/pencil-white.svg';
import questionsIcon from 'img/questions-icon.svg';
import participantIcon from 'img/participant-icon.svg';
import accuracyIcon from 'img/accuracy-icon.svg';
import SiteLayout from 'layouts/SiteLayout';
import QuestionsTab from './Reports/QuestionTab/index'
import marks from 'img/marks.svg'
import Print from 'img/Print.svg'
import Save from 'img/Save.svg'
import delet from 'img/delet.svg'
import clock from 'img/clock.svg'
import mail from 'img/mail.svg'
import comment from 'img/comment.svg'
import { Input, message, Select } from 'antd';
import { range } from 'lodash';
import Md5 from "crypto-api/src/hasher/md5";
import Hmac from "crypto-api/src/mac/hmac";
import { toHex } from "crypto-api/src/encoder/hex";
import { fromUtf } from "crypto-api/src/encoder/utf";
import Sha256 from "crypto-api/src/hasher/sha256";
import { toBase64 } from "crypto-api/src/encoder/base64";
import * as moment from 'moment'
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const { Option } = Select;
const { TextArea } = Input;
const percentage = 66;


export class DetailReport extends Component {
    constructor(props) {
        super(props)

        this.state = {
            participantArr: [],
            dispParticipantListArr: [],
            overViewArr: [],
            selectedQuizName: '',
            selectedType: '',
            selectedStudentName: '',
            selectedQuizId: '5f4cfa32e736cf9d7d413b12',
            selectedQuizParticipants: '',
            selectedQuizAccuracy: '',
            selectedQuiztotalQuestion: '',
            numOfNA: '',
            numOfCorrect: '',
            numOfWrong: '',
            percentOfNA: '',
            percentOfCorrect: '',
            percentOfWrong: '',
            showItems: 10,
            currentPage: 1,
            currentPageOverview: 1,
            calQuestArr: [],
            teacherAssis: [],
            commentBoxId: null,
            assessment: {},
            selectedQuizScore: null,
            overViewLength: '',
            sortVal: 'desc',
            totalParticpantCount: 0,
            selectedQuizTime: [],
            questionTypeFilters: [],
            filteredStudentDetailQuesAnsArr: [],
            quizId: '',
            id: ''
        }
    }

    getParticipantData = async (e) => {
        try {
            let userObj = JSON.parse(localStorage.getItem('user'));
            let teacherId = userObj.id;
            let schoolId = userObj.schoolId;
            let selectedQuizId = localStorage.getItem('selectedQuizId');
            let selectedQuizName = localStorage.getItem('selectedQuizName');
            let selectedType = localStorage.getItem('selectedType');
            let selectedQuizParticipants = localStorage.getItem('selectedQuizParticipants');
            let selectedQuizAccuracy = localStorage.getItem('selectedQuizAccuracy');
            let selectedQuiztotalQuestion = localStorage.getItem('selectedQuiztotalQuestion');
            // let url = `https://2juw9d7jic.execute-api.ap-south-1.amazonaws.com/PROD/quizparticipants?id=${selectedQuizId}&teacherId=${teacherId}&schoolId=${schoolId}&type=${selectedQuizName}&page=${e}&sort=desc`
            let url = `https://2juw9d7jic.execute-api.ap-south-1.amazonaws.com/PROD/quizparticipants?id=${selectedQuizId}&teacherId=${teacherId}&schoolId=${schoolId}&type=${selectedQuizName}&page=${e}&sort=${this.state.sortVal}`
            let result = await axios.get(url);
            let participantsArr = Array.isArray(result.data.participants) == true ? (result.data.participants !== undefined ? result.data.participants : []) : []
            let totalParticpantCount = result.data.totalUsers
            this.setState({
                participantArr: result.data.participants,
                selectedQuizName: selectedQuizName,
                selectedType: selectedType,
                selectedQuizId: selectedQuizId,
                selectedQuizParticipants: selectedQuizParticipants,
                selectedQuizAccuracy: selectedQuizAccuracy,
                selectedQuiztotalQuestion: selectedQuiztotalQuestion,
                dispParticipantListArr: participantsArr,
                totalParticpantCount: totalParticpantCount
            })

            const selectedQuizTimer = [];
            participantsArr.forEach(a => {
                selectedQuizTimer.push({
                    userTime: a.userTimePerQuestion,
                    participant: {
                        name: a.name,
                        id: a.id,
                        mongoId: a.MongoId
                    }
                })
            })
            if (selectedQuizTimer) {
                this.setState({
                    selectedQuizTime: selectedQuizTimer
                })
            }

        } catch (e) {
            console.log(e)
        }
    }

    getoverViewList = async (e) => {
        let userObj = JSON.parse(localStorage.getItem('user'));
        let teacherId = userObj.id;
        let schoolId = userObj.schoolId;
        let selectedQuizId = localStorage.getItem('selectedQuizId');
        let selectedQuizName = localStorage.getItem('selectedQuizName');
        let url = `https://2juw9d7jic.execute-api.ap-south-1.amazonaws.com/PROD/quizoverview?id=${selectedQuizId}&teacherId=${teacherId}&schoolId=${schoolId}&type=${selectedQuizName}&page=${e}`
        let result = await axios.get(url)
        let overViewCalculatedArr = [];
        let overViewQuestionsArr = [];

        let participantsArr = result.data.participants !== undefined ? result.data.participants : [];
        participantsArr.forEach((i, e) => {
            let correctAns = i.response.filter(function (x) { return (x.response) == 'True' }).length;
            let totalResp = i.response.length;
            i['percent'] = correctAns / totalResp * 100;
            i.response.forEach((k, v) => {
                if (overViewQuestionsArr.indexOf(k.question) == -1) {
                    overViewQuestionsArr.push(k.question);
                }
            })
        })
        let calQuestArr = [];
        overViewQuestionsArr.forEach((i1, e1) => {
            let overviewObj = {};
            overviewObj.name = i1;
            overviewObj.respArr = [];
            calQuestArr.push(overviewObj);
            participantsArr.forEach((i, e) => {
                i.response.forEach((k, v) => {
                    if (i1 === k.question) {
                        overviewObj.respArr.push(k.response)
                    }
                })
            })
        })
        calQuestArr.forEach((i2, e2) => {
            let numCorrectAns = i2.respArr.filter(function (x) { return x === "True"; }).length
            let totalNumAns = i2.respArr.length
            i2['percentage'] = ((numCorrectAns / totalNumAns) * 100).toFixed(2);
        })
        this.setState({
            overViewArr: participantsArr,
            calQuestArr: calQuestArr,
            overViewLength: result.data.totalUsers
        })
    }

    getUserDetails = async (selectedRowKeys, selectedRows) => {
        let userObj = JSON.parse(localStorage.getItem('user'));
        let teacherId = userObj.id;
        let schoolId = userObj.schoolId;
        let selectedQuizName = localStorage.getItem('selectedQuizName');
        let questionListWithResponses = `https://8ytwl5trrl.execute-api.ap-south-1.amazonaws.com/PROD/assessmentques?assessmentId=${selectedRows.quizId}`
        let questionListWithResponsesResult = await axios.get(questionListWithResponses)

        // let userResponses = `https://2juw9d7jic.execute-api.ap-south-1.amazonaws.com/PROD/quizparticipants?id=${selectedRows.quizId}&page=${1}`
        let userResponses = `https://2juw9d7jic.execute-api.ap-south-1.amazonaws.com/PROD/quizparticipants?id=${selectedRows.quizId}&teacherId=${teacherId}&schoolId=${schoolId}&type=${selectedQuizName}&page=${1}&sort=${this.state.sortVal}`
        let userResponsesResult = await axios.get(userResponses)
        let allParticipantsArr = userResponsesResult.data.participants !== undefined ? userResponsesResult.data.participants : [];
        let filterParicipantRespArr = allParticipantsArr.find(x => x.id === selectedRows.id)

        let calculatedQAArr = []
        let calculatedOutcomeArr = []
        let numOfNA = "";
        let numOfCorrect = "";
        let numOfWrong = "";
        let percentOfNA = "";
        let percentOfCorrect = "";
        let percentOfWrong = "";
        questionListWithResponsesResult.data.questions.forEach(function (i, v) {
            console.log(i, v);
            let calculatedQAObj = {}
            calculatedQAObj.questionText = i.questionText
            calculatedQAObj.correctAns = filterParicipantRespArr.quizCorrectResponse[v]
            calculatedQAObj.userResponse = filterParicipantRespArr.userResponse[v]
            calculatedQAObj.outcome = filterParicipantRespArr.userResponse[v] == "" ? 'NA' : (filterParicipantRespArr.quizCorrectResponse[v] == filterParicipantRespArr.userResponse[v] ? 'correct' : 'wrong')
            let resp = filterParicipantRespArr.userResponse[v] == "" ? 'NA' : (filterParicipantRespArr.quizCorrectResponse[v] == filterParicipantRespArr.userResponse[v] ? 'correct' : 'wrong');
            calculatedOutcomeArr.push(resp);
            numOfNA = calculatedOutcomeArr.filter(function (x) { return x === "NA"; }).length;
            numOfCorrect = calculatedOutcomeArr.filter(function (x) { return x === "correct"; }).length;
            numOfWrong = calculatedOutcomeArr.filter(function (x) { return x === "wrong"; }).length;
            percentOfNA = (numOfNA / calculatedOutcomeArr.length) * 100;
            percentOfCorrect = (numOfCorrect / calculatedOutcomeArr.length) * 100;
            percentOfWrong = (numOfWrong / calculatedOutcomeArr.length) * 100;
            calculatedQAArr.push(calculatedQAObj);
        });

        this.setState({
            studentDetailQuesAnsArr: calculatedQAArr,
            selectedStudentName: selectedRows.name,
            numOfNA: numOfNA,
            numOfCorrect: numOfCorrect,
            numOfWrong: numOfWrong,
            percentOfNA: percentOfNA,
            percentOfCorrect: percentOfCorrect,
            percentOfWrong: percentOfWrong,
            selectedQuizParticipantId: selectedRows.quizId
        })
    }

    getCurrentUserDetails = async (val) => {

        try {
            this.setState({
                commentBoxId: null,
                quizId: val.quizId,
                id: val.id
            })
            let userObj = JSON.parse(localStorage.getItem('user'));
            let teacherId = userObj.id;
            let schoolId = userObj.schoolId;
            let selectedQuizName = localStorage.getItem('selectedQuizName');
            let questionListWithResponses = `https://8ytwl5trrl.execute-api.ap-south-1.amazonaws.com/PROD/assessmentques?assessmentId=${val.quizId}`
            let questionListWithResponsesResult = await axios.get(questionListWithResponses)
            // let userResponses = `https://2juw9d7jic.execute-api.ap-south-1.amazonaws.com/PROD/quizparticipants?id=${val.quizId}&page=${1}`
            // let userResponses = `https://2juw9d7jic.execute-api.ap-south-1.amazonaws.com/PROD/quizparticipants?id=${val.quizId}&teacherId=${teacherId}&schoolId=${schoolId}&type=${selectedQuizName}&page=${1}&sort=desc`
            let userResponses = `https://2juw9d7jic.execute-api.ap-south-1.amazonaws.com/PROD/quizparticipants?id=${val.quizId}&teacherId=${teacherId}&schoolId=${schoolId}&type=${selectedQuizName}&page=${1}&sort=${this.state.sortVal}`
            let userResponsesResult = await axios.get(userResponses)

            let allParticipantsArr = userResponsesResult.data.participants !== undefined ? userResponsesResult.data.participants : [];
            let filterParicipantRespArr = allParticipantsArr.find(x => x.id === val.id)

            let calculatedQAArr = []
            let calculatedOutcomeArr = []
            let numOfNA = "";
            let numOfCorrect = "";
            let numOfWrong = "";
            let percentOfNA = "";
            let percentOfCorrect = "";
            let percentOfWrong = "";
            questionListWithResponsesResult.data.questions.forEach(function (i, v) {
                let calculatedQAObj = {}
                calculatedQAObj.questionText = i.questionText
                calculatedQAObj.correctAns = filterParicipantRespArr.quizCorrectResponse[v]
                calculatedQAObj.userResponse = filterParicipantRespArr.userResponse[v]
                calculatedQAObj.id = i._id
                calculatedQAObj.type = i.type
                calculatedQAObj.outcome = filterParicipantRespArr.userResponse[v] == "" ? 'NA' : (filterParicipantRespArr.quizCorrectResponse[v] == filterParicipantRespArr.userResponse[v] ? 'correct' : 'wrong')
                let resp = filterParicipantRespArr.userResponse[v] == "" ? 'NA' : (filterParicipantRespArr.quizCorrectResponse[v] == filterParicipantRespArr.userResponse[v] ? 'correct' : 'wrong');
                calculatedOutcomeArr.push(resp);
                numOfNA = calculatedOutcomeArr.filter(function (x) { return x === "NA"; }).length;
                numOfCorrect = calculatedOutcomeArr.filter(function (x) { return x === "correct"; }).length;
                numOfWrong = calculatedOutcomeArr.filter(function (x) { return x === "wrong"; }).length;
                percentOfNA = (numOfNA / calculatedOutcomeArr.length) * 100;
                percentOfCorrect = (numOfCorrect / calculatedOutcomeArr.length) * 100;
                percentOfWrong = (numOfWrong / calculatedOutcomeArr.length) * 100;
                calculatedQAArr.push(calculatedQAObj);
            });

            const filterValues = questionListWithResponsesResult.data.questions.map(a => ({ value: a.type, name: a.type.toUpperCase() }))
            filterValues.unshift({ name: "All Questions", value: "" })

            this.setState({
                questionTypeFilters: filterValues
            })

            await this.getActorDetail(val.quizId, teacherId, schoolId, val.MongoId, val.id, calculatedQAArr)

            this.setState({
                // showUserDetailModal: true,
                selectedQuiz: {
                    score: val.score,
                    board: val.board,
                    grade: val.board
                },
                // studentDetailQuesAnsArr: calculatedQAArr,
                selectedStudentName: val.name,
                numOfNA: numOfNA,
                numOfCorrect: numOfCorrect,
                numOfWrong: numOfWrong,
                percentOfNA: percentOfNA,
                percentOfCorrect: percentOfCorrect,
                percentOfWrong: percentOfWrong,
                assessment: {
                    id: questionListWithResponsesResult.data.assessment_id,
                    type: val.quizName,
                    teacherId: questionListWithResponsesResult.data.teacher_id,
                    subject: questionListWithResponsesResult.data.subject,
                    actor: {
                        id: val.id,
                        name: val.name,
                        phone: val.phone
                    },
                    MongoId: val.MongoId,
                    context: val.context
                },

            }, () =>
                console.log(this.state))




        } catch (e) {
            console.log(e);
        }
    }

    getActorDetail = async (quizId, teacherId, schoolId, mongoId, actorId, studentDetailQuesAnsArr) => {
        try {
            const url = `https://2juw9d7jic.execute-api.ap-south-1.amazonaws.com/PROD/quizparticipants?id=${quizId}&teacherId=${teacherId}&schoolId=${schoolId}&page=1&type=ASSESSEMENT_TEST&mongoId=${mongoId}&actorId=${actorId}&sort=desc`;
            const { data } = await axios.get(url)

            if (data.participants._source.teacherAssessment && data.participants._source.teacherAssessment.length) {
                for (const assis of data.participants._source.teacherAssessment) {
                    studentDetailQuesAnsArr = studentDetailQuesAnsArr.map(a => {
                        if (a.id === assis[0]) {
                            a.marks = assis[1]
                            a.comment = assis[2]
                        }
                        return a
                    })
                }
            } else {
                studentDetailQuesAnsArr = studentDetailQuesAnsArr.map(a => ({ ...a, marks: '', comment: '' }))
            }

            this.setState({
                studentDetailQuesAnsArr,
                filteredStudentDetailQuesAnsArr: studentDetailQuesAnsArr
            })

        } catch (err) {
            console.log(err)
        }
    }

    goBack = () => {
        this.props.history.push('/reports');
    }

    handleClick = (e) => {
        this.getParticipantData(e.target.id)
        this.setState({
            currentPage: Number(e.target.id),
        });
    }

    handleNextClick = (e) => {
        let curnum = this.state.currentPage
        let finalNum = curnum + 1
        this.getParticipantData(finalNum)
        this.setState({
            currentPage: finalNum,
        });
    }

    handlePrevClick = (e) => {
        let curnum = this.state.currentPage
        let finalNum = curnum - 1
        this.getParticipantData(finalNum)
        this.setState({
            currentPage: finalNum,
        });
    }

    handleClickOverview = (e) => {
        this.getoverViewList(e.target.id)
        this.setState({
            currentPageOverview: Number(e.target.id),
        });
    }

    handleNextClickOverview = (e) => {
        let curnum = this.state.currentPageOverview
        let finalNum = curnum + 1
        this.getoverViewList(finalNum)
        this.setState({
            currentPageOverview: finalNum,
        });
    }

    handlePrevClickOverview = (e) => {
        let curnum = this.state.currentPageOverview
        let finalNum = curnum - 1
        this.getoverViewList(finalNum)
        this.setState({
            currentPageOverview: finalNum,
        });
    }



    showCommentBox = (item) => {
        this.setState({
            commentBoxId: item.id
        })
    }

    sendEmailToCurrentUser = async (e) => {
        let userObj = JSON.parse(localStorage.getItem('user'));
        let teacherId = userObj.id;
        let schoolId = userObj.schoolId;
        let selectedQuizName = localStorage.getItem('selectedQuizName');
        let userEmail = `https://2juw9d7jic.execute-api.ap-south-1.amazonaws.com/PROD/sendquizmail?quizId=${e.quizId}&userId=${e.id}&teacherId=${teacherId}&schoolId=${schoolId}&type=${selectedQuizName}`
        let userEmailResponse = await axios.get(userEmail)
        if (userEmailResponse.data.statusCode === 200) {
            // alert('Mail sent successfully')
            message.success('Mail sent successfully')
        }
    }

    sendEmailToCurrentUserFromModal = async (e, f) => {
        console.log(e);
        console.log(f);

        let userObj = JSON.parse(localStorage.getItem('user'));
        let teacherId = userObj.id;
        let schoolId = userObj.schoolId;
        let selectedQuizName = localStorage.getItem('selectedQuizName');
        let userEmail = `https://2juw9d7jic.execute-api.ap-south-1.amazonaws.com/PROD/sendquizmail?quizId=${e}&userId=${f}&teacherId=${teacherId}&schoolId=${schoolId}&type=${selectedQuizName}`
        let userEmailResponse = await axios.get(userEmail)
        if (userEmailResponse.data.statusCode === 200) {
            // alert('Mail sent successfully')
            message.success('Mail sent successfully')
        }
    }

    sendEmail = async () => {
        let userObj = JSON.parse(localStorage.getItem('user'));
        let teacherId = userObj.id;
        let schoolId = userObj.schoolId;
        let selectedQuizName = localStorage.getItem('selectedQuizName');
        let quizId = localStorage.getItem('selectedQuizId')
        let email = `https://2juw9d7jic.execute-api.ap-south-1.amazonaws.com/PROD/sendquizmail?quizId=${quizId}&teacherId=${teacherId}&schoolId=${schoolId}&type=${selectedQuizName}&param=all`;
        let emailResponse = await axios.get(email)
        if (emailResponse.data.statusCode === 200) {
            // alert('Mail sent successfully')
            message.success('Mail sent successfully')
        }
    }

    downloadCsv = async () => {
        let userObj = JSON.parse(localStorage.getItem('user'));
        let teacherId = userObj.id;
        let schoolId = userObj.schoolId;
        let quizId = localStorage.getItem('selectedQuizId')
        let selectedType = localStorage.getItem('selectedType');
        let csv = `https://2juw9d7jic.execute-api.ap-south-1.amazonaws.com/PROD/quizparticipantcsv?quizId=${quizId}&teacherId=${teacherId}&schoolId=${schoolId}&type=${selectedType}`
        let csvResponse = await axios.get(csv)
        window.open(csvResponse.data.path, "_blank")
    }

    updateQuestionListing = async () => {
        try {
            console.log("SELECTED ID", this.state.selectedQuizParticipantId);

            const teacherAssessment = [];
            this.state.studentDetailQuesAnsArr.forEach(a => {
                let markStr = a.marks ? a.marks.toString() : '';
                let comment = a.comment ? a.comment : ""
                let data = [a.id, markStr, comment];
                teacherAssessment.push(data);
            })

            // const query = {
            //     actor: {
            //         id: this.state.assessment.actor.id,
            //         name: this.state.assessment.actor.name,
            //         mobile_number: this.state.assessment.actor.phone
            //     },
            //     verb: "COMPLETED",
            //     learn_object: {
            //         id: this.state.assessment.id,
            //         type: this.state.assessment.type,
            //         teacher_id: this.state.assessment.teacherId,
            //         subjectSlug: this.state.assessment.subject,
            //         mappedChildObjectIds: this.state.studentDetailQuesAnsArr.map(a => a.id),
            //         correct_responses: this.state.studentDetailQuesAnsArr.map(a => a.correctAns),
            //     },
            //     learn_object_id: this.state.assessment.id,
            //     learn_object_type: this.state.assessment.type,
            //     result: {
            //         score: this.state.selectedQuiz.score,
            //         incorrect_responses: this.state.numOfWrong,
            //         correct_responses: this.state.numOfCorrect,
            //         user_response: this.state.studentDetailQuesAnsArr.map(a => a.userResponse),
            //         duration: "",
            //         completion: true
            //     },
            //     context: {
            //         timestamp: new Date().toISOString(),
            //         school_id: userObj.schoolId,
            //         language: "en",
            //         grade_name: this.state.selectedQuiz.grade,
            //         board_name: this.state.selectedQuiz.board,
            //     },
            //     teacherAssessment
            // }

            var start_date = moment(new Date().toString(), 'YYYY-MM-DD HH:mm:ss');

            let newQuery = {
                condition: {
                    actor_id: this.state.assessment.actor.id,
                    id: this.state.assessment.MongoId
                },
                update_item: {
                    teacherAssessment,
                    context: {
                        ...this.state.assessment.context,
                        timestamp: new Date().toISOString(),
                    }
                },
            }
            // let url = 'https://api.stepapp.in/insert-records'
            // let urlFinal = this.getUrlWithToken(url)
            // console.log(urlFinal);

            // const result = await axios.post(urlFinal, query);
            // console.log({result})

            let url = 'https://8ytwl5trrl.execute-api.ap-south-1.amazonaws.com/PROD/update-lrs'
            // let urlFinal = this.getUrlWithToken(url)
            // console.log(urlFinal);

            const result = await axios.post(url, newQuery);
        } catch (err) {
            console.log(err)
        }
    }

    getUrlWithToken = (url) => {
        let arrParamUrl = url.split('https://api.stepapp.in');
        if (arrParamUrl && arrParamUrl.length > 0) {
            let urlWithToken = this.getVerifyParam(arrParamUrl[1]);
            return url + urlWithToken;
        }
    }

    getVerifyParam = (queryString) => {
        let hmack = 'thisisasharedsecret'
        let HASHKey = hmack;
        let hasher = new Sha256();
        let hmac = new Hmac(fromUtf(HASHKey), hasher);
        let timestamp = Math.round(new Date().getTime() / 1000); //1591927981; //
        let separator = `${queryString.includes("?") ? "&" : "?"}verify`;
        hmac.update(fromUtf(queryString + timestamp));
        let hmacresult = toBase64(hmac.finalize());
        let updatedParam = `${separator}=${timestamp}-${encodeURIComponent(
            hmacresult
        )}`;
        return updatedParam;
    }

    changeSort = async (e) => {
        console.log(e.target.value);
        let sortVal = e.target.value;
        let userObj = JSON.parse(localStorage.getItem('user'));
        let teacherId = userObj.id;
        let schoolId = userObj.schoolId;
        let selectedQuizId = localStorage.getItem('selectedQuizId');
        let selectedQuizName = localStorage.getItem('selectedQuizName');
        let url = `https://2juw9d7jic.execute-api.ap-south-1.amazonaws.com/PROD/quizparticipants?id=${selectedQuizId}&teacherId=${teacherId}&schoolId=${schoolId}&type=${selectedQuizName}&page=${1}&sort=${sortVal}`
        let result = await axios.get(url);
        let participantsArr = Array.isArray(result.data.participants) == true ? (result.data.participants !== undefined ? result.data.participants : []) : []
        this.setState({
            participantArr: result.data.participants,
            dispParticipantListArr: participantsArr,
            sortVal: sortVal
        })
    }

    componentDidMount() {
        this.getParticipantData(1);
        this.getoverViewList(1);
    }

    handleFilterChange = (val) => {
        if (val) {
            this.setState({
                filteredStudentDetailQuesAnsArr: this.state.studentDetailQuesAnsArr.filter(a => a.type === val)
            })
        } else {
            this.setState({
                filteredStudentDetailQuesAnsArr: this.state.studentDetailQuesAnsArr
            })
        }
    }

    render() {
        // totalParticpantCount
        let participantArr = this.state.participantArr
        let pagination = Math.ceil(this.state.totalParticpantCount / 10)
        console.log(this.state.overViewArr);
        console.log(pagination, this.state.overViewArr.length, this.state.totalParticpantCount);
        const pageNumbers = [];
        let count = 0
        for (let i = 1; i <= pagination; i++) {
            count++
            pageNumbers.push(count);
        }

        console.log(this.state.dispParticipantListArr);
        console.log(this.state.dispParticipantListArr.length);
        this.state.dispParticipantListArr.slice(0, this.state.showItems).map((i, e) => {
            console.log(i)
        })

        const pageNumberOverview = [];
        let counts = 0
        let pag = Math.ceil(this.state.overViewLength / 10)
        for (let i = 1; i <= pag; i++) {
            counts++
            pageNumberOverview.push(counts);
        }
        console.log(pageNumberOverview);
        console.log(this.state.assessment)


        return (
            <SiteLayout>
                <div className="uk-container uk-container-small">
                    <a className="uk-button uk-button-default uk_btn_gry" onClick={this.goBack}><span uk-icon="icon: chevron-left; ratio: 1"></span> Back</a>
                    <div className="uk-container uk-container-small report_det_cont">
                        <div className="uk-grid-small uk-child-width-expand@s" data-uk-grid>
                            <div>
                                <div className="uk-card uk-card-default uk-card-body">
                                    <div className="report_body">
                                        <div className="report_body_left">
                                            <h2 className="repo_heading"><span><img src={challenge} /></span> {this.state.selectedQuizName.split("_")[1]}</h2>
                                            <h3 className="main_heading">{this.state.selectedQuizName}
                                                {/* <span className="edit_btn"><img src={edit} />Edit</span> */}
                                            </h3>
                                            <div className="sub_heading">September 10th 2020, 3:45 PM (30 minutes ago)</div>
                                            <ul className="report_label">
                                                {/* <li>View quiz</li>
                                                <li>Flashcards</li> */}
                                            </ul>
                                        </div>
                                        <div className="report_body-right">
                                            <div>
                                                {/* <span className="iconSpan"> <img src={accuracyIcon} /> <CircularProgressbar style={{width:"0%"}} value={this.state.selectedQuizAccuracy}  text={`${this.state.selectedQuizAccuracy}%`} />; </span><br /> */}
                                                <span className="iconSpan">
                                                    <div className="accurac_img">
                                                        <span>
                                                            <img src={accuracyIcon} alt="doge" />
                                                        </span>
                                                        <CircularProgressbarWithChildren value={this.state.selectedQuizAccuracy}>
                                                        </CircularProgressbarWithChildren>
                                                    </div>
                                                </span>
                                                <strong>
                                                    {this.state.selectedQuizAccuracy}%
                                                </strong>
                                                <br /> <p>Accuracy</p>
                                            </div>
                                            <div><span className="iconSpan"><img src={participantIcon} /> </span><br />
                                                <strong>{this.state.selectedQuiztotalQuestion}</strong>  <br /><p>Questions</p></div>
                                            <div><span className="iconSpan"><img src={questionsIcon} /> </span><br />
                                                <strong>{this.state.selectedQuizParticipants}</strong>
                                                <br /><p>Particpant</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ul className="uk-subnav uk-subnav-pill report_navbar" data-uk-switcher="animation: uk-animation-fade">
                        <li><a href="#">Participants</a></li>
                        <li><a href="#">Questions</a></li>
                        <li><a href="#">Overview</a></li>
                    </ul>

                    <div className="sort_email">
                        <ul>
                            <li><span className="sorBy">Sort By:</span></li>
                            <li>
                                <div class="time_select">
                                    <select className="uk-select" value={this.state.sortVal} onChange={this.changeSort}>
                                        <option>Score </option>
                                        <option value="asc">asc</option>
                                        <option value="desc">desc</option>
                                    </select>
                                </div>
                            </li>
                            <li>
                                <button className="regl_btn" onClick={() => this.sendEmail()}>
                                    <img src={mail} />Email all parents
                            </button>
                            </li>
                        </ul>
                        <ul className="prnt_dwld_dlt quiz_right">
                            <li>
                                {/* <span><img src={Print} /></span><p>Print</p>  */}
                            </li>
                            <li><a onClick={() => this.downloadCsv()}><span><img src={Save} /></span><p>Save</p></a></li>
                            <li>
                                {/* <span><img src={delet} /></span><p>Delete Quiz</p> */}
                            </li>
                        </ul>
                    </div>




                    {/* <ul className="uk-subnav uk-subnav-pill report_navbar" data-uk-switcher="animation: uk-animation-fade">
                        <li><a href="#">Participants</a></li>
                        <li><a href="#">Questions</a></li>
                        <li><a href="#">Overview</a></li>
                    </ul>

                    <div className="sort_email">
                        <ul>
                            <li><span className="sorBy">Sort By:</span></li>
                            <li>
                                <div class="time_select">
                                    <select className="uk-select">
                                        <option>Score </option>
                                        <option>Score 1</option>
                                        <option>Score 2</option>
                                        <option>Score 3</option>
                                    </select>
                                </div>
                            </li>
                            <li>
                                <button className="regl_btn" onClick={() => this.sendEmail()}>
                                    <img src={mail} />Email all parents
                            </button>
                            </li>
                        </ul>
                        <ul className="prnt_dwld_dlt quiz_right">
                            <li><span><img src={Print} /></span><p>Print</p> </li>
                            <li><a onClick={() => this.downloadCsv()}><span><img src={Save} /></span><p>Save</p></a></li>
                            <li><span><img src={delet} /></span><p>Delete Quiz</p></li>
                        </ul>
                    </div> */}

                    <ul className="uk-switcher uk-margin">
                        <li>
                            <table className="uk-table paricipants_table">
                                <tbody>
                                    {this.state.dispParticipantListArr.length !== 0 ? this.state.dispParticipantListArr.slice(0, this.state.showItems).map((i, e) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <div className="name_icon">
                                                        <span className="color_pinch">{i.name.split(' ').map(x => x.charAt(0)).join('').toUpperCase()}</span>{i.name}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div id="progress" className="progress">
                                                        <div className="progress-bar bg-strong" style={{ width: `${i.right / (i.right + i.wrong) * 100}%` }}>{i.right}</div>
                                                        <div className="progress-bar bg-weak" style={{ width: `${i.wrong / (i.right + i.wrong) * 100}%` }}>{i.wrong}</div>
                                                        <div className="progress-bar " style={{ width: `${0}%` }}>NA</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="accuracy">
                                                        <span className="accuracy_perct">{i.accuracy}%</span>
                                                        <span className="accuracy_name">Accuracy</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="accuracy">
                                                        <span className="accuracy_perct">{i.score}</span>
                                                        <span className="accuracy_name">Score</span>
                                                    </div>
                                                </td>
                                                <td><a className="uk-button uk-button-default uk_btn_gry" onClick={() => this.sendEmailToCurrentUser(i)}>Email parents</a></td>
                                                <td>
                                                    <button className="dots_div" type="button" onClick={() => this.getCurrentUserDetails(i)} data-uk-toggle="target: #modal-example">...</button>
                                                </td>
                                            </tr>
                                        )
                                    }) : ''}
                                </tbody>
                            </table>

                            {/* ------------- PAGINATION --------------- */}

                            <ul className="uk-pagination" uk-margin>
                                <li><a onClick={this.handlePrevClick} disabled={this.state.currentPage === 1 ? true : false}><span data-uk-pagination-previous></span></a></li>
                                {pageNumbers.map(number => {
                                    return (
                                        <li
                                            className={this.state.currentPage === number ? `uk-active` : ''}
                                            key={number}
                                            id={number}
                                            value={number}
                                            onClick={this.handleClick}
                                        ><a id={number} onClick={this.handleClick}>{number}</a></li>
                                    );
                                })}
                                <li><a onClick={this.handleNextClick} disabled={this.state.currentPage === pageNumbers.length ? true : false}><span data-uk-pagination-next></span></a></li>
                            </ul>
                        </li>
                        <li>
                            {
                                this.state.selectedQuizId &&
                                this.state.participantArr?.length &&
                                this.state.selectedQuizId !== 'PRACTISE' && (
                                    <QuestionsTab quizId={this.state.selectedQuizId} participantsTimeTaken={this.state.selectedQuizTime} participants={this.state.participantArr} />
                                )
                            }
                        </li>
                        <li>
                            <table className="uk-table overview_table">
                                <tbody className="">
                                    <tr>
                                        <td>Participant names</td>
                                        <td>Score</td>
                                        {this.state.calQuestArr.map((i, e) => {
                                            return (<td> {i.name} <span className={i.percentage == '100' ? 'grn_btn' : 'red_btn'}>{i.percentage}%</span></td>)
                                        })}
                                    </tr>
                                    {this.state.overViewArr.map((i, e) => {
                                        return (
                                            <tr>
                                                <td>{i.name}</td>
                                                <td>{i.score} ({i.percent}%)</td>
                                                {i.response.map(tag => {
                                                    return (
                                                        <td>
                                                            {tag.response == 'True' ? <span className="grn_check" uk-icon="icon: check; ratio: 2"></span> : <span className="red_cross" uk-icon="icon: close; ratio: 2"></span>}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                            <ul className="uk-pagination" uk-margin>
                                <li><a onClick={this.handlePrevClickOverview} disabled={this.state.currentPageOverview === 1 ? true : false}><span data-uk-pagination-previous></span></a></li>
                                {pageNumberOverview.map(number => {
                                    return (
                                        <li
                                            className={this.state.currentPageOverview === number ? `uk-active` : ''}
                                            key={number}
                                            id={number}
                                            value={number}
                                            onClick={this.handleClickOverview}
                                        ><a id={number} onClick={this.handleClickOverview}>{number}</a></li>
                                    );
                                })}
                                <li><a onClick={this.handleNextClickOverview} disabled={this.state.currentPageOverview === pageNumberOverview.length ? true : false}><span data-uk-pagination-next></span></a></li>
                            </ul>

                        </li>

                    </ul>

                </div>


                {/* <li><button className="uk-button uk-button-default uk_btn_gry" onClick={() => this.sendEmail()} >Email all parents</button></li>
                    <li><button className="uk-button uk-button-default uk_btn_gry" onClick={() => this.downloadCsv()} >Download csv</button></li> */}
                {/* <br /> */}


                <ul className="uk-switcher uk-margin">
                    <li>
                        <table className="uk-table paricipants_table">
                            <tbody>
                                {this.state.dispParticipantListArr.length !== 0 ? this.state.dispParticipantListArr.slice(0, this.state.showItems).map((i, e) => {
                                    return (
                                        <tr>
                                            <td>
                                                <div className="name_icon">
                                                    <span className="color_pinch">{i.name.split(' ').map(x => x.charAt(0)).join('').toUpperCase()}</span>{i.name}
                                                </div>
                                            </td>
                                            <td>
                                                <div id="progress" className="progress">
                                                    <div className="progress-bar bg-strong" style={{ width: `${i.right / (i.right + i.wrong) * 100}%` }}>{i.right}</div>
                                                    <div className="progress-bar bg-weak" style={{ width: `${i.wrong / (i.right + i.wrong) * 100}%` }}>{i.wrong}</div>
                                                    <div className="progress-bar " style={{ width: `${0}%` }}>NA</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="accuracy">
                                                    <span className="accuracy_perct">{i.accuracy}%</span>
                                                    <span className="accuracy_name">Accuracy</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="accuracy">
                                                    <span className="accuracy_perct">{i.score}</span>
                                                    <span className="accuracy_name">Score</span>
                                                </div>
                                            </td>
                                            <td><a className="uk-button uk-button-default uk_btn_gry" onClick={() => this.sendEmailToCurrentUser(i)}>Email parents</a></td>
                                            <td>
                                                <button className="dots_div" type="button" onClick={() => this.getCurrentUserDetails(i)} data-uk-toggle="target: #modal-example">...</button>
                                            </td>
                                        </tr>
                                    )
                                }) : ''}
                            </tbody>
                        </table>

                        {/* ------------- PAGINATION --------------- */}

                        <ul className="uk-pagination" uk-margin>
                            <li><a onClick={this.handlePrevClick} disabled={this.state.currentPage === 1 ? true : false}><span data-uk-pagination-previous></span></a></li>
                            {pageNumbers.map(number => {
                                return (
                                    <li
                                        className={this.state.currentPage === number ? `uk-active` : ''}
                                        key={number}
                                        id={number}
                                        value={number}
                                        onClick={this.handleClick}
                                    ><a id={number} onClick={this.handleClick}>{number}</a></li>
                                );
                            })}
                            <li><a onClick={this.handleNextClick} disabled={this.state.currentPage === pageNumbers.length ? true : false}><span data-uk-pagination-next></span></a></li>
                        </ul>
                    </li>
                    <li>
                        {
                            this.state.selectedQuizId &&
                            this.state.participantArr?.length &&
                            this.state.selectedQuizId !== 'PRACTISE' && (
                                <QuestionsTab quizId={this.state.selectedQuizId} participantsTimeTaken={this.state.selectedQuizTime} participants={this.state.participantArr} />
                            )
                        }
                    </li>
                    <li>
                        <table className="uk-table overview_table">
                            <tbody className="">
                                <tr>
                                    <td>Participant names</td>
                                    <td>Score</td>
                                    {this.state.calQuestArr.map((i, e) => {
                                        return (<td> {i.name} <span className={i.percentage == '100' ? 'grn_btn' : 'red_btn'}>{i.percentage}%</span></td>)
                                    })}
                                </tr>
                                {this.state.overViewArr.map((i, e) => {
                                    return (
                                        <tr>
                                            <td>{i.name}</td>
                                            <td>{i.score} ({i.percent}%)</td>
                                            {i.response.map(tag => {
                                                return (
                                                    <td>
                                                        {tag.response == 'True' ? <span className="grn_check" uk-icon="icon: check; ratio: 2"></span> : <span className="red_cross" uk-icon="icon: close; ratio: 2"></span>}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>

                    </li>
                </ul>




                {/* new section */}

                <div id="modal-example" data-uk-modal>
                    <div className="uk-modal-dialog uk-modal-body">
                        <div className="modal_header">
                            <div className="modal_header_left">
                                <div className="name_icon">
                                    <span className="color_pinch">VI</span>
                                </div>
                                <h2>{this.state.selectedStudentName}
                                    <span className="date_sec">Date: Aug 11, 2020, 12:05:pm</span>
                                </h2>
                            </div>

                            <div className="modal_header_right">
                                {/* <button className="uk-button uk-button-default blue_btn" type="button">
                                    <img src={pencilWhite} />edit
                                </button> */}
                                <Select className="uk-button" allowClear={true} placeholder="Select Question Type" onChange={this.handleFilterChange}>
                                    {this.state.questionTypeFilters.map(a => (
                                        <Option value={a.value} key={a.value}>{a.name} </Option>
                                    ))}
                                </Select>
                                {/* {  this.state.dispParticipantListArr.slice(0, this.state.showItems).map((i, e) => ( */}
                                <button className="uk-button uk-button-default uk_btn_gry" type="button" onClick={() => this.sendEmailToCurrentUserFromModal(this.state.quizId, this.state.id)}>Email parents</button>
                                {/* ))} */}
                                {/* <button className="uk-button uk-button-default uk_btn_gry" type="button" onClick={(e) => this.sendEmailToCurrentUser(e)}>Email parents</button> */}
                                <button className="uk-button uk-button-default black_btn uk-modal-close" type="button">Close</button>
                            </div>
                        </div>

                        <div id="progress" className="progress">
                            <div className="progress-bar bg-strong" style={{ width: `${this.state.percentOfCorrect}%` }}>{this.state.numOfCorrect} </div>
                            <div className="progress-bar bg-weak" style={{ width: `${this.state.percentOfWrong}%` }}>{this.state.numOfWrong} </div>
                            <div className="progress-bar " style={{ width: `${this.state.percentOfNA}%` }}>{this.state.numOfNA} </div>
                        </div>
                        <div id="progress" className="progress trnsp_progress">
                            <div className="progress-bar" style={{ width: `${this.state.percentOfCorrect}%` }}>{this.state.percentOfCorrect}% correct</div>
                            <div className="progress-bar" style={{ width: `${this.state.percentOfWrong}%` }}>{this.state.percentOfWrong}% incorrect</div>
                            <div className="progress-bar" style={{ width: `${this.state.percentOfNA}%` }}>{this.state.percentOfNA}% unattempt</div>
                        </div>
                        {this.state.filteredStudentDetailQuesAnsArr !== undefined ? this.state.filteredStudentDetailQuesAnsArr.map((i, e) => {
                            return (
                                <>
                                    <div className="participate_heading">
                                        <p>{e + 1}.{i.questionText}</p>
                                        <button onClick={() => this.showCommentBox(i)}>
                                            <img src={comment} /><span>Add Comment</span>
                                        </button>
                                    </div>

                                    <p>{i.outcome !== 'NA' ? (i.outcome === 'correct' ? <span uk-icon="icon: check; ratio: 1" className="icon_right"></span> : <span uk-icon="icon: close; ratio: 1" className="icon_close"></span>) : (<span><span uk-icon="icon: check; ratio: 1" className="icon_unattm"></span>Unattempted</span>)} <span>{i.userResponse}</span></p>

                                    <ul className="marks chek_list">
                                        <li className="mark_lbl"><img src={marks} />Marks</li>
                                        {
                                            range(1, 11).map(a => (
                                                <li onClick={() => { i.marks = a; this.forceUpdate() }}>
                                                    <input id={`${i.id}_m${a}`} checked={i.marks == a} type="radio" name={`${i.id}_markList`} />
                                                    <label for={`${i.id}_m${a}`}> {a}</label>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    <div className="container" onClick={() => this.showCommentBox(i)} >
                                        <span uk-icon="icon: comment; ratio: 1" className="icon_right"></span>
                                        <span className="icon_right">Add comment</span>
                                    </div>
                                    {
                                        i.comment || this.state.commentBoxId === i.id ?
                                            <div className="coment_textarea">
                                                <TextArea value={i.comment} onChange={({ target: { value } }) => { i.comment = value; this.forceUpdate() }} rows={4} />
                                            </div> : null
                                    }
                                </>
                            )
                        }) : ''}
                        <div className="modal_header_right">
                            <button className="uk-button uk-button-default blue_btn"
                                data-uk-toggle="target: #modal-example"
                                onClick={this.updateQuestionListing}
                                type="button">Save</button>
                        </div>
                    </div>
                </div>
            </SiteLayout>
        )
    }
}

export default withRouter(DetailReport);


