import axios from "axios";
import Config from "../config";

const getClassRooms = () => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/rooms/room/classRoom",
        data: { limit: 30 }
    });
};

const getDoubtsRooms = () => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/rooms/room/doubtRoom",
        data: { limit: 30 }
    });
};

const createClassRoom = data => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/classRoom",
        data
    });
};

const createDoubtRoom = data => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/doubt",
        data
    });
};

const createClass = data => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/class/create",
        data
    });
};

const joinClass = data => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/class/join",
        data
    });
};
export {getClassRooms, getDoubtsRooms, createClassRoom, createDoubtRoom, createClass, joinClass};
