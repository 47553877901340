import React,{useState,useEffect} from 'react';
import { useHistory } from "react-router-dom";
import './Nav.sass';
// import {useDispatch, useSelector} from "react-redux";
import {
    FiCpu,
    FiPhone,
    FiMessageCircle,
    FiSearch,
    FiSettings,
    FiStar,
} from "react-icons/fi";
import {
    FaTasks, FaVideo, FaPenSquare, FaHdd, FaCommentDots
} from "react-icons/fa";

import channellogo from '../../img/Channels.svg'
import assesslogo from '../../img/Assessments.svg'
import chatlogo from '../../img/Chat.svg'
import doubtlogo from '../../img/Doubts.svg'
import drivelogo from '../../img/Drive.svg'
import livelogo from '../../img/Live.svg'
import reportlogo from '../../img/Reports.svg'

import whiteAssessments from '../../img/whiteAssessments.svg'
import whiteLiveClasses from '../../img/whiteLiveClasses.svg'
import whiteDoubts from '../../img/whiteDoubts.svg'
import whiteDrive from '../../img/whiteDrive.svg'
import whiteChat from '../../img/whiteChat.svg'
import whiteReports from '../../img/whiteReports.svg' 
import whiteChannels from '../../img/whiteChannels.svg' 
import Quiz from '../../img/Quiz.svg' 
import CreateQuizM from '../../img/CreateQuizM.svg' 

// import Views from "../../variables/Views";
// import View from "../../variables/actions/View";
// import combine from 'classnames';
// import Actions from "../../variables/actions/Actions";
// import Config from "../../config";

const Nav = (props) => {
    // const dispatch = useDispatch();

    // const view = useSelector(state => state.view.nav);
    // const mobileView = useSelector(state => state.view.mobile);
    // const user = useSelector(state => state.user);
    // const peerConnection = useSelector(state => state.rtc.peerConnection);
    // const rtcStatus = useSelector(state => state.rtc.status);
    // const status = useSelector(state => state.status);

    // const navigate = view => dispatch({ type: View.NAVIGATE, nav: view, panel: view });
    // const showHome = view => dispatch({ type: Actions.SHOW_HOME });

    // const Picture = () => {
    //     if (user.picture)
    //         return <img src={`${Config.url || ''}/api/images/${user.picture.shieldedID}/256`} alt="Picture" className="picture"/>;
    //     else
    //         return <div className="img">{user.firstName.substr(0,1)}{user.lastName.substr(0,1)}</div>;
    // };

    // const getClass = () => {
    //     if (status.online.includes(user.id)) return 'online';
    //     if (status.away.includes(user.id)) return 'away';
    //     if (status.busy.includes(user.id)) return 'busy';
    //     return 'offline';
    // };


    const [urlvalue, setUrlValue] = useState('');
    const history = useHistory();
    const changeRoute = (val) => {
        // props.onChange(false)
         setUrlValue(val);
        history.push(val);

    }


    // useEffect(() => {
    //     setUrlValue('/')
    //   }, [])
    // console.log(urlvalue);
    // console.log(urlvalue === '/create-quiz');
    // console.log(typeof(urlvalue));
    // console.log(history.location.pathname);
    let location = history.location.pathname
    // console.log(props);
    // console.log(props.NavClick);
    
    const onClick = () => {
        props.onChange(false)

    }

   
    return (
        
        // <div className="leftnav leftnav_mobile">
        <div className={ props.NavClick  === true  ?"leftnav leftnav_mobile_in":"leftnav leftnav_mobile"}>
            <div className="clsBtn">
                 <button onClick={onClick}> <span uk-icon="icon: close"></span></button>
            </div> 
            <div className={` button + ${location === '/create-quiz' || location === '/quiz-detail' || location === '/quiz-listing' ? 'active orng_clr':''}`}  onClick={() => changeRoute('/create-quiz')}>
                <img src={CreateQuizM} className="sidenavimg" onClick={() => changeRoute('/create-quiz')} />
                {/* <span className="btnlbl"  >Create Quiz</span> */}
                <span className="btnlbl">Assessment</span>
            </div>
            <div className={`button + ${location ==='/' ?' active grn_clr':''}`}  onClick={() => changeRoute('/')}>
            <span className=""><img src={whiteChannels} className="sidenavimg"/>
            </span>
                <span className="btnlbl">Channels</span>
            </div>
            <div className={`button + ${location==='/class-rooms'?'active dark_pink_clr':''}`}  onClick={() => changeRoute('/class-rooms')}>
                <span className=""><img src={whiteChannels} className="sidenavimg"/></span> 
                <span className="btnlbl">ClassRooms</span>
            </div>
            {/* <div className={`button + ${location ==='/assessments'?'active pink_clr':''}`} onClick={() => changeRoute('/assessments')}>
            <span className=""><img src={whiteAssessments} className="sidenavimg"/>
            </span>
                <span className="btnlbl">Assessment</span>
            </div> */}
            {/* <div className={`button + ${location==='/live-class'?'active blue_clr':''}`}  onClick={() => changeRoute('/live-class')}>
            <span className=""><img src={whiteLiveClasses} className="sidenavimg"/>
            </span>
                <span className="btnlbl">Live Classes</span>
            </div> */}
            <div className={`button + ${location==='/doubts'?'active lght_blue_clr':''}`}  onClick={() => changeRoute('/doubts')}>
                <img src={whiteDoubts} className="sidenavimg"/>
                <span className="btnlbl">Doubts</span>
            </div>
            {/* <div className={`button + ${location==='/drive'?'active lght_brwn_clr':''}`}  onClick={() => changeRoute('/drive')}>
            <span className=""><img src={whiteDrive} className="sidenavimg"/>
            </span>
                <span className="btnlbl">Drive</span>
            </div> */}
            {/* <div className={`button + ${location==='/chat'?'active dark_pink_clr':''}`}  onClick={() => changeRoute('/chat')}>
            <span className=""><img src={whiteChat} className="sidenavimg"/>
            </span> 
                <span className="btnlbl">Chat</span>
            </div> */}
            <div className={`button + ${location==='/reports' || location==='/report-details'?'active lght_grn_clr':''}`}  onClick={() => changeRoute('/reports')}>
                <img src={whiteReports} className="sidenavimg"/>
                <span className="btnlbl">Reports</span>
            </div>
            {/* <div className={`button + ${location==='/broadcast' || location==='/broadcast'?'active lght_grn_clr':''}`}  onClick={() => changeRoute('/broadcast')}>
                <img src={whiteReports} className="sidenavimg"/>
                <span className="btnlbl">BroadCast</span>
            </div> */}
        </div>
    );
};

export default Nav;
