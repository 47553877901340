import React from 'react'
import htmlParser from 'html-react-parser';

function QuestionOptionNew({ option, isAnswer = false }) {
    let progressBarColor = isAnswer ? 'bg-strong' : 'bg-weak';
    let badgeColor = 'orngseqRound';

    if (option.type === 'unattempted') {
        progressBarColor = 'grey';
        badgeColor = 'greyseqRound';
    }
    if (option?.percent == 0) {
        progressBarColor = 'grey';
    }

    return (
        <>
            <tr>
                <td><span className={badgeColor}>{option?.option_name}</span>  {option.option_value && htmlParser(option.option_value.toString())}</td>
                <td>{option?.participants || 0} participants</td>
                <td>
                    <div id="progress" className="progress">
                        <div className={`progress-bar ${progressBarColor}`} style={{ width: `${option?.percent || 0}%` }}></div>
                    </div>
                </td>
                <td>{option?.percent || 0} %</td>
            </tr>
        </>
    )
}

export default React.memo(QuestionOptionNew);
