import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import UIkit from 'uikit';
import SiteLayout from 'layouts/SiteLayout';
import featherImage from 'img/featherImage.svg'
import editBtn from 'img/editBtn.svg'
import share from 'img/share.svg'
import idolImg from 'img/idolImg.png'
import Quiz from 'img/Quiz.svg'
import delet from 'img/delet.svg'
import idol_think from 'img/idol_think.svg'


import * as moment from 'moment'
import axios from 'axios';

export class QuizListing extends Component {
    constructor(props) {
        super(props)

        this.state = {
            existingQuizArr: [],
            currentPage: 1,
            showItems: 10,
            boardListArr: [],
            gradeListArr: [],
            subjectListArr: [],
            boardName: '',
            gradeName: " ",
            quizName: " ",
            subjectName: " ",
            duration: " ",
            description: " ",
            publishflg: " ",
            board_id: '',
            grade_id: '',
            subject_id: '',
            assessment_id: '',
            loader: false,
            showItems: 10,
            currentPage: 1,


        }
    }

    getAssessmentData = async () => {
        try {
            let userObj = JSON.parse(localStorage.getItem('user'));
            let teacherId = userObj.id;
            let schoolId = userObj.schoolId;
            let firstName = userObj.firstName;
            let lastName = userObj.lastName;
            let reqObj = {
                school_id: schoolId,
                teacher_id: teacherId
            }
            let url = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/get-assessment-list'
            let result = await axios.post(url, reqObj)
            console.log(result);
            this.setState({
                existingQuizArr: result.data.data,
                boardListArr: result.data.board_list,
                gradeListArr: result.data.grade_list,
                subjectListArr: result.data.subject_list,
                displayName: firstName + ' ' + lastName,
                loader: false
            })
        }
        catch (e) {
            console.log(e);
        }
    }

    getDetailsAsmt = (val) => {
        console.log(val);

        this.props.history.push({
            pathname: '/quiz-detail',
            state: {
                quiz: val.name,
                board: val.board,
                grade: val.grade,
                subject: val.subject,
                assessmentID: val._id,
                boardListArr: this.state.boardListArr,
                gradeListArr: this.state.gradeListArr,
                subjectListArr: this.state.subjectListArr,
                board_id: val.board_id,
                grade_id: val.grade_id,
                subject_id: val.subject_id,
                description: val.description,
                duration: val.duration,
                publishflg: this.state.publishflg,
                updated_at: val.updatedAt,
                assessment_image_url: val.assessment_image_url


            }
        })
    }

    goBack = () => {
        this.props.history.push('/create-quiz');
    }

    componentDidMount() {
        this.setState({
            loader: true
        })
        this.getAssessmentData();
    }

    deleteSelectQuiz = async (i) => {

        // UIkit.modal.confirm('')
        // console.log(this.props.location.state);
        console.log(i);
        let assessmentID = i._id

        console.log(assessmentID);
        let reqObj = {
            assessment_id: assessmentID
        }
        let x = this
        UIkit.modal.confirm('Are you sure you want to delete ?').then(async function () {
            // console.log('Confirmed.')

            let url = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/delete-assessment'
            let result = await axios.post(url, reqObj)
            console.log(result);
            // x.props.history.push('/create-quiz');
            x.getAssessmentData();


        }, function () {
            console.log('Rejected.')
        });


    }
    showUpdateModal = (i) => {

        //         assessment_image_url: "https://quiz.stepapp.in/uploads/assessment/assessment_image_1602272014.png"
        // asset_type: "16"
        // board: "CBSE"
        // board_id: "5f99339d82ecc60008a38f50"
        // createdAt: "2020-10-28 9:0:18"
        // data: []
        // description: "Maths Basics"
        // duration: "30"
        // grade: "8"
        // grade_id: "5f99339d82ecc60008a38f51"
        // is_published: 1
        // name: "Maths Basics"
        // question_release_date: "2020-09-23"
        // questions: (4) ["5f9933504b718b00071c3164", "5f9955ffba136a00080a8faf", "5f995787ba136a00080a8fb0", "5f995788e7e4ff0008143961"]
        // questionss: []
        // school_id: 1
        // sequence: "1"
        // subject: "maths"
        // subject_id: "5f99339d82ecc60008a38f52"
        // teacher_id: "5f7c4fbd0dac594c2b6f344e"
        // thumbnail_url: ""
        // type: "quiz"
        // updatedAt: "2020-10-28 9:2:21"
        // _id: "5f993323e690f20008088384"
        console.log(i)
        this.setState({
            quizName: i.name,
            duration: i.duration,
            description: i.description,
            publishflg: i.is_published,
            subject_id: i.subject_id,
            grade_id: i.grade_id,
            board_id: i.board_id,
            subjectName: i.subject,
            boardName: i.board,
            gradeName: i.grade,
            assessment_id: i._id

        }, () => {
            UIkit.modal('#updatequizmodal').show();
        })

        // UIkit.modal('#updatequizmodal', 'show');

    }

    editQuizUpdate = (e) => {
        // console.log(e.target.options[e.target.selectedIndex].dataset.valname)
        // console.log(e.target.value,e.target.name,e.target.id);
         if (e.target.name == 'board_id') {
           
             
            this.setState({
                boardName: e.target.options[e.target.selectedIndex].dataset.valname
            })
        }
        if (e.target.name == 'grade_id') {
            this.setState({
                gradeName: e.target.options[e.target.selectedIndex].dataset.valname
            })
        }
        // if (e.target.name == 'subjectName') {
        //     this.setState({
        //         subjectId: e.target.id
        //     })
        // }
        console.log(e.target.name);
        console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value,
        }, () => console.log(this.state));
    }


    onSubmit = async (e) => {
        e.preventDefault()
        console.log(this.state);
        let { quizName, boardName, gradeName, subjectName, description, duration } = this.state
        console.log(quizName, boardName, gradeName, subjectName);
        const inputData = {

            assessment_id: this.state.assessment_id,
            board_id: this.state.board_id,
            grade_id: this.state.grade_id,
            board: boardName,
            grade: gradeName,
            subject_id: this.state.subject_id,
            subject: subjectName,
            name: quizName,
            duration: duration,
            type: "quiz",
            description: description,
            assessment_image_url: this.state.assessmentMainImg
        }


        let url1 = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/update-assessment';
        let res = await axios.post(url1, inputData)
        console.log(res.data);

        UIkit.modal.alert('Quiz Updated Successfully !!')
        // this.props.history.push('/create-quiz');
        this.getAssessmentData();


    }



    handlePublishUpdate = async (e) => {
        let value
        if (e.currentTarget.checked === true) {
            value = 1
        } if (e.currentTarget.checked === false) {
            value = 0

        } if (e.currentTarget.checked === undefined) {
            value = e.target.value
        }
        console.log(e.target.value);
        console.log(e.currentTarget.checked);
        console.log(typeof (e.target.value))
        console.log(e.target.getAttribute('value1'));
        console.log(this.state.existingQuizArr);
        let { quizName, boardId, boardName, gradeId, gradeName, subjectId, subjectName, description, duration } = this.state
        let assessment_id = e.target.getAttribute('value1')
        let published = e.target.value
        let val = this.state.existingQuizArr.filter(i => i._id === assessment_id)
        console.log(val);


        const inputData = {

            assessment_id: assessment_id,
            type: this.state.quizType,
            // is_published: Number(e.target.value),
            is_published: Number(value),
            name: val.name,
            board: val.board,
            grade: val.grade,
            subject: val.subject,
            board_id: val.board_id,
            grade_id: val.grade_id,
            subject_id: val.subject_id,
            description: val.description,
            duration: val.duration

        }


        let url1 = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/update-assessment';
        let res = await axios.post(url1, inputData)
        console.log(res.data);

        // this.setState({
        //     published:published
        // })
        this.getAssessmentData();

    }


    handleClick = (e) => {

        let data = this.state.existingQuizArr
        let page_size = this.state.showItems
        let page_number = e.target.id

        let paginationLogic = data.slice((page_number - 1) * page_size, page_number * page_size);


        this.setState({
            currentPage: Number(e.target.id),
            data: paginationLogic
        });

    }


    handleNextClick = (e) => {
        let curnum = this.state.currentPage
        let finalNum = curnum + 1
        // this.handleClickButton(finalNum)
        this.setState({
            currentPage: finalNum,
        });
    }

    handlePrevClick = (e) => {
        let curnum = this.state.currentPage
        let finalNum = curnum - 1
        // this.handleClickButton(finalNum)

        this.setState({
            currentPage: finalNum,
        });
    }

    render() {
        const { showItems, currentPage } = this.state
        var currentdate = new Date();
        // var start_date = moment(this.props.location.state.updated_at, 'YYYY-MM-DD HH:mm:ss');
        var end_date = moment(currentdate, 'YYYY-MM-DD HH:mm:ss');
        // var duration = moment.duration(end_date.diff(start_date));
        // console.log(this.props.location.state.updated_at);
        console.log(this.state.grade_id, this.state.board_id, this.state.subject_id);
        let pagination = Math.ceil(this.state.existingQuizArr.length / 10)
        const pageNumbers = [];
        let count = 0
        for (let i = 1; i <= pagination; i++) {
            count++
            pageNumbers.push(count);
        }
        return (
            <>
                <div className="uk-container uk-container-small">
                    {/* <a className="uk-button uk-button-default trns_btn" onClick={this.goBack}><span uk-icon="icon: chevron-left; ratio: 1"></span> Back</a> */}
                    {this.state.loader === false ? this.state.existingQuizArr.slice((currentPage - 1) * showItems, currentPage * showItems).map((i, e) => {
                        {/* {(this.state.data ? this.state.data : this.state.existingQuizArr.slice(0, this.state.showItems)).map((i, e) => { */ }

                        return (
                            <>
                                {/* <tr key={e}>
                                    <td>{i.board}</td>
                                    <td>{i.grade}</td>
                                    <td>{i.subject}</td>
                                    <td>{i.type}</td>
                                    <td>{i.name}</td>
                                    <td>{i.description}</td>
                                    <td>
                                        <select className="uk-select" id="published" name="published" value={i.is_published} value1={i._id} onChange={this.handlePublishUpdate}>
                                            <option value={0}>No</option>
                                            <option value={1}>Yes</option>
                                        </select>
                                    </td>
                                    <td>
                                        <button className="dots_div" type="button" onClick={() => this.getDetailsAsmt(i)}>...</button>
                                    </td>
                                </tr> */}

                                <div className="uk-child-width-1-2 create_quize_main" data-uk-grid>
                                    <div className="create_quize_left">

                                        <div className="addQuize_img">
                                            <a>
                                                <span><img src={i.assessment_image_url !== '' ? i.assessment_image_url : featherImage} width="85" height="85" /></span>
                                                {/* <p>+ Add Quiz image</p> */}
                                                {/* <label htmlFor="file-input-id">Upload</label> */}
                                                <input id="quizimg" type="file" style={{ visibility: "hidden", width: "1px", height: "1px" }} name="quizimg" onChange={this.quizimageUrl} />
                                                {/* <a className="media_img" onClick={() => this.quesImg('quizimg')} > + Add Quiz image  {this.getImageName(this.state.quizimg.replace(/^.*[\\\/]/, ''))} </a> */}
                                                {/* <input type="file" id="file-input-id" onChange={this.onSelectImage} /> */}
                                            </a>
                                        </div>
                                        <a onClick={() => this.getDetailsAsmt(i)}>
                                            <div className="addQize_text"><h2>{i.name}</h2>
                                                <p>{i.subject}</p>
                                                <p>{i.board} Board <span className="ml_20">Grade : {i.grade}</span></p>
                                                {/* <strong>Add at least 4 Questions : <span>{this.state.questionsListArr.length > 0 ? this.state.questionsListArr.length : 0}</span></strong> */}
                                            </div>
                                        </a>
                                    </div>
                                    <div className="create_quize_right">
                                        <a
                                            // data-uk-toggle="target: #updatequizmodal" 
                                            onClick={() => this.showUpdateModal(i)} className="quize_edit"><img src={editBtn} /> </a>
                                        {/* <a href="#" className="quize_share"><img src={share} /> </a> */}
                                        <div className="publish_btn">
                                            <p>Published</p>
                                            <label class="switch" >
                                                <input type="checkbox" id="published" name="published" checked={i.is_published} value1={i._id} onChange={this.handlePublishUpdate} />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="quiz_editor_main quiz_editor_btns">
                                    <h2 className="quiz_left">
                                        <span><img src={idolImg} /></span><p>{this.state.displayName}</p>
                                        {/* <p className="time_min"><strong>.</strong> { dateFormat(this.state.currentDate, "h:MM TT") - dateFormat(this.props.location.state.updated_at, "h:MM TT")} ago by</p> */}
                                        {/* <p className="time_min"><strong>.</strong> { duration.days() + ":" + duration.hours() + ":" + duration.minutes()  } ago by</p> */}
                                        <p className="time_min"><strong>.</strong> {i.updatedAt !== undefined ? `${moment.duration(end_date.diff(moment(i.updatedAt, 'YYYY-MM-DD HH:mm:ss'))).days()} days ${moment.duration(end_date.diff(moment(i.updatedAt, 'YYYY-MM-DD HH:mm:ss'))).hours()} hours ${moment.duration(end_date.diff(moment(i.updatedAt, 'YYYY-MM-DD HH:mm:ss'))).minutes()}  mins ago` : ''}  </p>
                                    </h2>
                                    <ul className="quiz_right">
                                        {/* <li>
                                                        <span><img src={Print} /></span>
                                                        <p>Print</p>
                                                    </li>
                                                    <li>
                                                        <span><img src={Save} /></span>
                                                        <p>Save</p>
                                                    </li> */}
                                        {/* <li>
                                        <span>Published</span>
                                        <select className="uk-select" id="published" name="published" value={i.is_published} value1={i._id} onChange={this.handlePublishUpdate}>
                                                <option value={0}>No</option>
                                                <option value={1}>Yes</option>

                                            </select>
                                        </li> */}
                                        <li>
                                            <a onClick={() => this.deleteSelectQuiz(i)}>
                                                <span><img src={delet} /></span>
                                                <p>Delete Quiz</p>
                                            </a>
                                        </li>
                                    </ul>

                                </div>

                            </>
                        )
                    }) :
                        //  <div uk-spinner="ratio: 3" style={{  position: "absolute",
                        //     height: "100px",
                        //     width: "100px",
                        //     top: "50%",
                        //     left: "50%",
                        //     marginLeft: "-50px",
                        //     marginTop: "-50px",
                        //     backgroundSize: "100%"}}></div>
                        <div className="idolImg"><img src={idol_think} /></div>

                    }
                    <ul className="uk-pagination uk-margin-top" uk-margin>
                        <li><a onClick={this.handlePrevClick} disabled={this.state.currentPage === 1 ? true : false}><span data-uk-pagination-previous></span></a></li>
                        {pageNumbers.map(number => {
                            return (
                                <li
                                    className={this.state.currentPage === number ? `uk-active` : ''}
                                    key={number}
                                    id={number}
                                    value={number}
                                    onClick={this.handleClick}
                                ><a id={number} onClick={this.handleClick}>{number}</a></li>
                            );
                        })}
                        <li><a onClick={this.handleNextClick} disabled={this.state.currentPage === pageNumbers.length ? true : false}><span data-uk-pagination-next></span></a></li>
                    </ul>
                </div>
                {/* -----------------Edit quiz  modal----------------------------------------------------------- */}

                <div id="updatequizmodal" data-uk-modal>
                    <div className="uk-modal-dialog uk-margin-auto-vertical uk-modal-body create_quize_modal">
                        <h2 className="quize_heading"><img src={Quiz} />Update Quiz</h2>
                        <form className="uk-form-stacked" onSubmit={this.onSubmit}>
                            <div className="uk-child-width-1-2" data-uk-grid>
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label" for="form-stacked-select1">Select a Board</label>
                                        <div className="uk-form-controls">
                                            <select className="uk-select" id="board_id" name="board_id" value={this.state.board_id} onChange={this.editQuizUpdate}>
                                                <option>Select Board</option>
                                                {this.state.boardListArr.map((i, e) => {
                                                    return (
                                                        <option value={i._id} data-valname={i.boardName}>{i.boardName}</option>
                                                    )
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="">
                                    <label className="uk-form-label" for="form-stacked-select2">Select Grade</label>
                                    <div className="uk-form-controls">
                                        <select className="uk-select" id="grade_id" name="grade_id" value={this.state.grade_id} onChange={this.editQuizUpdate}>
                                            <option>Select Grade</option>
                                            {this.state.gradeListArr.map((i, e) => {
                                                return (
                                                    <option value={i._id} data-valname={i.value}>{i.grade_roman}</option>
                                                )
                                            })
                                            }
                                        </select>
                                    </div>
                                </div>

                            </div>
                            {/* <div className="uk-child-width-1" data-uk-grid>
                                <label className="uk-form-label" for="form-stacked-select2">
                                    Select Subject</label>
                                <ul className="uk-margin chek_list">
                                    {this.state.subjectListArr.map((i, e) => {
                                        return (
                                            <li>
                                                <input className="uk-radio" type="radio" name="subjectName" id={i._id} value={i.subject_name} onChange={this.editQuizUpdate} />
                                                <label htmlFor={i._id}>{i.subject_name}</label>
                                            </li>
                                        )
                                    })}
                               </ul>
                            </div> */}
                             <div className="uk-child-width-1" data-uk-grid>
                                <label className="uk-form-label" for="form-stacked-select2">
                                    Select Subject</label>
                                 {/* <ul className="uk-margin chek_list">
                                        {this.state.subjectListArr.map((i, e) => {
                                            return (
                                                <li>
                                                    <input className="uk-radio" type="radio" name="subjectName" id={i._id} value={i.subject_name} onChange={this.handleFromUpdate} />
                                                    <label htmlFor={i._id}>{i.subject_name}</label>
                                                </li> 
                                            )
                                        })}
                                    </ul> */}
                            </div> 
                            {/* <div className="">
                                {this.state.subjectListArr.map((i, e) => {
                                    return (
                                        <span style={{ padding: '2px' }}>
                                            <input type="radio" id={i._id} name="subjectName" id={i._id} value={i.subject_name} onChange={this.editQuizUpdate} checked={this.state.subjectName === i.subject_name} />
                                            <label htmlFor={i._id} style={{ marginLeft: 4 }}>{i.subject_name}</label>
                                        </span>
                                    )
                                })
                                }
                            </div> */}
                            <div className="select_subject">
                                    {this.state.subjectListArr.map((i, e) => {
                                        return (
                                            <span style={{ padding: '2px' }}>
                                                <label htmlFor={i._id}>
                                                    <input style={{ marginRight: 4 }} type="radio" htmlFor={i._id}  id={i._id} name="subjectName" value={i.subject_name} onChange={this.editQuizUpdate} checked={this.state.subjectName === i.subject_name} /> 
                                                    <strong>{i.subject_name}</strong> 
                                                </label>
                                                {/* <input type="radio" id={i._id} name="subjectName" value={i.subject_name} onChange={this.editQuizUpdate} checked={this.state.subjectName === i.subject_name} />
                                                <label htmlFor={i._id} style={{ marginLeft: 4 }}>{i.subject_name}</label> */}
                                            </span>
                                        )
                                    })
                                    }
                                </div>
                            <div className="uk-margin">
                                <label className="uk-form-label" for="form-stacked-text">Name this quiz</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" id="form-stacked-text" type="text" name="quizName" value={this.state.quizName} placeholder="Quiz Name" onChange={this.editQuizUpdate} />
                                </div>
                            </div>
                            {/* <div className="uk-margin">
                                <label className="uk-form-label" for="form-stacked-text"> Quiz Duration</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" id="form-stacked-text" type="text" name="duration" value={this.state.duration} placeholder="Quiz Duration"  />
                                </div>
                            </div> */}
                            <div className="uk-child-width-1-2" data-uk-grid>
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label" for="form-stacked-select1">Quiz Duration</label>
                                        <div className="uk-form-controls">
                                            <select className="uk-select" id="duration" name="duration" value={this.state.duration} onChange={this.editQuizUpdate}>
                                                <option>Select</option>
                                                <option value="30">30</option>
                                                <option value="45">45</option>
                                                {/* {this.state.boardListArr.map((i, e) => {
                                                        return (
                                                            <option value={i._id} data-valname={i.boardName}>{i.boardName}</option>
                                                        )
                                                    })
                                                    } */}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label" for="form-stacked-select1">Publish</label>
                                        <div className="uk-form-controls">
                                            <select className="uk-select" id="publishflg" name="publishflg" value={this.state.publishflg} onChange={this.editQuizUpdate}>
                                                <option>Select</option>
                                                <option value={1}>Yes</option>
                                                <option value={0}>No</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="uk-margin">
                                <label className="uk-form-label" for="form-stacked-text">Quiz Description</label>
                                <div className="uk-form-controls">
                                    {/* <input className="uk-input" id="form-stacked-text" type="text" name="description" value={this.state.description} placeholder="Quiz Description" onChange={this.handleFromUpdate} /> */}
                                    <textarea class="uk-textarea" rows="5" id="description" name="description" value={this.state.description} placeholder="Quiz Description" onChange={this.editQuizUpdate}></textarea>
                                </div>
                            </div>

                            <div class="uk-modal-footer uk-text-right">
                                <button className="uk-button uk-button-default uk-modal-close" type="button" onChange={this.onCancel} >Cancel</button>
                                <button className="uk-button uk-button-default"   >Edit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </>

        )
    }
}

export default withRouter(QuizListing)
