import React from "react";
import './Info.sass';
import logo from '../../../assets/logo.png';
import Config from '../../../config';

const Info = () => {

    return (
        <div className="info">
            <div className="top">
                <div className="logo">
                    <img src={logo} alt="Picture" />
                </div>
                <div className="text">
                    Welcome to {Config.appName || 'STEPapp'}!<br/><br/>
                    Some other message here
                </div>
            </div>
            <div className="text">
                Copyright &copy; {Config.brand || 'Eduisfun'}<br />
                v2.0.0
            </div>
        </div>
    );
};

export default Info;
