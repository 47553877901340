import React, { Component } from 'react'
import Iframe from 'react-iframe'
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import SiteLayout from 'layouts/SiteLayout';

export class LiveClass extends Component {
    constructor(props) {
        super(props)

        this.state = {
            schoolId: '',
            teacherId: '',
            teacherCode: '',
            blocking: true,
        }
    }

    hideSpinner = () => {
        console.log("loaded")
        this.setState({
            blocking: false
        })
    }

    componentDidMount() {
        let teacherData = JSON.parse(localStorage.getItem('teacher_resp'));
        console.log(teacherData);
        // this.setState({
        //     schoolId: teacherData.schoolId,
        //     teacherId: teacherData.id,
        //     teacherCode: teacherData.teacherCode
        // })
    }

    render() {
        let schoolid = this.state.schoolId || 43;
        let teacher_id = this.state.teacherId || 10;
        let teacherCode = this.state.teacherCode
        // let url = "https://video.stepapp.in/Teacher?token=1&teacher_id=1&teacher_f_name=rahul&teacher_l_name=holkar&teacher_code=" + teacherCode + ""
        let url ="https://video.stepapp.in/Teacher?token=1&teacher_id=1&teacher_f_name=rahul&teacher_l_name=holkar&teacher_code=djksjkds"
        console.log(url);
        return (
            <SiteLayout>
            <BlockUi tag="div" blocking={this.state.blocking} keepInView>
                <div className="container-div">
                    <Iframe url={url}
                        width="1200px"
                        height="850px"
                        id="myId"
                        className="myClassname responsive-iframe"
                        display="initial"
                        onLoad={this.hideSpinner}
                        position="relative" />
                </div>
            </BlockUi>
            </SiteLayout>
        )
    }
}

export default LiveClass

