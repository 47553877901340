import React, { Component } from 'react'
import Iframe from 'react-iframe'
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import SiteLayout from 'layouts/SiteLayout';

export class Assessments extends Component {
    constructor(props) {
        super(props)

        this.state = {
            schoolId: '',
            teacherId: '',
            blocking: true,
        }
    }

    hideSpinner = () => {
        console.log("loaded")
        this.setState({
            blocking: false
        })
    }
    componentDidMount() {
        let teacherData = JSON.parse(localStorage.getItem('teacher_resp'));
        console.log(teacherData);
        // this.setState({
        //     schoolId: teacherData.schoolId,
        //     teacherId: teacherData.id,
        // })
    }
    render() {
        let schoolid = this.state.schoolId || 43;
        let teacher_id = this.state.teacherId || 10;
        let url = "http://35.154.182.138/assessment.php?school_id=" + schoolid + "&teacher_id=" + teacher_id + ""
        console.log(url);
        return (
            <SiteLayout>
            <BlockUi tag="div" blocking={this.state.blocking} keepInView>
                <div className="container-div">

                    <Iframe url={url}
                        width="1200px"
                        height="820px"
                        id="myId"
                        className="myClassname responsive-iframe"
                        display="initial"
                        onLoad={this.hideSpinner}
                        position="relative" />

                </div>
            </BlockUi>
            </SiteLayout>
        )
    }
}

export default Assessments
