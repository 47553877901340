import React, { Component } from 'react'
import SiteLayout from 'layouts/SiteLayout';

export class Chat extends Component {
    render() {
        return (
            <SiteLayout>
            <div>
                <h2>Messages</h2>
            </div>
            </SiteLayout>
        )
    }
}

export default Chat
