import Axios from "axios";
import { stringify } from "qs";
import config from "config";

function createAxios() {
    const axios = Axios.create();

    axios.defaults.baseURL = `${config.apiEndPoint}/`;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.timeout = 120000; // 2*60*1000 = 120000 = 2 minutes
    // axios.defaults.headers.common['x-access-token'] = localStorage.getItem('x-access-token');

    axios.interceptors.response.use(
        (response) => response?.data,
        (error) => {
            if (error?.response?.data) return Promise.reject(error.response.data);
            return Promise.reject(error);
        }
    );
    return axios;
}

// Initialise Axios
const api = createAxios();

const service = {
    get(route, query = {}, options = {}) {
        return api.get(`${route}?${stringify(query)}`, options);
    },
    getById(route, id, options = {}) {
        return api.get(`${route}/${id}`, options);
    },
    post(route, payload = {}, options = {}) {
        return api.post(route, payload, options);
    },
    postQs(route, query = {}, payload = {}, options = {}) {
        return api.post(`${route}?${stringify(query)}`, payload, options);
    },
};

export default service;
