export default {
    demo: false,
    brand: 'STEPapp', // Footer brand
    appName: 'Padhao', // App Name
    showCredits: true, // Show credits in login page
    url: 'https://padhao.stepapp.in',
    PWAurl: 'https://learn.stepapp.in',
    assessmentUrl: "https://8ytwl5trrl.execute-api.ap-south-1.amazonaws.com/PROD",
    quizUrl: "https://2juw9d7jic.execute-api.ap-south-1.amazonaws.com/PROD",
};
