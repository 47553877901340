import React, { Component } from 'react'
import edit from 'img/edit.svg';
import QuestionCardNew from './components/QuestionCardNew'
// import FilterMenu from './components/FilterMenu';
import config from 'config';
import api from 'services/api';
import { orderBy } from 'lodash';

export class QuestionsTab extends Component {
    constructor(props) {
        super(props)

        this.state = {
            percentOfCorrect: '',
            percentOfWrong: '',
            percentOfNA: '',
            quiz: null,
            questions: [],
        }
    }

    getQuiz = async () => {
        try {
            const quiz = await api.get('/assessmentques', { assessmentId: this.props.quizId }, {
                baseURL: config.assessmentUrl,
            });

            quiz.questions = quiz.questions.map(this.parseQuestion);
            this.setState({ quiz, questions: quiz.questions });
        } catch (err) {
            console.log({ err });
            // message.error(err.message);
        }
    }

    // Question types: MCQ,MCMS,T/F,Fill In The Blanks,Subjective
    parseQuestion = (ques, index) => {
        const participants = this.props.participants;
        const totalParticipants = participants?.length;
        const participantsUnattempted = participants.filter(p => p?.userResponse?.length && !p?.userResponse[index]).length;
        console.log({ totalParticipants, participantsUnattempted, type: ques?.type });

        const unattemptedQues = {
            "option_name": "0",
            "option_value": "Unattempted",
            "option_type": "text",
            percent: parseFloat(((participantsUnattempted / totalParticipants) * 100).toFixed(2)),
            participants: participantsUnattempted,
            type: "unattempted"
        }

        if (!ques?.data) ques.data = { options: [] };

        switch (ques?.type) {
            case 'mcq':
                ques.data.options = ques.data.options.map((op, i) => {
                    const participantsAnswered = participants.filter(p => p?.userResponse?.length && p?.userResponse[index] === op.option_name).length;
                    op.participants = participantsAnswered;
                    op.percent = parseFloat(((participantsAnswered / totalParticipants) * 100).toFixed(2));
                    return op;
                });
                break;
            case 'mcms':
                ques.data.options = ques.data.options.map((op, i) => {
                    const participantsAnswered = participants.filter(p => p?.userResponse?.length && p?.userResponse[index].includes(op.option_name)).length;
                    op.participants = participantsAnswered;
                    op.percent = parseFloat(((participantsAnswered / totalParticipants) * 100).toFixed(2));
                    return op;
                });
                break;
            case 'subjective':
                ques.data.options = participants.filter(p => p?.userResponse?.length && p?.userResponse[index]).map((p, i) => {
                    return {
                        "option_name": String.fromCharCode(65 + i),
                        "option_value": p?.userResponse?.length && p?.userResponse[index],
                        "option_type": "text",
                        "percent": parseFloat(((1 / totalParticipants) * 100).toFixed(2)),
                        participants: 1
                    };
                });
                break;
            case 't/f':
                const TAnswered = participants.filter(p => p?.userResponse?.length && p?.userResponse[index] === 'T').length;
                const FAnswered = participants.filter(p => p?.userResponse?.length && p?.userResponse[index] === 'F').length;
                ques.data.options = [{
                    "option_name": "A",
                    "option_value": "True",
                    "option_type": "text",
                    "option_shortName": "T",
                    "percent": parseFloat(((TAnswered / totalParticipants) * 100).toFixed(2)),
                    participants: TAnswered
                }, {
                    "option_name": "B",
                    "option_value": "False",
                    "option_type": "text",
                    "option_shortName": "F",
                    "percent": parseFloat(((FAnswered / totalParticipants) * 100).toFixed(2)),
                    participants: FAnswered
                }];
                break;
            case 'fill in the blanks':
                ques.data.options = [{
                    "option_name": "A",
                    "option_value": ques?.answer,
                    "option_type": "text",
                    "percent": 0,
                    participants: 0
                }];
                const newOptions = participants.filter(p => p?.userResponse?.length && p?.userResponse[index]).map((p, i) => {
                    return {
                        "option_name": String.fromCharCode(65 + i + 1),
                        "option_value": p?.userResponse?.length && p?.userResponse[index],
                        "option_type": "text",
                        "percent": parseFloat(((1 / totalParticipants) * 100).toFixed(2)),
                        participants: 1
                    };
                });
                ques.data.options.push(...newOptions);
                // const participantsAnswered = participants.filter(p => p?.userResponse?.length && 
                //     p?.userResponse[index] && ques?.answer.includes(p?.userResponse[index])).length;
                // ques.data.options = [{
                //     "option_name": "A",
                //     "option_value": ques?.answer,
                //     "option_type": "text",
                //     "percent": parseFloat(((participantsAnswered / totalParticipants) * 100).toFixed(2)),
                //     participants: participantsAnswered
                // }];
                break;
        }

        ques.data.options.push(unattemptedQues);
        return ques;
    }

    handleMenuClick = (e) => {
        this.setState((state) => ({ questions: orderBy(state.questions, 'sequence', e.key) }));
    }

    componentDidMount() {
        this.getQuiz();
    }

    render() {
        const { questions } = this.state;
        return (
            <div>
                {
                    questions?.length ? questions?.map((ques, index) => (

                        <QuestionCardNew question={ques} index={index} participantsTimeTaken={this.props.participantsTimeTaken} />

                    )) : (
                            <h4 className="h4 text-center">Question not found.</h4>
                        )
                }

            </div>
        )
    }
}

export default QuestionsTab















