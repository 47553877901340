import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import SiteLayout from 'layouts/SiteLayout';
import UIkit from 'uikit';
import Checkbox from '../../img/Checkbox.svg'
import FillInTheBlank from '../../img/FillInTheBlank.svg'
import Poll from '../../img/Poll.svg'
import OpenEnded from '../../img/OpenEnded.svg'
import MultipleChoice from '../../img/MultipleChoice.svg'
import Quiz from '../../img/Quiz.svg'
import featherImage from '../../img/featherImage.svg'
import importspeadseet from '../../img/importspeadseet.svg'
import editBtn from '../../img/editBtn.svg'
import CreateQuizM from '../../img/CreateQuizM.svg'
import folder from '../../img/folder.svg'
import playCircle from '../../img/playCircle.svg'
import idol_think from '../../img/idol_think.svg'
import share from '../../img/share.svg'
import idolImg from '../../img/idolImg.png'
import Print from '../../img/Print.svg'
import Save from '../../img/Save.svg'
import SaveW from '../../img/SaveW.svg'
import delet from '../../img/delet.svg'
import Media from '../../img/Media.svg'
import circleGrn from '../../img/circleGrn.svg'
import plusIcon from '../../img/plusIcon.svg'
import clock from '../../img/clock.svg'
import axios from 'axios';
import QuizListing from './components/QuizListing'
import { message } from 'antd';




export class CreateQuiz extends Component {
    constructor(props) {
        super(props)

        this.state = {
            boardId: '',
            gradeId: '',
            boardName: '',
            gradeName: '',
            quizName: '',
            subjectId: '',
            subjectName: '',
            showItems: 10,
            existingQuizArr: [],
            boardListArr: [],
            gradeListArr: [],
            subjectListArr: [],
            currentPage: 1,
            duration: '',
            description: '',
            published: 0,
            publishflg: 0,
            quizType: 'quiz'
        }
    }

    handleFromUpdate = (e) => {
        // console.log(e.target.options[e.target.selectedIndex].dataset.valname)
        // console.log(e.target.value,e.target.name,e.target.id);
        if (e.target.name == 'boardId') {
            this.setState({
                boardName: e.target.options[e.target.selectedIndex].dataset.valname
            })
        }
        if (e.target.name == 'gradeId') {
            this.setState({
                gradeName: e.target.options[e.target.selectedIndex].dataset.valname
            })
        }
        if (e.target.name == 'subjectName') {
            this.setState({
                subjectId: e.target.id
            })
        }
        this.setState({
            [e.target.name]: e.target.value,
        }, () => console.log(this.state));
    }

    onCancel = () => {
        
        this.setState({
            boardName: '',
            gradeName: '',
            quizName: '',
            subjectName: '',
            duration: '',
            description: '',
            boardId: '',
            gradeId: '',
            subjectId:'',
        })
    }

    onSubmit = async (e) => {
        e.preventDefault()
        console.log("STATE:", this.state);
        console.log(this.state.existingQuizArr.length);
        let userObj = JSON.parse(localStorage.getItem('user'));
        let teacherId = userObj.id;
        let schoolId = userObj.schoolId;
        // let teacherId = '10';
        // let schoolId = '43';
        let { quizName, boardId, boardName, gradeId, gradeName, subjectId, subjectName, description, duration } = this.state
        console.log(quizName, boardName, gradeName, subjectName, duration);
        if (quizName !== "" && boardName !== "" && gradeName !== "" && subjectName !== "") {
            const inputData = {
                school_id: schoolId,
                teacher_id: teacherId,
                board_id: boardId,
                grade_id: gradeId,
                board: boardName,
                grade: gradeName,
                subject_id: subjectId,
                subject: subjectName,
                question_release_date: "2020-09-23",
                name: quizName,
                sequence: this.state.existingQuizArr.length + 1,
                duration: duration,
                type: this.state.quizType,
                thumbnail_url: "",
                description: description,
                asset_type: "16",
                assessment_image_url: "",
                questions: [],
                data: [],
                is_published: 0
            }

            let url1 = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/save-assessment';
            let res = await axios.post(url1, inputData)
            console.log(res.data);
            // console.log(res.data[0]._id);
            let assessmentId = res.data.data[0]._id
            console.log(assessmentId);
            // $('#createQuiz').hide();
            // UIkit.modal('#createQuiz').hide();
            UIkit.modal.alert('Quiz Created Successfully !!')
            this.props.history.push({
                pathname: '/quiz-detail',
                state: {
                    quiz: this.state.quizName,
                    board: this.state.boardName,
                    grade: this.state.gradeName,
                    subject: this.state.subjectName,
                    teacherId: '10',
                    schoolId: '43',
                    assessmentID: assessmentId,
                    boardListArr: this.state.boardListArr,
                    gradeListArr: this.state.gradeListArr,
                    subjectListArr: this.state.subjectListArr,
                    description: this.state.description,
                    duration: this.state.duration,
                    publishflg: this.state.publishflg

                }
            });
        } else {
            console.log()
            message.warning("Please enter all fields!!");

            // UIkit.modal.confirm('Plese enter all the fields')
          
            
        }
    }


    getAssessmentData = async () => {
        try {
            let userObj = JSON.parse(localStorage.getItem('user'));
            let teacherId = userObj.id;
            let schoolId = userObj.schoolId;
            // let teacherId = '10';
            // let schoolId = '43';

            let reqObj = {
                school_id: schoolId,
                teacher_id: teacherId
            }
            let url = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/get-assessment-list'
            let result = await axios.post(url, reqObj)
            let gradeList = result.data.grade_list;
            gradeList.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
            this.setState({
                existingQuizArr: result.data.data,
                boardListArr: result.data.board_list,
                gradeListArr: gradeList,
                subjectListArr: result.data.subject_list,
            })
        }
        catch (e) {
            console.log(e);
        }
    }

    getDetailsAsmt = (val) => {
        console.log(val);

        this.props.history.push({
            pathname: '/quiz-detail',
            state: {
                quiz: val.name,
                board: val.board,
                grade: val.grade,
                subject: val.subject,
                assessmentID: val._id,
                boardListArr: this.state.boardListArr,
                gradeListArr: this.state.gradeListArr,
                subjectListArr: this.state.subjectListArr,
                board_id: val.board_id,
                grade_id: val.grade_id,
                subject_id: val.subject_id,
                description: val.description,
                duration: val.duration,
                publishflg: this.state.publishflg,
                updated_at: val.updatedAt
            }
        })
    }

    viewQuizList = () => {
        this.props.history.push({
            pathname: '/quiz-listing',
            state: {
                // quiz: val.name,
                // board: val.board,
                // grade: val.grade,
                // subject: val.subject,
                // assessmentID: val._id,
                // boardListArr: this.state.boardListArr,
                // gradeListArr: this.state.gradeListArr,
                // subjectListArr: this.state.subjectListArr,
                // board_id: val.board_id,
                // grade_id: val.grade_id,
                // subject_id: val.subject_id,
                // description: val.description,
                // duration: val.duration,
                // publishflg: this.state.publishflg,
                // updated_at: val.updatedAt
            }
        })
    }

    componentDidMount() {
        this.getAssessmentData();
    }


    handleClick = (e) => {

        let data = this.state.existingQuizArr
        let page_size = this.state.showItems
        let page_number = e.target.id

        let paginationLogic = data.slice((page_number - 1) * page_size, page_number * page_size);


        this.setState({
            currentPage: Number(e.target.id),
            data: paginationLogic
        });

    }
    handleClickButton = (e) => {

        let data = this.state.existingQuizArr
        let page_size = this.state.showItems
        let page_number = e
        // console.log(e.target.id);
        let paginationLogic = data.slice((page_number - 1) * page_size, page_number * page_size);


        this.setState({
            currentPage: e,
            data: paginationLogic
        });

    }

    handleNextClick = (e) => {
        let curnum = this.state.currentPage
        let finalNum = curnum + 1
        // this.handleClickButton(finalNum)
        this.setState({
            currentPage: finalNum,
        });
    }

    handlePrevClick = (e) => {
        let curnum = this.state.currentPage
        let finalNum = curnum - 1
        // this.handleClickButton(finalNum)

        this.setState({
            currentPage: finalNum,
        });
    }


    handlePublishUpdate = async (e) => {
        console.log(e.target.value);
        console.log(typeof (e.target.value))
        console.log(e.target.getAttribute('value1'));
        console.log(this.state.existingQuizArr);
        let { quizName, boardId, boardName, gradeId, gradeName, subjectId, subjectName, description, duration } = this.state
        let assessment_id = e.target.getAttribute('value1')
        let published = e.target.value
        let val = this.state.existingQuizArr.filter(i => i._id === assessment_id)
        console.log(val);


        const inputData = {

            assessment_id: assessment_id,
            type: this.state.quizType,
            is_published: Number(e.target.value),
            name: val.name,
            board: val.board,
            grade: val.grade,
            subject: val.subject,
            board_id: val.board_id,
            grade_id: val.grade_id,
            subject_id: val.subject_id,
            description: val.description,
            duration: val.duration,
            // assessment_image_url:val.assessment_image_url

        }


        let url1 = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/update-assessment';
        let res = await axios.post(url1, inputData)
        console.log(res.data);

        // this.setState({
        //     published:published
        // })
        this.getAssessmentData();

    }

    openQuizModal = (val) => {
        this.setState({
            quizType: val
        })
        UIkit.modal('#createQuizModal').show();
    }

    render() {
        let pagination = Math.ceil(this.state.existingQuizArr.length / 10)
        const pageNumbers = [];
        let count = 0
        for (let i = 1; i <= pagination; i++) {
            count++
            pageNumbers.push(count);
        }
        const { showItems, currentPage } = this.state
        return (
            <SiteLayout>
                <div className="uk-container uk-container-small uk-margin-top">
             
                    {/* <button className="uk-button uk-button-default uk-margin-small-right" type="button" data-uk-toggle="target: #modal-example">Open</button> */}

                    {/* <button className="uk-button uk-button-default uk-margin-small-right" id="createQuiz" type="button" data-uk-toggle="target: #modal-example">Create Quiz</button> */}

                    {/* ------------------- LIST EXISTING QUIZ TABLE  --------------------- */}
                    {/* <div className="uk-container uk-container-small">
                        <div className="uk-overflow-auto">
                            <table className="uk-table uk-table-small uk-table-divider report_table create_quz_table">
                                <thead>
                                    <tr>
                                        <th>Board</th>
                                        <th>Grade</th>
                                        <th>Subject</th>
                                        <th>Type</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>published</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.existingQuizArr.slice((currentPage - 1) * showItems, currentPage * showItems).map((i, e) => {
                                        return (
                                            <tr key={e}>
                                                <td>{i.board}</td>
                                                <td>{i.grade}</td>
                                                <td>{i.subject}</td>
                                                <td>{i.type}</td>
                                                <td>{i.name}</td>
                                                <td>{i.description}</td>
                                                <td>
                                                    <select className="uk-select" id="published" name="published" value={i.is_published} value1={i._id} onChange={this.handlePublishUpdate}>
                                                        <option value={0}>No</option>
                                                        <option value={1}>Yes</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <button className="dots_div" type="button" onClick={() => this.getDetailsAsmt(i)}>...</button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <ul className="uk-pagination uk-margin-top" uk-margin>
                            <li><a onClick={this.handlePrevClick} disabled={this.state.currentPage === 1 ? true : false}><span data-uk-pagination-previous></span></a></li>
                            {pageNumbers.map(number => {
                                return (
                                    <li
                                        className={this.state.currentPage === number ? `uk-active` : ''}
                                        key={number}
                                        id={number}
                                        value={number}
                                        onClick={this.handleClick}
                                    ><a id={number} onClick={this.handleClick}>{number}</a></li>
                                );
                            })}
                            <li><a onClick={this.handleNextClick} disabled={this.state.currentPage === pageNumbers.length ? true : false}><span data-uk-pagination-next></span></a></li>
                        </ul>
                    </div> */}


                    {/*--------------- CREATE QUIZ MODAL  ------------------*/}
                    <div id="createQuizModal" data-uk-modal>
                        <div className="uk-modal-dialog uk-margin-auto-vertical uk-modal-body create_quize_modal">
                            <h2 className="quize_heading"><img src={Quiz} />Create a {this.state.quizType}</h2>
                            <form className="uk-form-stacked" onSubmit={this.onSubmit}>
                                <div className="uk-child-width-1-2" data-uk-grid>
                                    <div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label" htmlFor="form-stacked-select1">Select a Board</label>
                                            <div className="uk-form-controls">
                                                <select className="uk-select" id="boardId" name="boardId" value={this.state.boardId} onChange={this.handleFromUpdate}>
                                                    <option>Select Board</option>
                                                    {this.state.boardListArr.map((i, e) => {
                                                        return (
                                                            <option value={i._id} data-valname={i.boardName}>{i.boardName}</option>
                                                        )
                                                    })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <label className="uk-form-label" htmlFor="form-stacked-select2">Select Grade</label>
                                        <div className="uk-form-controls">
                                            <select className="uk-select" id="gradeId" name="gradeId" value={this.state.gradeId} onChange={this.handleFromUpdate}>
                                                <option>Select Grade</option>
                                                {this.state.gradeListArr.map((i, e) => {
                                                    return (
                                                        <option value={i._id} data-valname={i.value}>{i.value}</option>
                                                    )
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div className="uk-child-width-1" data-uk-grid>
                                    <label className="uk-form-label" htmlFor="form-stacked-select2">
                                        Select Subject</label>
                                    {/* <ul className="uk-margin chek_list">
                                        {this.state.subjectListArr.map((i, e) => {
                                            return (
                                                <li>
                                                    <input className="uk-radio" type="radio" name="subjectName" id={i._id} value={i.subject_name} onChange={this.handleFromUpdate} />
                                                    <label htmlFor={i._id}>{i.subject_name}</label>
                                                </li> 
                                            )
                                        })}
                                    </ul> */}
                                </div>
                                <div className="select_subject">
                                    {this.state.subjectListArr.map((i, e) => {
                                        return (
                                            <span style={{ padding: '2px' }}>
                                                <label htmlFor={i._id}>
                                                    <input style={{ marginRight: 4 }} type="radio" htmlFor={i._id}  id={i._id} name="subjectName" value={i.subject_name} onChange={this.handleFromUpdate} checked={this.state.subjectName === i.subject_name} /> 
                                                    <strong>{i.subject_name}</strong> 
                                                </label>
                                                {/* <input type="radio" id={i._id} name="subjectName" value={i.subject_name} onChange={this.editQuizUpdate} checked={this.state.subjectName === i.subject_name} />
                                                <label htmlFor={i._id} style={{ marginLeft: 4 }}>{i.subject_name}</label> */}
                                            </span>
                                        )
                                    })
                                    }
                                </div>
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="form-stacked-text">Name this quiz</label>
                                    <div className="uk-form-controls">
                                        <input className="uk-input" id="form-stacked-text" type="text" name="quizName" value={this.state.quizName} placeholder="Quiz Name" onChange={this.handleFromUpdate} />
                                    </div>
                                </div>
                                <div className="uk-child-width-1-2" data-uk-grid>
                                    <div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label" htmlFor="form-stacked-select1">Quiz Duration</label>
                                            <div className="uk-form-controls">
                                                <select className="uk-select" id="duration" name="duration" value={this.state.duration} onChange={this.handleFromUpdate}>
                                                    <option>Select</option>
                                                    <option value="30">30</option>
                                                    <option value="45">45</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label" htmlFor="form-stacked-select1">Publish</label>
                                            <div className="uk-form-controls">
                                                <select className="uk-select" id="publishflg" name="publishflg" value={this.state.publishflg} onChange={this.handleFromUpdate}>
                                                    <option>Select</option>
                                                    <option value={1}>Yes</option>
                                                    <option value={0}>No</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <label className="uk-form-label" htmlFor="form-stacked-text">Quiz Description</label>
                                    <div className="uk-form-controls">
                                        {/* <input className="uk-input" id="form-stacked-text" type="text" name="description" value={this.state.description} placeholder="Quiz Description" onChange={this.handleFromUpdate} /> */}
                                        <textarea class="uk-textarea" rows="5" id="description" name="description" value={this.state.description} placeholder="Quiz Description" onChange={this.handleFromUpdate}></textarea>
                                    </div>
                                </div>

                                <div class="uk-modal-footer uk-text-right">
                                    <button className="uk-button uk-button-default uk-modal-close" type="button" onClick={this.onCancel} >Cancel</button>
                                    <button className="uk-button uk-button-default">Next</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="create_quize_sec uk-child-width-1-2" data-uk-grid>
                        <div className="create_quize_mainbox">
                            <div className="create_quize_box">
                                <strong><span><img src={CreateQuizM} /></span> Create a Quiz</strong>
                                <button className="uk-button uk_btn_grn" onClick={() => this.openQuizModal('quiz')}>Start</button>
                            </div>

                        </div>
                        <div className="create_quize_mainbox">
                            {/* <a onClick={this.viewQuizList}>
                                <div className="create_quize_box create_quize_collection">
                                    <strong><span className="bg_ylw"><img src={folder} /></span> Collections</strong>
                                    <div className=""><img src={playCircle} /> </div>
                                </div>
                            </a> */}
                            <div className="create_quize_box">
                                <strong><span className="bg_prpl"><img src={CreateQuizM} /></span> Create a Test</strong>
                                <button className="uk-button uk_btn_grn" onClick={() => this.openQuizModal('test')}>Start</button>
                            </div>
                            {/* <div className="idolImg"><img src={idol_think} /></div> */}
                        </div>
                    </div>
                    <br />
                    <QuizListing />
                    

                </div>

            </SiteLayout>
        )
    }
}

export default withRouter(CreateQuiz)
