import React, { Component } from 'react'
import SiteLayout from 'layouts/SiteLayout';

export class index extends Component {
    render() {
        return (
            <div>
                {/* ----------------------------------------------------------------------- */}
                <div id="video-page" class="p-0 m-0">

                    <div class="row no-gutters">

                        <div class="streaming-section col-lg-9  col-sm-8 col-xs-8  main-section">

                            <div Class="row no-gutters vid-info">
                                <div class="dropdown dropright p-2">
                                    <icon class="icon-back-arrow pr-2" style={{ color: '#fff' }}></icon>
                                </div>
                                <div class="broadcast-info py-2">
                                    Lesson Number 3:Metals and non metals<br />
                                    <span class="teacher-info">Teachers Name: Riya Sharma</span>
                                </div>
                                <div class="user-count float-right">
                                    <icon class="icon-users"></icon>  30
                                </div>
                            </div>


                            <div class="row no-gutters streaming-section-vid">
                                <div class="col-md-12 m-auto p-0">
                                    <div className="m-auto text-center width-100">
                                        <video id="videoUrl" controls autoplay="autoplay" class="width-100 video-block"
                                            style="width:100% ; height: 100%; text-align:center">
                                            <source src=" images/vid.jpg" type="video/mp4" width="100%">
                                            </source>
                                        </video>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="str-chat-section col-lg-3  col-sm-4  col-xs-4 card card_border shadow-sm ">

                            <div class="card2">
                                <ul class="nav right-tabs " role="tablist">
                                    <li class="presentation icon-tabs-lb active">
                                        <a href="#home" aria-controls="home" role="tab" data-toggle="tab">
                                            <icon class="icon-chat"></icon>Chat
                                        </a>
                                    </li>
                                </ul>

                                {/* <!-- Tab panes --> */}
                                <div class="tab-content">
                                    <div role="tabpanel" class="tab-pane active " id="home">

                                        <div class="inbox_msg">


                                            <div class="msg_history">

                                                <div class="incoming_msg">
                                                    <div class="incoming_msg_img"> <img src="images/logosmall.png" alt="Alexander" width="20px"
                                                        height="20px" class="img-fluid" /> </div>
                                                    <div class="received_msg">
                                                        <div class="received_withd_msg">
                                                            <p>Coming
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>

                        </div>

                    </div>
                </div>

                // {/* ------------------------------------------------------------------------ */}

            </div>
        )
    }
}

export default index
