import React, { useEffect, useState } from 'react';
import Div100vh from 'react-div-100vh';
import CreateGroup from "../../features/Group/Create";
import CreateGroup2 from "../../features/Group/Create2";
import Panel from "../../features/Panel";
import Nav from "../../features/Nav/Nav";
import Details from "../../features/Details";
import "./Home.sass";
import { useToasts } from "react-toast-notifications";
import { useGlobal, getGlobal, setGlobal } from "reactn";
import Conversation from "../../features/Conversation";
import Meeting from "../../features/Meeting";
import Welcome from "../../features/Welcome";
import NotFound from "../../features/NotFound";
import { Route, Switch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Admin from "../../features/Admin";
import Logo from '../../img/Logo.svg'
import Concept from '../../img/Concepts.svg'

const Home = () => {
    const location = useLocation();
    const [over, setOver] = useGlobal('over');
    const showPanel = useGlobal('showPanel')[0];
    const showDetails = useGlobal('showDetails')[0];
    const panel = useGlobal('panel')[0];
    const callIncrement = useSelector(state => state.rtc.callIncrement);
    const callData = useSelector(state => state.rtc.callData);
    const [user, setUser] = useGlobal('user');
    const setToken = useGlobal('token')[1];
    const { addToast } = useToasts();

    const history = useHistory();

    const [userTypeVal, setuserTypeVal] = useState('');
    const [navClick, setNavClick] = useState(false);

    const onLogoutClick = async () => {
        const username = user.username;
        localStorage.removeItem('token');
        await setToken(null);
        await setUser({});
        addToast(`User ${username} logged out!`, {
            appearance: 'success',
            autoDismiss: true,
        })
        history.push("/login");
    }

    useEffect(() => {
        if (!callData) return;
        setGlobal({
            audio: true,
            video: false,
            callDirection: 'incoming',
            meeting: { _id: callData.meetingID },
        }).then(() => {
            history.replace('/meeting/' + callData.meetingID);
        });
    }, [callIncrement, callData])

    useEffect(() => {
        if (location.pathname !== '/') setOver(true);
    }, [location]);

    useEffect(()=>{
        let userObj = JSON.parse(localStorage.getItem('user'));
        let userType = userObj.userType;
        console.log(userType);
        let finalUserType = userType !== undefined ? userType : 'student'
        setuserTypeVal(finalUserType)
    })

    const getPanel = () => {
        switch (panel) {
            case 'createGroup':
                return <CreateGroup />
            case 'createGroup2':
                return <CreateGroup2 />
            default:
                return <Panel />
        }
    }

    console.log(userTypeVal);
    const onClick = () => {
        console.log("test")
        setNavClick(true)
    }

    const onChange =(val) =>{
        console.log(val)
        setNavClick(val)


    }

    return (
        <Div100vh>
            {userTypeVal !== 'student' ?
            <nav className="uk-navbar-container " uk-navbar="mode: click" style={{ backgroundColor: '#15388C' }}>
                <div className="uk-navbar-left">

                    <ul className="uk-navbar-nav">
                        <li className="uk-active mob_menu_li">
                            <a href="#" onClick={onClick} >
                                <div className="mob_menu">
                                    <span></span>
                                </div>
                            </a>
                        </li>
                        <li className="uk-active">
                            <a href="#">
                                <div className="logo1" style={{ paddingRight: 18 }}>
                                    <img src={Concept} />
                                </div>
                            </a>
                        </li>
                        <li className="uk-active">
                            <a href="#">
                                <div className="col-md-6" style={{ paddingRight: 70 }}>
                                    <img src={Logo} />
                                </div>
                            </a>
                        </li>
                        <li className="uk-active">
                            <a href="#">
                                {/* <input className="uk-input uk-form-width-large" type="text" placeholder="Search here..." /> */}
                            </a>
                        </li>
                    </ul>

                </div>
                <div className="uk-navbar-right">
                    <ul className="uk-navbar-nav">
                        <li>
                            <a href="#"><img src={'https://randomuser.me/api/portraits/men/64.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com" /></a>
                            <div className="uk-navbar-dropdown">
                                <ul className="uk-nav uk-navbar-dropdown-nav">
                                    <li className="uk-active"><a href="#" onClick={onLogoutClick}>Logout</a></li>
                                    {/* <li><a href="#">Item</a></li>
                                    <li><a href="#">Item</a></li> */}
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav> : '' }
            <div className="app">
                {userTypeVal !== 'student' ? <Nav NavClick={navClick} onChange={onChange} />:''}
                {showPanel && getPanel()}
                <div className={`main uk-flex uk-flex-column${over ? ' over' : ''}${over === false ? ' exit' : ''}`}>
                    <Switch>
                        <Route exact path="/" component={Welcome} />
                        <Route exact path="/admin" component={Admin} />
                        <Route exact path="/meeting/:id" component={Meeting} />
                        <Route exact path="/room/:id" component={Conversation} />
                        <Route exact path="/room/:id/info" component={Details} />
                        <Route path="/" component={NotFound} />
                    </Switch>
                </div>
                {!location.pathname.endsWith('/info') && (showDetails || !location.pathname.startsWith('/meeting')) && <Details />}
            </div>
        </Div100vh>
    );
}

export default Home;
