import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import UIkit from 'uikit';
import SiteLayout from 'layouts/SiteLayout';
import Checkbox from 'img/Checkbox.svg'
import FillInTheBlank from 'img/FillInTheBlank.svg'
import Poll from 'img/Poll.svg'
import OpenEnded from 'img/OpenEnded.svg'
import MultipleChoice from 'img/MultipleChoice.svg'
import Quiz from 'img/Quiz.svg'
import featherImage from 'img/featherImage.svg'
import importspeadseet from 'img/importspeadseet.svg'
import editBtn from 'img/editBtn.svg'
import share from 'img/share.svg'
import idolImg from 'img/idolImg.png'
import Print from 'img/Print.svg'
import Save from 'img/Save.svg'
import delet from 'img/delet.svg'
import Media from 'img/Media.svg'
import editBlack from 'img/editBlack.svg'
import copyBlack from 'img/copyBlack.svg'
import menuBtn from 'img/menuBtn.svg'
import SaveW from 'img/SaveW.svg'
import circleGrn from 'img/circleGrn.svg'
import plusIcon from 'img/plusIcon.svg'
import clock from 'img/clock.svg'
import whiteDrop from 'img/whiteDrop.svg'

import Md5 from "crypto-api/src/hasher/md5";
import Hmac from "crypto-api/src/mac/hmac";
import { toHex } from "crypto-api/src/encoder/hex";
import { fromUtf } from "crypto-api/src/encoder/utf";
import Sha256 from "crypto-api/src/hasher/sha256";
import { toBase64 } from "crypto-api/src/encoder/base64";
import axios from 'axios';
import * as moment from 'moment'
import * as XLSX from 'xlsx';
import { message } from 'antd';

var dateFormat = require('dateformat');

const defaultOptionArray = [
    {
        optSelect: 'A',
        optName: "option1",
        optValue: null,
        optImage: null
    },
    {
        optSelect: 'B',
        optName: "option2",
        optValue: null,
        optImage: null
    },
    {
        optSelect: 'C',
        optName: "option3",
        optValue: null,
        optImage: null
    },
    {
        optSelect: 'D',
        optName: "option3",
        optValue: null,
        optImage: null,
    },
]

export class QuizDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            questionsListArr: [],
            questiontxt: '',
            questionimg: '',
            questionimgChk: '',
            ansOpt1: '',
            ansImgOpt1Chk: '',
            ansOpt2: '',
            ansImgOpt2Chk: '',
            ansOpt3: '',
            ansImgOpt3Chk: '',
            ansOpt4: '',
            ansImgOpt4Chk: '',
            inputList: [],
            addOption: false,
            ansOpt5: '',
            ansImgOpt5: '',
            checkedValue: '',
            checkedName: '',
            base64: '',
            file: '',
            Checked: [],
            CheckedValue: [],
            mcqDuration: '',
            checkboxDuration: '',
            currentDate: '',
            questiontxtMcq: '',
            questionimgMcq: '',
            ansOpt1Mcq: '',
            ansImgOpt1Mcq: '',
            ansOpt2Mcq: '',
            ansImgOpt2Mcq: '',
            ansOpt3Mcq: '',
            ansImgOpt3Mcq: '',
            ansOpt4Mcq: '',
            ansImgOpt4Mcq: '',
            ansOpt5Mcq: '',
            ansImgOpt5Mcq: '',
            // boardListArr: this.props.location.state.boardListArr,
            // gradeListArr: this.props.location.state.gradeListArr,
            // subjectListArr: this.props.location.state.subjectListArr,
            // boardName: this.props.location.state.board,
            // gradeName: this.props.location.state.grade,
            // quizName: this.props.location.state.quiz,
            // subjectName: this.props.location.state.subject,
            // duration: this.props.location.state.duration,
            // description: this.props.location.state.description,
            // publishflg: this.props.location.state.publishflg

            boardListArr: [],
            gradeListArr: [],
            subjectListArr: [],
            boardName: " ",
            gradeName: " ",
            quizName: " ",
            subjectName: " ",
            duration: " ",
            description: " ",
            publishflg: " ",
            fillbQuestion: "",
            fillbQuestionImg: "",
            fillbAnswer: "",
            fillbAnswerImg: "",
            openEndQuestion: "",
            openEndQuestionImg: "",
            openEndAnswer: "",
            openEndAnswerImg: "",
            editFlag: false,
            questiontxtMcqedit: '',
            quizimg: '',
            question_id: '',
            sequence: '',
            createQuizType: 'mcq',
            selbgImg: MultipleChoice,
            mcqOptionArr: [...defaultOptionArray],
            loader: false,
            fieldName: ''

        }
    }



    goBack = () => {
        // this.props.history.push('/quiz-listing');
        this.props.history.push('/create-quiz');
    }

    getQuestionListForAssessment = async () => {
        let userObj = JSON.parse(localStorage.getItem('user'));
        let firstName = userObj.firstName;
        let lastName = userObj.lastName;
        const { assessmentID } = this.props.location.state != undefined ? this.props.location.state : '';
        console.log(assessmentID);
        let reqObj = {
            assessment_id: assessmentID
        }
        let url = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/get-assessment-questions'
        let result = await axios.post(url, reqObj)
        console.log(result);
        // let assessmentDetail = result.data.assessment_detail[0]
        this.setState({
            questionsListArr: result.data.assessment_question !== undefined ? result.data.assessment_question : [],
            displayName: firstName + ' ' + lastName,
            // assessmentDetail: result.data.assessment_detail[0]
        })
    }

    updateFormField = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        }, () => console.log(this.state));
    }


    saveAssessmentQuestionMcq = async () => {

        if ((this.state.questiontxtMcq || this.state.questionimgMcq) && (this.state.ansImgOpt1Mcq || this.state.ansOpt1Mcq) !== '') {
            let userObj = JSON.parse(localStorage.getItem('user'));
            let teacherId = userObj.id;
            let schoolId = userObj.schoolId;
            // const { quiz, board, grade, subject, assessmentID } = this.props.location.state
            const { quiz, board, board_id, grade_id, subject_id, grade, subject, assessmentID } = this.props.location.state
            let reqObj = {
                board_id: board_id,
                created_at: "2020-10-16 15:19:42",
                questionText: this.state.questiontxtMcq,
                question_image: this.state.questionimgMcq,
                sequence: this.state.questionsListArr.length + 1,
                board: board,
                grade: grade,
                subject_id: subject_id,
                updated_at: "2020-10-16 15:19:42",
                grade_id: grade_id,
                subject: subject,
                school_id: schoolId,
                teacher_id: teacherId,
                assessment_id: assessmentID,
                answer: {
                    "option_name": this.state.checkedValue,
                    "option_value": this.state.checkedName
                },
                note: "asdsad",
                isDelete: 0,
                asset_type: "17",
                type: "mcq",
                question_type_id: "1",
                // mcqDuration:this.state.mcqDuration,
                data: {
                    options: [
                        {
                            "option_name": "A",
                            "option_value": this.state.ansOpt1Mcq,
                            "option_type": "text",
                            "option_image": this.state.ansImgOpt1Mcq
                        },
                        {
                            "option_name": "B",
                            "option_value": this.state.ansOpt2Mcq,
                            "option_type": "text",
                            "option_image": this.state.ansImgOpt2Mcq
                        },
                        {
                            "option_name": "C",
                            "option_value": this.state.ansOpt3Mcq,
                            "option_type": "text",
                            "option_image": this.state.ansImgOpt3Mcq
                        },
                        {
                            "option_name": "D",
                            "option_value": this.state.ansOpt4Mcq,
                            "option_type": "text",
                            "option_image": this.state.ansImgOpt4Mcq
                        },
                        // {
                        //     "option_name": "E",
                        //     "option_value": this.state.ansImgOpt5,
                        //     "option_type": "text",
                        //     "option_image": this.state.ansImgOpt5.replace(/^.*[\\\/]/, '')
                        // }
                    ]
                }
            }

            let url = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/save-assessment-question'
            let result = await axios.post(url, reqObj)
            console.log(result);
            UIkit.modal.alert('Question Created Successfully !!')
            this.getQuestionListForAssessment();
        } else {
            UIkit.modal.alert('Please fill the form!!')

        }
    }


    saveAssessmentQuestionGlobal = async () => {
        console.log("GLOBAL SAVE");
        const { mcqOptionArr, checkedValue, checkedName, createQuizType, Checked, CheckedValue } = this.state;

        let answer;
        let isError = false;

        let isValid = true;

        if (createQuizType === 'mcms') {
            if (!Checked || !Checked.length) {
                isError = true
            }
            const validOptions = mcqOptionArr.filter(a => a.optValue || a.optImage)
            if (validOptions.length < 2) {
                isValid = false
            }

            answer = []

            Checked.forEach(a => {
                const selectedOption = mcqOptionArr.find(o => o.optSelect === a)
                const data = {
                    option_value: a,
                    option_name: selectedOption ? selectedOption.optName : ""
                };
                answer.push(data)
            })
        } else if (createQuizType === 'mcq') {
            const validOptions = mcqOptionArr.filter(a => a.optValue || a.optImage)
            if (validOptions.length < 2) {
                isValid = false
            }

            if (!checkedValue || !checkedName) {
                isError = true
            }
            answer = {
                "option_name": checkedValue,
                "option_value": checkedName
            }
        } else if (createQuizType === 'fillblank') {
            answer = {
                "option_name": 'a',
                "option_value": this.state.fillbAnswer
            }

            if (!this.state.fillbAnswer && !this.state.fillbAnswerImg) {
                isValid = false
            }
        } else if (createQuizType === 'subjective') {
            answer = {
                "option_name": 'a',
                "option_value": this.state.openEndAnswer
            }
            if (!this.state.openEndAnswer && !this.state.openEndAnswerImg) {
                isValid = false
            }
        }

        if (isError) {
            return message.error("Answer must be selected!")
        }

        if (!this.state.questiontxtMcq && !this.state.questionimgMcq) {
            return message.error("Question is required!")
        }

        if (!isValid) {
            return message.error(`${["subjective", "fillblank"].includes(createQuizType) ? "Answer is required!" : "Please fill atleast 2 options!"}`)
        }

        const options = mcqOptionArr.filter(a => a.optValue || a.optImage)

        let userObj = JSON.parse(localStorage.getItem('user'));
        let teacherId = userObj.id;
        let schoolId = userObj.schoolId;
        // const { quiz, board, grade, subject, assessmentID } = this.props.location.state
        const { quiz, board, board_id, grade_id, subject_id, grade, subject, assessmentID } = this.props.location.state
        let reqObj = {
            board_id: board_id,
            created_at: "2020-10-16 15:19:42",
            questionText: this.state.questiontxtMcq,
            question_image: this.state.questionimgMcq,
            sequence: this.state.questionsListArr.length + 1,
            board: board,
            grade: grade,
            subject_id: subject_id,
            updated_at: "2020-10-16 15:19:42",
            grade_id: grade_id,
            subject: subject,
            school_id: schoolId,
            teacher_id: teacherId,
            assessment_id: assessmentID,
            answer,
            note: "asdsad",
            isDelete: 0,
            asset_type: "17",
            type: createQuizType,
            question_type_id: "1",
            // mcqDuration:this.state.mcqDuration,
            data: {
                options: options.map(a => ({
                    option_name: a.optSelect,
                    option_value: a.optValue,
                    option_type: "text",
                    option_image: a.optImage
                }))
            }
        }


        if (createQuizType === 'fillblank') {
            reqObj.data = [{
                option_name: "a",
                option_value: this.state.fillbAnswer,
                option_type: "text",
                option_image: this.state.fillbAnswerImg
            }]
        } else if (createQuizType === 'subjective') {
            reqObj.data = [{
                option_name: 'a',
                option_value: this.state.openEndAnswer,
                option_type: "text",
                option_image: this.state.openEndAnswerImg
            }]
        }

        let url, msg = "Question Created Successfully !!";
        if (this.state.editFlag) {
            url = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/update-question'
            msg = "Question Updated successfully!!"
            reqObj.question_id = this.state.question_id
        } else {
            url = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/save-assessment-question'
        }
        let result = await axios.post(url, reqObj)

        UIkit.modal.alert(msg)
        this.getQuestionListForAssessment();

        // } else {
        //     UIkit.modal.alert('Please fill the form!!')

        // }
    }

    editAssessmentQuestionMcq = async () => {
        console.log("edit api");
        if ((this.state.questiontxtMcq || this.state.questionimgMcq) && (this.state.ansImgOpt1Mcq || this.state.ansOpt1Mcq) !== '') {
            let userObj = JSON.parse(localStorage.getItem('user'));
            let teacherId = userObj.id;
            let schoolId = userObj.schoolId;
            // const { quiz, board, grade, subject, assessmentID } = this.props.location.state
            const { quiz, board, board_id, grade_id, subject_id, grade, subject, assessmentID } = this.props.location.state

            let post_data = {
                question_id: this.state.question_id,
                subject_id: subject_id,
                board_id: board_id,
                questionText: this.state.questiontxtMcq,
                question_image: this.state.questionimgMcq,
                sequence: this.state.sequence,
                board: board,
                grade: grade,
                grade_id: grade_id,
                subject: subject,
                school_id: schoolId,
                teacher_id: teacherId,
                assessment_id: assessmentID,
                answer: {
                    "option_name": this.state.checkedValue,
                    "option_value": this.state.checkedName
                },
                note: "asdsad",
                isDelete: 0,
                asset_type: "17",
                type: "mcq",
                question_type_id: "1",
                data: {
                    options: [
                        {
                            "option_name": "A",
                            "option_value": this.state.ansOpt1Mcq,
                            "option_type": "text",
                            "option_image": this.state.ansImgOpt1Mcq
                        },
                        {
                            "option_name": "B",
                            "option_value": this.state.ansOpt2Mcq,
                            "option_type": "text",
                            "option_image": this.state.ansImgOpt2Mcq
                        },
                        {
                            "option_name": "C",
                            "option_value": this.state.ansOpt3Mcq,
                            "option_type": "text",
                            "option_image": this.state.ansImgOpt3Mcq
                        },
                        {
                            "option_name": "D",
                            "option_value": this.state.ansOpt4Mcq,
                            "option_type": "text",
                            "option_image": this.state.ansImgOpt4Mcq
                        },
                        // {
                        //     "option_name": "E",
                        //     "option_value": this.state.ansImgOpt5,
                        //     "option_type": "text",
                        //     "option_image": this.state.ansImgOpt5.replace(/^.*[\\\/]/, '')
                        // }
                    ]
                }

            }

            let url = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/update-question'
            let result = await axios.post(url, post_data)
            console.log(result);
            UIkit.modal.alert('Question Edited Successfully !!')
            this.getQuestionListForAssessment();
            this.setState({ editFlag: false })
        } else {
            UIkit.modal.alert('Please fill the form!!')

        }

    }

    saveAssessmentQuestion = async () => {
        let ansArr = []
        console.log(this.state.Checked, this.state.CheckedValue)
        let checkedOptArr = this.state.Checked;
        let checkedAnsArr = this.state.CheckedValue;
        checkedOptArr.forEach(function (i, v) {
            var ansObj = {};
            console.log(i, v);
            console.log(checkedAnsArr[v]);
            ansObj.option_name = i
            ansObj.option_value = checkedAnsArr[v]
            ansArr.push(ansObj);
        })
        console.log(ansArr);

        let mcqObj = {
            "option_name": this.state.Checked,
            "option_value": this.state.checkedName
        }

        if ((this.state.questiontxt || this.state.questionimgChk) && (this.state.ansImgOpt1Chk || this.state.ansOpt1) !== '') {

            let userObj = JSON.parse(localStorage.getItem('user'));
            let teacherId = userObj.id;
            let schoolId = userObj.schoolId;
            const { quiz, board, board_id, grade_id, subject_id, grade, subject, assessmentID } = this.props.location.state
            let reqObj = {
                board_id: board_id,
                created_at: new Date(),
                questionText: this.state.questiontxt,
                question_image: this.state.questionimgChk,
                sequence: this.state.questionsListArr.length + 1,
                board: board,
                grade: grade,
                subject_id: subject_id,
                updated_at: new Date(),
                grade_id: grade_id,
                subject: subject,
                school_id: schoolId,
                teacher_id: teacherId,
                assessment_id: assessmentID,
                answer: ansArr,
                note: "asdsad",
                isDelete: 0,
                asset_type: "17",
                type: "mcms",
                question_type_id: "2",
                data: {
                    options: [
                        {
                            "option_name": "A",
                            "option_value": this.state.ansOpt1,
                            "option_type": "text",
                            "option_image": this.state.ansImgOpt1Chk
                        },
                        {
                            "option_name": "B",
                            "option_value": this.state.ansOpt2,
                            "option_type": "text",
                            "option_image": this.state.ansImgOpt2Chk
                        },
                        {
                            "option_name": "C",
                            "option_value": this.state.ansOpt3,
                            "option_type": "text",
                            "option_image": this.state.ansImgOpt3Chk
                        },
                        {
                            "option_name": "D",
                            "option_value": this.state.ansOpt4,
                            "option_type": "text",
                            "option_image": this.state.ansImgOpt4Chk
                        },
                        // {
                        //     "option_name": "E",
                        //     "option_value": this.state.ansImgOpt5,
                        //     "option_type": "text",
                        //     "option_image": this.state.ansImgOpt5.replace(/^.*[\\\/]/, '')
                        // }
                    ]
                }
            }

            let url = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/save-assessment-question'
            let result = await axios.post(url, reqObj)
            console.log(result);
            UIkit.modal.alert('Question Created Successfully !!')
            this.getQuestionListForAssessment();
        } else {
            UIkit.modal.alert('Please fill the form!!')

        }
    }

    editAssessmentQuestion = async () => {
        let ansArr = []
        console.log(this.state.Checked, this.state.CheckedValue)
        let checkedOptArr = this.state.Checked;
        let checkedAnsArr = this.state.CheckedValue;
        checkedOptArr.forEach(function (i, v) {
            var ansObj = {};
            console.log(i, v);
            console.log(checkedAnsArr[v]);
            ansObj.option_name = i
            ansObj.option_value = checkedAnsArr[v]
            ansArr.push(ansObj);
        })
        console.log(ansArr);

        let mcqObj = {
            "option_name": this.state.Checked,
            "option_value": this.state.checkedName
        }

        if ((this.state.questiontxt || this.state.questionimgChk) && (this.state.ansImgOpt1Chk || this.state.ansOpt1) !== '') {

            let userObj = JSON.parse(localStorage.getItem('user'));
            let teacherId = userObj.id;
            let schoolId = userObj.schoolId;
            const { quiz, board, board_id, grade_id, subject_id, grade, subject, assessmentID } = this.props.location.state


            let post_data = {
                question_id: this.state.question_id,
                subject_id: subject_id,
                board_id: board_id,
                questionText: this.state.questiontxt,
                question_image: this.state.questionimgChk,
                sequence: this.state.sequence,
                board: board,
                grade: grade,
                grade_id: grade_id,
                subject: subject,
                school_id: schoolId,
                teacher_id: teacherId,
                assessment_id: assessmentID,

                answer: ansArr,
                note: "asdsad",
                isDelete: 0,
                asset_type: "17",
                type: "mcms",
                question_type_id: "2",
                data: {
                    options: [
                        {
                            "option_name": "A",
                            "option_value": this.state.ansOpt1,
                            "option_type": "text",
                            "option_image": this.state.ansImgOpt1Chk
                        },
                        {
                            "option_name": "B",
                            "option_value": this.state.ansOpt2,
                            "option_type": "text",
                            "option_image": this.state.ansImgOpt2Chk
                        },
                        {
                            "option_name": "C",
                            "option_value": this.state.ansOpt3,
                            "option_type": "text",
                            "option_image": this.state.ansImgOpt3Chk
                        },
                        {
                            "option_name": "D",
                            "option_value": this.state.ansOpt4,
                            "option_type": "text",
                            "option_image": this.state.ansImgOpt4Chk
                        },
                        // {
                        //     "option_name": "E",
                        //     "option_value": this.state.ansImgOpt5,
                        //     "option_type": "text",
                        //     "option_image": this.state.ansImgOpt5.replace(/^.*[\\\/]/, '')
                        // }
                    ]
                }


            }

            let url = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/update-question'
            let result = await axios.post(url, post_data)
            console.log(result);
            UIkit.modal.alert('Question Edited Successfully !!')
            this.getQuestionListForAssessment();
            this.setState({ editFlag: false })


        } else {
            UIkit.modal.alert('Please fill the form!!')

        }
    }

    saveSingleAnsAssessmentQuestion = async (type) => {
        let questiontxt;
        let correctAnswer;
        let questionTypeTxt;
        let questionTypeId
        if (type == 'openend') {
            questiontxt = this.state.openEndQuestion
            correctAnswer = this.state.openEndAnswer
            questionTypeTxt = 'subjective'
            questionTypeId = '4'
        } else if (type == 'fillblank') {
            questiontxt = this.state.fillbQuestion
            correctAnswer = this.state.fillbAnswer
            questionTypeTxt = 'fill in the blanks'
            questionTypeId = '3'
        }

        let userObj = JSON.parse(localStorage.getItem('user'));
        let teacherId = userObj.id;
        let schoolId = userObj.schoolId;
        // const { quiz, board, grade, subject, assessmentID } = this.props.location.state
        const { quiz, board, board_id, grade_id, subject_id, grade, subject, assessmentID } = this.props.location.state
        let reqObj = {
            board_id: board_id,
            created_at: new Date(),
            questionText: questiontxt,
            question_image: type == 'fillblank' ? this.state.fillbQuestionImg : this.state.openEndAnswerImg,
            sequence: this.state.questionsListArr.length + 1,
            board: board,
            grade: grade,
            subject_id: subject_id,
            updated_at: new Date(),
            grade_id: grade_id,
            subject: subject,
            school_id: schoolId,
            teacher_id: teacherId,
            assessment_id: assessmentID,
            answer: {
                "option_name": 'a',
                "option_value": correctAnswer
            },
            note: "asdsad",
            isDelete: 0,
            asset_type: "17",
            type: questionTypeTxt,
            question_type_id: questionTypeId,
            data: {
                options: [
                    {
                        "option_name": "A",
                        "option_value": correctAnswer,
                        "option_type": "text",
                        "option_image": type == 'fillblank' ? this.state.fillbAnswerImg : this.state.openEndAnswerImg
                    },
                ]
            }
        }

        let url = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/save-assessment-question'
        let result = await axios.post(url, reqObj)
        console.log(result);
        UIkit.modal.alert('Question Created Successfully !!')
        this.getQuestionListForAssessment();

    }


    editSingleAnsAssessmentQuestion = async (type) => {
        let questiontxt;
        let correctAnswer;
        let questionTypeTxt;
        let questionTypeId
        if (type == 'openend') {
            questiontxt = this.state.openEndQuestion
            correctAnswer = this.state.openEndAnswer
            questionTypeTxt = 'subjective'
            questionTypeId = '4'
        } else if (type == 'fillblank') {
            questiontxt = this.state.fillbQuestion
            correctAnswer = this.state.fillbAnswer
            questionTypeTxt = 'fill in the blanks'
            questionTypeId = '3'
        }

        let userObj = JSON.parse(localStorage.getItem('user'));
        let teacherId = userObj.id;
        let schoolId = userObj.schoolId;
        // const { quiz, board, grade, subject, assessmentID } = this.props.location.state
        const { quiz, board, board_id, grade_id, subject_id, grade, subject, assessmentID } = this.props.location.state


        let post_data = {
            question_id: this.state.question_id,
            subject_id: subject_id,
            board_id: board_id,
            questionText: questiontxt,
            question_image: type == 'fillblank' ? this.state.fillbQuestionImg : this.state.openEndQuestionImg,
            sequence: this.state.sequence,
            board: board,
            grade: grade,
            grade_id: grade_id,
            subject: subject,
            school_id: schoolId,
            teacher_id: teacherId,
            assessment_id: assessmentID,
            answer: {
                "option_name": 'a',
                "option_value": correctAnswer
            },
            note: "asdsad",
            isDelete: 0,
            asset_type: "17",
            type: questionTypeTxt,
            question_type_id: questionTypeId,
            data: {
                options: [
                    {
                        "option_name": "A",
                        "option_value": correctAnswer,
                        "option_type": "text",
                        "option_image": type == 'fillblank' ? this.state.fillbAnswerImg : this.state.openEndAnswerImg
                    },
                ]

            }

        }

        let url = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/update-question'
        let result = await axios.post(url, post_data)
        console.log(result);
        UIkit.modal.alert('Question Edited Successfully !!')
        this.getQuestionListForAssessment();
        this.setState({ editFlag: false })



    }

    updateCorrectOption = (val) => {
        console.log(val);
        this.setState({
            correctOption: val
        })

    }

    getUrlWithToken = (url) => {
        let arrParamUrl = url.split('https://api.stepapp.in');
        if (arrParamUrl && arrParamUrl.length > 0) {
            let urlWithToken = this.getVerifyParam(arrParamUrl[1]);
            return url + urlWithToken;
        }
    }

    getVerifyParam = (queryString) => {
        let hmack = 'thisisasharedsecret'
        let HASHKey = hmack;
        let hasher = new Sha256();
        let hmac = new Hmac(fromUtf(HASHKey), hasher);
        let timestamp = Math.round(new Date().getTime() / 1000); //1591927981; //
        let separator = `${queryString.includes("?") ? "&" : "?"}verify`;
        hmac.update(fromUtf(queryString + timestamp));
        let hmacresult = toBase64(hmac.finalize());
        let updatedParam = `${separator}=${timestamp}-${encodeURIComponent(
            hmacresult
        )}`;
        return updatedParam;
    }

    deleteSelectQuiz = async () => {
        // UIkit.modal.confirm('')
        console.log(this.props.location.state);

        const { assessmentID } = this.props.location.state != undefined ? this.props.location.state : '';
        console.log(assessmentID);
        let reqObj = {
            assessment_id: assessmentID
        }
        let x = this
        UIkit.modal.confirm('Are you sure you want to delete ?').then(async function () {
            // console.log('Confirmed.')

            let url = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/delete-assessment'
            let result = await axios.post(url, reqObj)
            console.log(result);
            x.props.history.push('/create-quiz');



        }, function () {
            console.log('Rejected.')
        });


    }


    deleteSelectQuizQuestion = async (e) => {
        let reqObj = {
            question_id: e
        }
        let x = this
        UIkit.modal.confirm('Are you sure you want to delete ?').then(async function () {
            let url = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/delete-question'
            let result = await axios.post(url, reqObj)
            x.getQuestionListForAssessment();
        }, function () {
            console.log('Rejected.')
        });
    }


    copySelectQuizQuestion = async (e, i) => {
        await this.clearQuestionState();
        // Question TYPE
        //  mcq				    1
        // mcms 			    2
        // subjective		    3	
        // fill in the blanks	4

        // {
        //     optSelect: 'A',
        //     optName: "option1",
        //     optValue: null,
        //     optImage: null
        // },

        if (e.type === "mcq") {
            await this.setState({
                editFlag: i === 'edit' ? true : false,
                questiontxtMcq: e.questionText,
                questionimgMcq: e.question_image,
                createQuizType: "mcq",
                mcqOptionArr: e.data.options.map((a, i) => ({
                    optSelect: a.option_name,
                    optName: `option${i + 1}`,
                    optValue: a.option_value,
                    optImage: a.option_image
                })),
                // ansOpt1Mcq: e.data.options[0].option_value,
                // ansImgOpt1Mcq: e.data.options[0].option_image,
                // ansOpt2Mcq: e.data.options[1].option_value,
                // ansImgOpt2Mcq: e.data.options[1].option_image,
                // ansOpt3Mcq: e.data.options[2].option_value,
                // ansImgOpt3Mcq: e.data.options[2].option_image,
                // ansOpt4Mcq: e.data.options[3].option_value,
                // ansImgOpt4Mcq: e.data.options[3].option_image,
                // ansOpt5Mcq: e.options[4].option_value,
                // ansImgOpt5Mcq: e.options,
                checkedValue: e.answer.option_name,
                question_id: e._id,
                sequence: e.sequence
            }, () => {
                // UIkit.modal('#multichoiceModal').show();
            })
        } else if (e.type === "mcms") {
            let arr = []
            let arrVal = []
            let b = e.answer.map(i => arr.push(i.option_name))
            let c = e.answer.map(i => arrVal.push(i.option_value))

            await this.setState({
                editFlag: i === 'edit' ? true : false,
                questiontxt: e.questionText,
                questionimg: e.question_image,
                createQuizType: "mcms",
                mcqOptionArr: e.data.options.map((a, i) => ({
                    optSelect: a.option_name,
                    optName: `option${i + 1}`,
                    optValue: a.option_value,
                    optImage: a.option_image
                })),
                // ansOpt1: e.data.options[0].option_value,
                // ansImgOpt1Mcq: e.data.options[0].option_image,
                // ansOpt2: e.data.options[1].option_value,
                // ansImgOpt2Mcq: e.data.options[1].option_image,
                // ansOpt3: e.data.options[2].option_value,
                // ansImgOpt3Mcq: e.data.options[2].option_image,
                // ansOpt4: e.data.options[3].option_value,
                // ansImgOpt4Mcq: e.data.options[3].option_image,
                // ansOpt5Mcq: e.options[4].option_value,
                // ansImgOpt5Mcq: e.options,
                Checked: arr,
                CheckedValue: arrVal,

                question_id: e._id,
                sequence: e.sequence
            })
            // UIkit.modal('#checkbox_Modal').show();
        } else if (e.type === "subjective") {
            console.log(e.type)
            console.log(e);
            await this.setState({
                editFlag: i === 'edit' ? true : false,
                openEndQuestion: e.questionText,
                openEndAnswer: e.answer.option_value,
                openEndQuestionImg: e.question_image,
                openEndAnswerImg: e.data[0].option_image,
                question_id: e._id,
                sequence: e.sequence,
                createQuizType: "subjective",
            })
            // UIkit.modal('#openended_Modal').show();
        } else if (e.type === "fill in the blanks") {

            await this.setState({
                editFlag: i === 'edit' ? true : false,
                fillbQuestion: e.questionText,
                fillbAnswer: e.answer.option_value,
                question_id: e._id,
                sequence: e.sequence,
                createQuizType: "fillblank",
            })
        }

        UIkit.modal('#createQuestionModal').show();

    }


    loadData = () => {
        const { board_id, grade_id, subject } = this.props.location.state

        this.setState({
            board_id: board_id,
            grade_id: grade_id,
            subjectName: subject,
        })
    }

    handleExcelUploadTrigger = () => {
        alert("pp");
        this.handleExcelUploadRef.click();
    }

    processData = (dataString) => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }

        // prepare columns list from headers
        const columns = headers.map(c => ({
            name: c,
            selector: c,
        }));
        console.log("list", list)
        console.log("columns", columns)

        // setData(list);
        // setColumns(columns);
    }

    handleExcelUpload = (e) => {
        console.log(e.target);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            this.processData(data);
        };
        reader.readAsBinaryString(file);
    }

    componentDidMount() {
        this.getQuestionListForAssessment();
        this.loadData();
        console.log(this.props.location.state);
        var currentdate = new Date();
        var curr = moment(currentdate).format('YYYY-MM-DD HH:mm:ss')
        console.log(curr);

        // console.log(dateFormat(curr, "h:MM TT"));
        // console.log(dateFormat(this.props.location.state.update_at, "h:MM TT"));
        this.setState({
            boardListArr: this.props.location.state.boardListArr,
            gradeListArr: this.props.location.state.gradeListArr,
            subjectListArr: this.props.location.state.subjectListArr,
            boardName: this.props.location.state.board,
            gradeName: this.props.location.state.grade,
            quizName: this.props.location.state.quiz,
            subjectName: this.props.location.state.subject,
            duration: this.props.location.state.duration,
            description: this.props.location.state.description,
            publishflg: this.props.location.state.publishflg,
            currentDate: curr,
            assessment_image_url: this.props.location.state.assessment_image_url
        })



    }


    imageUrl = async (e) => {

        e.preventDefault();
        this.setState({
            loader: true,
            fieldName: e.target.name
        })

        let filee = e.target.files[0].name

        let fieldName = e.target.name

        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        // let a =''
        reader.onloadend = async () => {
            // a =  reader.result
            let url = 'https://api.stepapp.in/image-upload'
            // let url1 = 'https://api.stepapp.in/user';
            let urlFinal = this.getUrlWithToken(url)
            let reqObj = {
                imageName: filee,
                assessmentId: this.props.location.state.assessmentID,
                userId: "101",
                questionId: "1",
                encodedImage: reader.result.split('base64,').pop()

            }

            let result = await axios.post(urlFinal, reqObj)
            // console.log(e.target.name);

            console.log({ fieldName });

            this.setState({
                [fieldName]: result.data.location,
                loader: false

            }, () => {
                console.log(this.state)
            })
        };

    }


    quizimageUrl = async (e) => {

        e.preventDefault();
        this.setState({
            loader: true,
            fieldName: e.target.name

        })


        let filee = e.target.files[0].name

        let fieldName = e.target.name

        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        // let a =''
        reader.onloadend = async () => {
            // a =  reader.result
            let url = 'https://api.stepapp.in/image-upload'
            // let url1 = 'https://api.stepapp.in/user';
            let urlFinal = this.getUrlWithToken(url)
            let reqObj = {
                imageName: filee,
                assessmentId: this.props.location.state.assessmentID,
                userId: "101",
                questionId: "1",
                encodedImage: reader.result.split('base64,').pop()

            }

            let result = await axios.post(urlFinal, reqObj)
            // console.log(e.target.name);

            console.log({ fieldName });

            this.setState({
                [fieldName]: result.data.location,
                loader: false

            }, () => {
                console.log(this.state)
            })

            this.onImageSubmit(result.data.location)
        };


    }


    quesImg(e) {
        // this.inputElement.click();
        document.getElementById(e).click();
        // if (e === 'quizimg') {
        // }
        // if (e === 'ansImgOpt1') {
        //     document.getElementById('ansImgOpt1').click();
        // }
        // if (e === 'ansImgOpt2') {
        //     document.getElementById('ansImgOpt2').click();
        // }
        // if (e === 'questionimg') {
        //     document.getElementById('questionimg').click();
        // }
        // if (e === 'ansImgOpt3') {
        //     document.getElementById('ansImgOpt3').click();
        // }
        // if (e === 'ansImgOpt4') {
        //     document.getElementById('ansImgOpt4').click();
        // }
        // if (e === 'ansImgOpt1Chk') {
        //     document.getElementById('ansImgOpt1Chk').click();
        // }
        // if (e === 'ansImgOpt2Chk') {

        //     document.getElementById('ansImgOpt2Chk').click();
        // }
        // if (e === 'ansImgOpt3Chk') {

        //     document.getElementById('ansImgOpt3Chk').click();
        // }
        // if (e === 'ansImgOpt4Chk') {

        //     document.getElementById('ansImgOpt4Chk').click();
        // }
        // if (e === 'questionimgChk') {
        //     document.getElementById('questionimgChk').click();
        // }
        // if (e === 'fillbQuestionImg') {
        //     document.getElementById('fillbQuestionImg').click();
        // }
        // if (e === 'fillbAnswerImg') {
        //     document.getElementById('fillbAnswerImg').click();
        // }
        // if (e === 'openEndQuestionImg') {
        //     document.getElementById('openEndQuestionImg').click();
        // }
        // if (e === 'openEndAnswerImg') {
        //     document.getElementById('openEndAnswerImg').click();
        // }


    }
    quesTick = (e) => {
        //  console.log("aaaaaa");
        //  var ticked=  document.getElementById('1').checked; 
        //  this.setState({checkedValue:"ansImgOpt1"})

        // return ticked;
        if (e === '1') {
            var ticked = document.getElementById('1').checked;
            this.setState({
                checkedValue: "A",
                checkedName: this.state.ansOpt1Mcq
            })

            return ticked;

        } else if (e === '2') {
            var ticked = document.getElementById('2').checked;
            this.setState({ checkedValue: "B", checkedName: this.state.ansOpt2Mcq })

            return ticked;

        }
        else if (e === '3') {

            var ticked = document.getElementById('3').checked;
            this.setState({ checkedValue: "C", checkedName: this.state.ansOpt3Mcq })

            return ticked;
        }
        else if (e === '4') {

            var ticked = document.getElementById('4').checked;
            this.setState({ checkedValue: "D", checkedName: this.state.ansOpt4Mcq })

            return ticked;
        }

        else {
            var ticked = document.getElementById('5').checked;
            this.setState({ checkedValue: "E", checkedName: this.state.ansOpt5Mcq })

            return ticked;

        }


    }

    checkBoxTick = (e) => {
        console.log(e);

        //     let { Checked } = this.state
        //     if(Checked.length < 2){
        //     const currentIndex = Checked.indexOf(e);
        //     const newChecked = [...Checked];
        //     if (currentIndex === -1) {
        //         newChecked.push(e)
        //     } else {
        //         newChecked.splice(currentIndex, 1)
        //     }
        //     let val =[]
        //     if(newChecked.length > 0){
        //         newChecked.map((i,j) => {
        //             if(i.includes('A')){
        //                 val.push(this.state.ansOpt1)
        //             }
        //             else if(i.includes('B')){
        //                 val.push(this.state.ansOpt2)
        //             }
        //             else if(i.includes('C')){
        //                 val.push(this.state.ansOpt3)
        //             }
        //             else if(i.includes('D')){
        //                 val.push(this.state.ansOpt4)
        //             }
        //         })

        //     }

        //     this.setState({
        //         Checked:newChecked,
        //         CheckedValue:val
        //     })
        // } else {
        //     const currentIndex = Checked.indexOf(e);
        //     const newChecked = [...Checked];
        //     if (currentIndex !== -1) {
        //         // newChecked.push(e)
        //         newChecked.splice(currentIndex, 1)
        //     } 
        //     let val =[]
        //     if(newChecked.length > 0){
        //         newChecked.map((i,j) => {
        //             if(i.includes('A')){
        //                 val.push(this.state.ansOpt1)
        //             }
        //             else if(i.includes('B')){
        //                 val.push(this.state.ansOpt2)
        //             }
        //             else if(i.includes('C')){
        //                 val.push(this.state.ansOpt3)
        //             }
        //             else if(i.includes('D')){
        //                 val.push(this.state.ansOpt4)
        //             }
        //         })

        //     }
        //     this.setState({
        //         Checked:newChecked,
        //         CheckedValue:val
        //     })
        // }

        let { Checked } = this.state
        const currentIndex = Checked.indexOf(e);
        const newChecked = [...Checked];
        if (Checked.length < 2) {
            if (currentIndex === -1) {
                newChecked.push(e)
            } else {
                newChecked.splice(currentIndex, 1)
            }
        } else {
            if (currentIndex !== -1) {
                newChecked.splice(currentIndex, 1)
            }

        }
        let val = []
        if (newChecked.length > 0) {
            newChecked.map((i, j) => {
                if (i.includes('A')) {
                    val.push(this.state.mcqOptionArr.find(a => a.optSelect === 'A').optName)
                }
                else if (i.includes('B')) {
                    val.push(this.state.mcqOptionArr.find(a => a.optSelect === 'B').optName)
                }
                else if (i.includes('C')) {
                    val.push(this.state.mcqOptionArr.find(a => a.optSelect === 'C').optName)
                }
                else if (i.includes('D')) {
                    val.push(this.state.mcqOptionArr.find(a => a.optSelect === 'D').optName)
                }
            })

        }
        this.setState({
            Checked: newChecked,
            CheckedValue: val
        })

    }

    addOptions = () => {
        this.setState({
            addOption: true
        })

    }

    onCancel = (e) => {
        this.setState({
            questiontxtMcq: '',
            questionimgMcq: '',
            ansOpt1Mcq: '',
            ansImgOpt1Mcq: '',
            ansOpt2Mcq: '',
            ansImgOpt2Mcq: '',
            ansOpt3Mcq: '',
            ansImgOpt3Mcq: '',
            ansOpt4Mcq: '',
            ansImgOpt4Mcq: '',
            ansOpt5Mcq: '',
            ansImgOpt5Mcq: '',
            checkedValue: '',
            editFlag: false,
            fillbQuestion: "",
            fillbQuestionImg: "",
            fillbAnswer: "",
            fillbAnswerImg: "",
            openEndQuestion: "",
            openEndQuestionImg: "",
            openEndAnswer: "",
            openEndAnswerImg: "",
            questiontxt: '',
            questionimg: '',
            questionimgChk: '',
            ansOpt1: '',
            ansImgOpt1Chk: '',
            ansOpt2: '',
            ansImgOpt2Chk: '',
            ansOpt3: '',
            ansImgOpt3Chk: '',
            ansOpt4: '',
            ansImgOpt4Chk: '',
            ansOpt5: '',
            ansImgOpt5: '',
            Checked: '',
            fieldName: ''




        })
    }

    handleChange = (e) => {
        console.log("PP")
        this.setState({
            checkedValue: e.target.value
        });
    };

    handleCheckboxChange = (val) => {
        console.log(val);
        let multicheckedArr = this.state.multicheckedValueArr
        multicheckedArr.push(val);
        console.log(multicheckedArr);
        // this.setState({
        //     multicheckedValueArr: e.target.value
        // });
    }


    editQuizUpdate = (e) => {
        // console.log(e.target.options[e.target.selectedIndex].dataset.valname)
        // console.log(e.target.value,e.target.name,e.target.id);
        if (e.target.name == 'boardId') {
            this.setState({
                board_id: e.target.options[e.target.selectedIndex].dataset.valname
            })
        }
        if (e.target.name == 'gradeId') {
            this.setState({
                gradeName: e.target.options[e.target.selectedIndex].dataset.valname
            })
        }
        if (e.target.name == 'subjectName') {
            this.setState({
                subjectId: e.target.id
            })
        }
        this.setState({
            [e.target.name]: e.target.value,
        }, () => console.log(this.state));
    }
    onImageSubmit = async (e) => {
        // e.preventDefault()
        console.log(e);


        let { quizName, boardName, gradeName, subjectName, description, duration } = this.state
        console.log(quizName, boardName, gradeName, subjectName);
        const inputData = {

            assessment_id: this.props.location.state.assessmentID,
            board_id: this.props.location.state.board_id,
            grade_id: this.props.location.state.grade_id,
            board: boardName,
            grade: gradeName,
            subject_id: this.props.location.state.subject_id,
            subject: subjectName,
            name: quizName,
            duration: duration,
            type: "quiz",
            description: description,
            assessment_image_url: e
        }


        let url1 = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/update-assessment';
        let res = await axios.post(url1, inputData)
        console.log(res.data);

        UIkit.modal.alert('Quiz Updated Successfully !!')
        // this.getQuestionListForAssessment();
        this.setState({
            assessment_image_url: e
        })

        // this.props.history.push('/create-quiz');


    }


    onSubmit = async (e) => {
        e.preventDefault()
        console.log(this.state.assessmentMainImg);


        let { quizName, boardName, gradeName, subjectName, description, duration } = this.state
        console.log(quizName, boardName, gradeName, subjectName);
        const inputData = {

            assessment_id: this.props.location.state.assessmentID,
            board_id: this.props.location.state.board_id,
            grade_id: this.props.location.state.grade_id,
            board: boardName,
            grade: gradeName,
            subject_id: this.props.location.state.subject_id,
            subject: subjectName,
            name: quizName,
            duration: duration,
            type: "quiz",
            description: description,
            assessment_image_url: this.state.assessmentMainImg
        }


        let url1 = 'https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD/update-assessment';
        let res = await axios.post(url1, inputData)
        console.log(res.data);

        UIkit.modal.alert('Quiz Updated Successfully !!')
        this.props.history.push('/create-quiz');

    }

    showUpdateModal = () => {

        // UIkit.modal('#updatequizmodal', 'show');
        UIkit.modal('#updatequizmodal').show();
    }

    getImageName = (url) => {
        const value = url.replace(/^.*[\\\/]/, '')
        return value.length > 10 ? value.substring(0, 7) + "..." : value;
    }


    showMcqModal = () => {
        this.onCancel()

        UIkit.modal('#multichoiceModal').show();
    }



    createOption() {
        return this.state.mcqOptionArr.map((el, i) =>
            // <div key={i}>
            //    <input type="text" value={el||''} onChange={this.handleChange.bind(this, i)} />
            //    <input type='button' value='remove' onClick={this.removeClick.bind(this, i)}/>
            // </div>  
            <li key={i}>
                <span className={this.state.checkedValue === "A" ? "tick_img" : "gry_tick"} name="correctOption" onClick={() => this.updateCorrectOption('opt1')}>
                </span>
                <input style={{ visibility: "hidden", width: "1px", height: "1px" }} checked={this.state.checkedValue === "A"} id="1" type="radio" value="A" onChange={this.handleChange} />
                <a className={this.state.checkedValue === "A" ? "tick_img" : "gry_tick"} onClick={() => this.quesTick('1')} > <img src={circleGrn} /> </a>

                {/* <span className="tick_img gry_tick"><img src={circleGrn} /></span> */}
                <input type="text" id="ansOpt1" name="ansOpt1Mcq" value={this.state.ansOpt1Mcq} onChange={this.updateFormField} placeholder="Answer option 1" />
                <input id="ansImgOpt1" type="file" style={{ visibility: "hidden", width: "1px", height: "1px" }} name="ansImgOpt1Mcq" onChange={this.imageUrl} />
                <a className="media_img" onClick={() => this.quesImg('ansImgOpt1')} ><img src={Media} />  {this.getImageName(this.state.ansImgOpt1Mcq.replace(/^.*[\\\/]/, ''))} </a>
            </li>
        )
    }

    addOptions() {
        this.setState(prevState => ({ mcqOptionArr: [...prevState.mcqOptionArr, ''] }))
    }

    selectCreateQuizType = (val, selbgImg) => {
        //  mcq				    1
        // mcms 			    2
        // subjective		    3	
        // fill in the blanks	4

        this.setState({
            createQuizType: val,
            selbgImg: selbgImg
        }, () => {
            this.clearQuestionState();
        })
    }

    updateMcqOption(e, option) {
        option.optValue = e
        this.forceUpdate()
    }

    mcqQuestionTick(val, select) {
        if (val) {
            var ticked = document.getElementById(val).checked;
            this.setState({
                checkedValue: select,
                checkedName: val.split('_')[0]
            })
            return ticked
        }
    }

    clearQuestionState = () => {
        this.setState({
            mcqOptionArr: defaultOptionArray.map(a => ({ ...a, optValue: "", optImage: "" })),
            questiontxtMcq: "",
            questionimgMcq: "",
            fillbAnswer: "",
            fillbAnswerImg: "",
            openEndAnswer: "",
            openEndAnswerImg: "",
            checkedValue: '',
            checkedName: '',
            Checked: [],
            CheckedValue: [],
        })
    }

    updateAnswerImage(e, option) {
        e.preventDefault();
        this.setState({
            loader: true,
        })

        let filee = e.target.files[0].name
        let fieldName = e.target.name
        let reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);
        // let a =''
        reader.onloadend = async () => {
            let url = 'https://api.stepapp.in/image-upload'
            let urlFinal = this.getUrlWithToken(url)
            let reqObj = {
                imageName: filee,
                assessmentId: this.props.location.state.assessmentID,
                userId: "101",
                questionId: "1",
                encodedImage: reader.result.split('base64,').pop()

            }

            let result = await axios.post(urlFinal, reqObj)

            option.optImage = result.data.location;

            this.setState({
                loader: false
            })
        };
    }

    ChangeModalDropDown = () => (
        <div data-uk-dropdown="mode: click">
            <ul className="uk-nav uk-dropdown-nav modal_drpdwn" >
                <li onClick={() => this.selectCreateQuizType('mcq', MultipleChoice)}>
                    <span className="clr_blue"><img src={MultipleChoice} /></span>
                    <p>Multiple Choice</p>
                </li>
                <li onClick={() => this.selectCreateQuizType('mcms', Checkbox)}>
                    <span className="clr_prpl"><img src={Checkbox} /></span>
                    <p>Checkbox</p>
                </li>
                <li onClick={() => this.selectCreateQuizType('fillblank', FillInTheBlank)}>
                    <span className="clr_mrn"><img src={FillInTheBlank} /></span>
                    <p>Fill-in-the-Blank</p>
                </li>
                {/* <li onClick={() => this.selectCreateQuizType('poll', Poll)}>
                    <span className="clr_grn"><img src={Poll} /></span>
                    <p>Poll</p>
                </li> */}
                <li onClick={() => this.selectCreateQuizType('subjective', OpenEnded)}>
                    <span className="clr_dble"><img src={OpenEnded} /></span>
                    <p>Open-ended</p>
                </li>
            </ul>
        </div>
    )

    addMcqOption = () => {
        const values = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        const usedValues = this.state.mcqOptionArr.map(a => a.optSelect);


        const selectValue = values[usedValues.length];


        const newArray = [...this.state.mcqOptionArr, {
            optSelect: selectValue,
            optName: `option${usedValues.length + 1}`,
            optValue: "",
            optImage: ""
        }]


        this.setState({
            mcqOptionArr: newArray
        })
    }

    removeOption = (index) => {
        let newArray = [...this.state.mcqOptionArr]

        if (newArray.length > 2) {
            newArray.splice(index, 1)
            this.setState({
                mcqOptionArr: newArray
            })
        } else {
            return message.error("2 options should be present!")
        }
    }

    render() {
        // if(this.props.location.state != undefined){
        const { quiz, board, grade, subject } = this.props.location.state != undefined ? this.props.location.state : ''
        // }
        // console.log(this.state.assessmentDetail);
        var currentdate = new Date();
        // var curr = moment(currentdate).format('YYYY-MM-DD HH:mm:ss')
        // console.log(curr);
        // let b = moment.duration(curr.diff(this.props.location.state.updated_at))
        // var t1 = moment('05:34:01', "HH:mm:ss");
        // var t2 = moment('20:44:44', "HH:mm:ss");

        var start_date = moment(this.props.location.state.updated_at, 'YYYY-MM-DD HH:mm:ss');
        var end_date = moment(currentdate, 'YYYY-MM-DD HH:mm:ss');
        var duration = moment.duration(end_date.diff(start_date));
        // console.log(this.props.location.state.updated_at);

        return (
            <SiteLayout>
                <div className="uk-container uk-container-small">
                    <a className="uk-button uk-button-default trns_btn" onClick={this.goBack}><span uk-icon="icon: chevron-left; ratio: 1"></span> Back</a>
                    <div className="uk-child-width-1-2 create_quize_main" data-uk-grid>
                        <div className="create_quize_left">
                            <div className="addQuize_img">
                                <a>
                                    <span><img src={this.state.assessment_image_url !== '' ? this.state.assessment_image_url : featherImage} width="85" height="85" /></span>
                                    {/* <p>+ Add Quiz image</p> */}
                                    {/* <label htmlFor="file-input-id">Upload</label> */}
                                    <input id="quizimg" type="file" style={{ visibility: "hidden", width: "1px", height: "1px" }} name="quizimg" onChange={this.quizimageUrl} />
                                    <a className="media_img" onClick={() => this.quesImg('quizimg')} > + Add Quiz image  {this.getImageName(this.state.quizimg.replace(/^.*[\\\/]/, ''))} </a>
                                    {/* <input type="file" id="file-input-id" onChange={this.onSelectImage} /> */}
                                </a>
                            </div>
                            <div className="addQize_text"><h2>{quiz}</h2>
                                <p>{subject}</p>
                                <p>{board} Board <span className="ml_20">Grade : {grade}</span></p>
                                <strong>Add at least 4 Questions : <span>{this.state.questionsListArr.length > 0 ? this.state.questionsListArr.length : 0}</span></strong>
                            </div>
                        </div>
                        <div className="create_quize_right">
                            <a
                                // data-uk-toggle="target: #updatequizmodal" 
                                onClick={this.showUpdateModal} className="quize_edit"><img src={editBtn} /> </a>
                            {/* <a href="#" className="quize_share"><img src={share} /> </a> */}
                        </div>
                    </div>

                    <div className="quiz_editor_main quiz_editor_btns">
                        <h2 className="quiz_left">
                            <span><img src={idolImg} /></span><p>{this.state.displayName}</p>
                            {/* <p className="time_min"><strong>.</strong> { dateFormat(this.state.currentDate, "h:MM TT") - dateFormat(this.props.location.state.updated_at, "h:MM TT")} ago by</p> */}
                            {/* <p className="time_min"><strong>.</strong> { duration.days() + ":" + duration.hours() + ":" + duration.minutes()  } ago by</p> */}
                            <p className="time_min"><strong>.</strong> {this.props.location.state.updated_at !== undefined ? `${duration.days()} days ${duration.hours()} hours ${duration.minutes()} mins ago` : 'Just Created'}  </p>
                        </h2>
                        <ul className="quiz_right">
                            {/* <li>
                                <span><img src={Print} /></span>
                                <p>Print</p>
                            </li>
                            <li>
                                <span><img src={Save} /></span>
                                <p>Save</p>
                            </li> */}
                            <li>
                                <a onClick={this.deleteSelectQuiz}>
                                    <span><img src={delet} /></span>
                                    <p>Delete Quiz</p>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="quiz_editor_main">
                        <h2 className="quiz_left">Quiz Editor </h2>
                        <ul className="quiz_right">
                            <li>
                                {/* <input type="file" accept=".csv,.xlsx,.xls" ref={this.handleExcelUploadRef} onChange={this.handleExcelUpload} />
                                <a onClick={this.handleExcelUploadTrigger}>
                                    <span><img src={importspeadseet} /></span>
                                    <p>Import from speadseet</p>
                                </a> */}
                            </li>
                            <li>
                                <a className="uk-button uk-button-default" onClick={() => this.clearQuestionState()} data-uk-toggle="target:#createQuestionModal">
                                    <span><img src={importspeadseet} /></span>
                                    <p>New question</p>
                                </a>
                            </li>
                            <li>
                                {/* <span><img src={importspeadseet} /></span>
                                <p>Teleport</p> */}
                            </li>
                        </ul>
                    </div>

                    <ul className="quiz_editor_list">
                        <li>
                            <a className="uk-button uk-button-default" onClick={() => this.selectCreateQuizType('mcq', MultipleChoice)} data-uk-toggle="target:#createQuestionModal">
                                {/* <a className="uk-button uk-button-default" data-uk-toggle="target:#multichoiceModal"} > */}
                                <span><img src={MultipleChoice} /></span>
                                <strong>Multiple Choice</strong>
                            </a>
                        </li>
                        <li>
                            <a className="uk-button uk-button-default" onClick={() => this.selectCreateQuizType('mcms', Checkbox)} data-uk-toggle="target:#createQuestionModal">
                                <span><img src={Checkbox} /></span>
                                <strong>Checkbox</strong>
                            </a>
                        </li>
                        <li>
                            <a className="uk-button uk-button-default" onClick={() => this.selectCreateQuizType('fillblank', FillInTheBlank)} data-uk-toggle="target:#createQuestionModal">
                                <span><img src={FillInTheBlank} /> </span>
                                <strong>Fill-in-the-Blank</strong>
                            </a>
                        </li>
                        {/* <li><span><img src={Poll} /> </span><strong>Poll</strong></li> */}
                        <li>
                            <a className="uk-button uk-button-default" onClick={() => this.selectCreateQuizType('subjective', OpenEnded)} data-uk-toggle="target:#createQuestionModal">
                                <span><img src={OpenEnded} /> </span><strong>Open-ended</strong>
                            </a>
                        </li>
                    </ul>


                    {/* ----------------------------------------------------------------------------- */}

                    {this.state.questionsListArr.map((i, e) => {
                        return (
                            <div className="main_question quize_detl_main">
                                <div className="qes_header">
                                    <ul className="qd_left">
                                        {/* <li><span className="menu_icons"><img src={menuBtn} /></span></li> */}
                                        <li><span className="menu_icons circle_g"><img src={MultipleChoice} /></span></li>
                                        <li><h3>Question {e + 1}</h3></li>
                                    </ul>
                                    <ul className="qd_right">
                                        <li><div class="time_select"><span>
                                            <img src={clock} /></span>
                                            <select class="uk-select"><option>30 Seconds</option><option>40 Seconds</option></select></div></li>
                                        <li><span onClick={() => this.copySelectQuizQuestion(i, "edit")} className="qes_btns" data-uk-tooltip="Edit"><img src={editBlack} /></span></li>
                                        <li><span onClick={() => this.copySelectQuizQuestion(i, "copy")} className="qes_btns" data-uk-tooltip="Copy"><img src={copyBlack} /></span></li>
                                        <li><a onClick={() => this.deleteSelectQuizQuestion(i._id)}><span className="qes_btns" data-uk-tooltip="Delete"><img src={delet} /></span></a></li>
                                    </ul>
                                </div>
                                <div className="qes_body">
                                    <h3>{i.questionText}</h3>
                                    <div className="line_heading">
                                        <h4><span>Answer choices</span></h4>
                                    </div>
                                    <ul className="ans_list">
                                        {/* {i.data.options != undefined && Array.isArray(i.answer) !== true ? i.data.options.map((i1, e1) => {
                                            return (
                                                <li><span className={`ans_circle ${i.answer.option_name.toLowerCase() == i1.option_name.toLowerCase() ? `grn_circle` : `red_circle`}`}></span>{i1.option_value}</li>
                                            )
                                        }) : ''}

                                        {i.data.options != undefined && Array.isArray(i.answer) == true ? i.data.options.map((i1, e1) => {
                                            console.log(e1.option_value, i1.option_name)
                                            // console.log(i.answer.some(e => e.option_name.toLowerCase() == i1.option_name.toLowerCase()))
                                            return (
                                                <li><span className={`ans_circle ${i.answer.some(e => e1.option_value.toLowerCase() == i1.option_name.toLowerCase()) == true ? `grn_circle` : `red_circle`}`}></span>{i1.option_value}</li>
                                            )
                                        }) : ''} */}
                                        {i.type == 'mcms' && Array.isArray(i.answer) == true ? i.data.options.map((i1, e1) => {

                                            return (
                                                <li><span className={`ans_circle ${i.answer.some(e => e.option_value.toLowerCase() == i1.option_name.toLowerCase()) == true ? `grn_circle` : `red_circle`}`}></span>{i1.option_value}</li>
                                            )
                                        }) : ''}
                                        {i.type == 'mcq' && Array.isArray(i.answer) !== true ? i.data.options.map((i1, e1) => {

                                            return (
                                                <li><span className={`ans_circle ${i.answer.option_name.toLowerCase() == i1.option_name.toLowerCase() ? `grn_circle` : `red_circle`}`}></span>{i1.option_value}</li>
                                            )
                                        }) : ''}
                                        {i.type == 'fillblank' && Array.isArray(i.answer) !== true ? i.data.map((i1, e1) => {

                                            return (
                                                <>
                                                    <span className={`ans_circle ${i.answer.option_name.toLowerCase() == i1.option_name.toLowerCase() ? `grn_circle` : `red_circle`}`}></span>{i1.option_value}
                                                </>
                                            )
                                        }) : ''}
                                        {i.type == 'subjective' && Array.isArray(i.answer) !== true ? i.data.map((i1, e1) => {
                                            return (
                                                <>
                                                    <span className={`ans_circle ${i.answer.option_name.toLowerCase() == i1.option_name.toLowerCase() ? `grn_circle` : `red_circle`}`}></span>{i1.option_value}
                                                </>
                                            )
                                        }) : ''}
                                    </ul>
                                </div>
                            </div>
                        )
                    }
                    )}

                    {/* ----------------------------------------------------------------------------- */}
                </div>


                {/* <a className="uk-button uk-button-default" data-uk-toggle="target:#newModal">Open</a> */}

                <div id="multichoiceModal" data-uk-modal>
                    <div className="uk-modal-dialog modal_dialog">
                        <div className="uk-modal-header bg_color_gry">
                            {/* <div className="uk-inline modalDrpBtn">
                                <button className="uk-button uk-button-default" type="button">
                                    <span><img src={MultipleChoice} /></span>
                                    <span><img src={whiteDrop} /></span>
                                </button>
                                {this.ChangeModalDropDown()}
                            </div>
                            <h2 className="uk-modal-title">Question 1</h2>
                        </div>
                        <div className="uk-modal-body">
                            {/* <ul data-uk-accordion> */}
                            {/* <li className=""> */}
                            {/* <a className="uk-accordion-title" href="#">Item 1</a> */}
                            <div className="uk-accordion-content">
                                <div className="quesn_box">
                                    <input type="text" id="questiontxt" name="questiontxtMcq" value={this.state.questiontxtMcq} onChange={this.updateFormField} placeholder="Write your question here" />
                                    {/* <input style={{ visibility: "hidden", width: "1px", height: "1px" }} id="questionimg" type="file" name="questionimgMcq" value={this.state.questionimgMcq} onChange={this.imageUrl} />
                                    <a onClick={() => this.quesImg('questionimg')}>{this.state.questionimg ? this.state.questionimg.replace(/^.*[\\\/]/, '') : <img src={Media} />} </a> */}
                                    <input id="questionimg" type="file" style={{ visibility: "hidden", width: "1px", height: "1px" }} name="questionimgMcq" onChange={this.imageUrl} />
                                    {/* {this.state.loader === true && this.state.fieldName === 'questionimgMcq' ? <div uk-spinner="ratio: 1"></div> : ''} */}


                                    <a className="media_img" onClick={() => this.quesImg('questionimg')} >
                                        {/* <img src={Media} />  */}
                                        {this.state.loader === true ? <div uk-spinner="ratio: 1"></div> : <img src={this.state.questionimgMcq !== '' ? this.state.questionimgMcq : Media} width="28.8" height="23.98" />}


                                        {this.getImageName(this.state.questionimgMcq.replace(/^.*[\\\/]/, ''))}
                                    </a>
                                </div>
                                <ul className="quesn_box_list">
                                    <li>
                                        <span className={this.state.checkedValue === "A" ? "tick_img" : "gry_tick"} name="correctOption" onClick={() => this.updateCorrectOption('opt1')}>
                                        </span>

                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} checked={this.state.checkedValue === "A"} id="1" type="radio" value="A" onChange={this.handleChange} />

                                        <a className={this.state.checkedValue === "A" ? "tick_img" : "gry_tick"} onClick={() => this.quesTick('1')} > <img src={circleGrn} /> </a>


                                        {/* <span className="tick_img gry_tick"><img src={circleGrn} /></span> */}
                                        {/* <span className="inputDelt"> */}
                                        <input type="text" id="ansOpt1" name="ansOpt1Mcq" value={this.state.ansOpt1Mcq} onChange={this.updateFormField} placeholder="Answer option 1" />
                                        <img src={delet} />
                                        <input id="ansImgOpt1" type="file" style={{ visibility: "hidden", width: "1px", height: "1px" }} name="ansImgOpt1Mcq" onChange={this.imageUrl} />
                                        {this.state.loader === true && this.state.fieldName === 'ansImgOpt1Mcq' ? <div uk-spinner="ratio: 1"></div> : ''}

                                        <a className="media_img" onClick={() => this.quesImg('ansImgOpt1')} >
                                            {/* <img src={Media} />  */}

                                            {this.state.loader === true && this.state.fieldName === 'ansImgOpt1Mcq' ? <div uk-spinner="ratio: 1"></div> : <img src={this.state.ansImgOpt1Mcq !== '' ? this.state.ansImgOpt1Mcq : Media} width="28.8" height="23.98" />}
                                            {this.getImageName(this.state.ansImgOpt1Mcq.replace(/^.*[\\\/]/, ''))} </a>
                                    </li>
                                    <li>
                                        <span className={this.state.checkedValue === "B" ? "tick_img" : "gry_tick"} name="correctOption" onClick={() => this.updateCorrectOption('opt2')}></span>
                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} checked={this.state.checkedValue === "B"} id="2" type="radio" value="B" onChange={this.handleChange} />
                                        <a className={this.state.checkedValue === "B" ? "tick_img" : "gry_tick"} onClick={() => this.quesTick('2')} > <img src={circleGrn} /> </a>

                                        <input type="text" id="ansOpt2" name="ansOpt2Mcq" value={this.state.ansOpt2Mcq} onChange={this.updateFormField} placeholder="Answer option 2" />
                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} id="ansImgOpt2" ref={input1 => this.inputElement = input1} type="file" name="ansImgOpt2Mcq" onChange={this.imageUrl} />
                                        {this.state.loader === true && this.state.fieldName === 'ansImgOpt2Mcq' ? <div uk-spinner="ratio: 1"></div> : ''}

                                        <a className="media_img" onClick={() => this.quesImg('ansImgOpt2')} >
                                            {/* <img src={Media} />  */}

                                            {this.state.loader === true && this.state.fieldName === 'ansImgOpt2Mcq' ? <div uk-spinner="ratio: 1"></div> : <img src={this.state.ansImgOpt2Mcq !== '' ? this.state.ansImgOpt2Mcq : Media} width="28.8" height="23.98" />}
                                            {this.getImageName(this.state.ansImgOpt2Mcq.replace(/^.*[\\\/]/, ''))} </a>
                                    </li>
                                    <li>
                                        <span className={this.state.checkedValue === "C" ? "tick_img" : "gry_tick"} name="correctOption" onClick={() => this.updateCorrectOption('opt3')}></span>
                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} checked={this.state.checkedValue === "C"} id="3" type="radio" value="C" onChange={this.handleChange} />
                                        <a className={this.state.checkedValue === "C" ? "tick_img" : "gry_tick"} onClick={() => this.quesTick('3')} > <img src={circleGrn} /> </a>
                                        <input type="text" id="ansOpt3" name="ansOpt3Mcq" value={this.state.ansOpt3Mcq} onChange={this.updateFormField} placeholder="Answer option 3" />
                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} id="ansImgOpt3" ref={input3 => this.inputElement = input3} type="file" name="ansImgOpt3Mcq" onChange={this.imageUrl} />
                                        {this.state.loader === true && this.state.fieldName === 'ansImgOpt3Mcq' ? <div uk-spinner="ratio: 1"></div> : ''}

                                        <a className="media_img" onClick={() => this.quesImg('ansImgOpt3')} >
                                            {/* <img src={Media} />  */}
                                            {this.state.loader === true && this.state.fieldName === 'ansImgOpt3Mcq' ? <div uk-spinner="ratio: 1"></div> : <img src={this.state.ansImgOpt3Mcq !== '' ? this.state.ansImgOpt3Mcq : Media} width="28.8" height="23.98" />}
                                            {this.getImageName(this.state.ansImgOpt3Mcq.replace(/^.*[\\\/]/, ''))} </a>
                                    </li>
                                    <li>
                                        <span className={this.state.checkedValue === "D" ? "tick_img" : "gry_tick"} name="correctOption" onClick={() => this.updateCorrectOption('opt4')}></span>
                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} checked={this.state.checkedValue === "D"} id="4" type="radio" value="D" onChange={this.handleChange} />
                                        <a className={this.state.checkedValue === "D" ? "tick_img" : "gry_tick"} onClick={() => this.quesTick('4')} > <img src={circleGrn} /> </a>
                                        <input type="text" id="ansOpt4" name="ansOpt4Mcq" value={this.state.ansOpt4Mcq} onChange={this.updateFormField} placeholder="Answer option 4" />
                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} id="ansImgOpt4" ref={input4 => this.inputElement = input4} type="file" name="ansImgOpt4Mcq" onChange={this.imageUrl} />
                                        {this.state.loader === true && this.state.fieldName === 'ansImgOpt4Mcq' ? <div uk-spinner="ratio: 1"></div> : ''}

                                        <a className="media_img" onClick={() => this.quesImg('ansImgOpt4')} >
                                            {/* <img src={Media} />  */}
                                            {this.state.loader === true && this.state.fieldName === 'ansImgOpt4Mcq' ? <div uk-spinner="ratio: 1"></div> : <img src={this.state.ansImgOpt4Mcq !== '' ? this.state.ansImgOpt4Mcq : Media} width="28.8" height="23.98" />}
                                            {this.getImageName(this.state.ansImgOpt4Mcq.replace(/^.*[\\\/]/, ''))} </a>
                                    </li>
                                    {/* {this.state.addOption === true ? <li>
                                        <span className={this.state.checkedValue === "E" ? "tick_img" : "gry_tick"} name="correctOption" onClick={() => this.updateCorrectOption('opt5')}></span>
                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} checked={this.state.checkedValue === "E"} id="5" type="radio" value="E" onChange={this.handleChange} />
                                        <a className={this.state.checkedValue === "E" ? "tick_img" : "gry_tick"} onClick={() => this.quesTick('5')} > <img src={circleGrn} /> </a>
                                        <input type="text" id="ansOpt5" name="ansOpt5" value={this.state.ansOpt5} onChange={this.updateFormField} placeholder="Answer option 5" />
                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} id="ansImgOpt5" type="file" name="ansImgOpt5" value={this.state.ansImgOpt5} onChange={this.updateFormField} />
                                        <a className="media_img" onClick={() => this.quesImg('ansImgOpt5')} ><img src={Media} />  {this.state.ansImgOpt5.replace(/^.*[\\\/]/, '')} </a>
                                    </li> : ''} */}
                                    <li>



                                        {/* <button onClick={this.addOptions}><span><img src={plusIcon} /></span>Add answer option</button> */}
                                    </li>
                                </ul>
                            </div>
                            {/* </li> */}
                            {/* </ul> */}
                        </div>

                        <div className="uk-modal-footer uk-text-right bg_color_gry modal_footer">
                            <div className="time_select">
                                <span><img src={clock} /></span>
                                <select className="uk-select" id="mcqDuration" name="mcqDuration" value={this.state.mcqDuration} onChange={this.updateFormField}>
                                    <option value="">Duration</option>
                                    <option value="30">30 Seconds</option>
                                    <option value="40">40 Seconds</option>
                                </select>
                            </div>
                            <div className="">
                                <button className="uk-button uk-button-default uk-modal-close comn_btn cls_btn" type="button" onClick={this.onCancel}>Cancel</button>
                                <button className="uk-button uk-button-primary comn_btn save_btn_grn" type="button" onClick={this.state.editFlag === false ? this.saveAssessmentQuestionMcq : this.editAssessmentQuestionMcq}><img src={SaveW} /> {this.state.editFlag === false ? "Save" : "Edit"}</button>
                                {/* <button className="uk-button uk-button-primary comn_btn save_btn_grn" type="button" onClick={this.saveAssessmentQuestionMcq}><img src={SaveW} /> Save</button> */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* <a className="uk-button uk-button-default" data-uk-toggle="target:#newModal2">Open</a> */}

                {/* ------------------------ CHECKBOX MODAL ---------------------------- */}


                <div id="checkbox_Modal" data-uk-modal>
                    <div className="uk-modal-dialog modal_dialog">
                        <div className="uk-modal-header bg_color_gry">
                            <div className="uk-inline modalDrpBtn">
                                <button className="uk-button uk-button-default" type="button">
                                    <span><img src={MultipleChoice} /></span>
                                    <span><img src={whiteDrop} /></span>
                                </button>
                                {this.ChangeModalDropDown()}
                            </div>
                            <h2 className="uk-modal-title">Checkbox Question</h2>
                        </div>
                        <div className="uk-modal-body">
                            {/* <ul data-uk-accordion> */}
                            {/* <li className=""> */}
                            {/* <a className="uk-accordion-title" href="#">Item 1</a> */}
                            <div className="uk-accordion-content">
                                <div className="quesn_box">
                                    <input type="text" id="questiontxt" name="questiontxt" value={this.state.questiontxt} onChange={this.updateFormField} placeholder="Write your question here" />
                                    <input style={{ visibility: "hidden", width: "1px", height: "1px" }} id="questionimgChk" type="file" name="questionimgChk" onChange={this.imageUrl} />
                                    {this.state.loader === true && this.state.fieldName === 'questionimgChk' ? <div uk-spinner="ratio: 1"></div> : ''}

                                    <a onClick={() => this.quesImg('questionimgChk')}>
                                        {this.state.loader === true && this.state.fieldName === 'questionimgChk' ? <div uk-spinner="ratio: 1"></div> : <img src={this.state.questionimgChk !== '' ? this.state.questionimgChk : Media} width="28.8" height="23.98" />}
                                        {this.state.questionimgChk.replace(/^.*[\\\/]/, '')} </a>


                                </div>
                                <ul className="quesn_box_list">
                                    <li>

                                        <span className={this.state.Checked.includes("A") ? "tick_img" : "gry_tick"} name="correctOption" onClick={() => this.updateCorrectOption('opt1')}>
                                        </span>
                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} checked={this.state.Checked.includes("A")} id="1" type="checkbox" value="A" onChange={this.handleChange} />
                                        <a className={this.state.Checked.includes("A") ? "tick_img" : "gry_tick"} onClick={() => this.checkBoxTick('A')} > <img src={circleGrn} /> </a>

                                        <input type="text" id="ansOpt1" name="ansOpt1" value={this.state.ansOpt1} onChange={this.updateFormField} placeholder="Answer option 1" />
                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} id="ansImgOpt1Chk" type="file" name="ansImgOpt1Chk" onChange={this.imageUrl} />
                                        {this.state.loader === true && this.state.fieldName === 'ansImgOpt1Chk' ? <div uk-spinner="ratio: 1"></div> : ''}
                                        {/* {this.state.loader === true && this.state.fieldName === 'ansImgOpt1Chk'  ?  <div uk-spinner="ratio: 1"></div> :''} */}

                                        <a className="media_img" onClick={() => this.quesImg('ansImgOpt1Chk')} >
                                            {this.state.loader === true && this.state.fieldName === 'ansImgOpt1Chk' ? <div uk-spinner="ratio: 1"></div> : <img src={this.state.ansImgOpt1Chk !== '' ? this.state.ansImgOpt1Chk : Media} width="28.8" height="23.98" />}

                                            {this.getImageName(this.state.ansImgOpt1Chk.replace(/^.*[\\\/]/, ''))} </a>
                                    </li>
                                    <li>
                                        <span className={this.state.Checked.includes("B") ? "tick_img" : "gry_tick"} name="correctOption" onClick={() => this.updateCorrectOption('opt2')}></span>
                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} checked={this.state.Checked.includes("B")} id="2" type="checkbox" value="B" onChange={this.handleChange} />
                                        <a className={this.state.Checked.includes("B") ? "tick_img" : "gry_tick"} onClick={() => this.checkBoxTick('B')} > <img src={circleGrn} /> </a>

                                        <input type="text" id="ansOpt2" name="ansOpt2" value={this.state.ansOpt2} onChange={this.updateFormField} placeholder="Answer option 2" />
                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} id="ansImgOpt2Chk" type="file" name="ansImgOpt2Chk" onChange={this.imageUrl} />
                                        {this.state.loader === true && this.state.fieldName === 'ansImgOpt2Chk' ? <div uk-spinner="ratio: 1"></div> : ''}

                                        <a className="media_img" onClick={() => this.quesImg('ansImgOpt2Chk')} >
                                            {this.state.loader === true && this.state.fieldName === 'ansImgOpt2Chk' ? <div uk-spinner="ratio: 1"></div> : <img src={this.state.ansImgOpt2Chk !== '' ? this.state.ansImgOpt2Chk : Media} width="28.8" height="23.98" />}

                                            {this.getImageName(this.state.ansImgOpt2Chk.replace(/^.*[\\\/]/, ''))} </a>
                                    </li>
                                    <li>
                                        <span className={this.state.Checked.includes("C") ? "tick_img" : "gry_tick"} name="correctOption" onClick={() => this.updateCorrectOption('opt3')}></span>
                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} checked={this.state.Checked.includes("C")} id="2" type="checkbox" value="C" onChange={this.handleChange} />
                                        <a className={this.state.Checked.includes("C") ? "tick_img" : "gry_tick"} onClick={() => this.checkBoxTick('C')} > <img src={circleGrn} /> </a>

                                        <input type="text" id="ansOpt3" name="ansOpt3" value={this.state.ansOpt3} onChange={this.updateFormField} placeholder="Answer option 3" />
                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} id="ansImgOpt3Chk" type="file" name="ansImgOpt3Chk" onChange={this.imageUrl} />
                                        {this.state.loader === true && this.state.fieldName === 'ansImgOpt3Chk' ? <div uk-spinner="ratio: 1"></div> : ''}

                                        <a className="media_img" onClick={() => this.quesImg('ansImgOpt3Chk')} >
                                            {this.state.loader === true && this.state.fieldName === 'ansImgOpt3Chk' ? <div uk-spinner="ratio: 1"></div> : <img src={this.state.ansImgOpt3Chk !== '' ? this.state.ansImgOpt3Chk : Media} width="28.8" height="23.98" />}

                                            {this.getImageName(this.state.ansImgOpt3Chk.replace(/^.*[\\\/]/, ''))} </a>
                                    </li>
                                    <li>
                                        <span className={this.state.Checked.includes("D") ? "tick_img" : "gry_tick"} name="correctOption" onClick={() => this.updateCorrectOption('opt4')}></span>
                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} checked={this.state.Checked.includes("D")} id="4" type="checkbox" value="D" onChange={this.handleChange} />
                                        <a className={this.state.Checked.includes("D") ? "tick_img" : "gry_tick"} onClick={() => this.checkBoxTick('D')} > <img src={circleGrn} /> </a>
                                        <input type="text" id="ansOpt4" name="ansOpt4" value={this.state.ansOpt4} onChange={this.updateFormField} placeholder="Answer option 4" />
                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} id="ansImgOpt4Chk" type="file" name="ansImgOpt4Chk" onChange={this.imageUrl} />
                                        {this.state.loader === true && this.state.fieldName === 'ansImgOpt4Chk' ? <div uk-spinner="ratio: 1"></div> : ''}

                                        <a className="media_img" onClick={() => this.quesImg('ansImgOpt4Chk')} >
                                            {this.state.loader === true && this.state.fieldName === 'ansImgOpt4Chk' ? <div uk-spinner="ratio: 1"></div> : <img src={this.state.ansImgOpt4Chk !== '' ? this.state.ansImgOpt4Chk : Media} width="28.8" height="23.98" />}

                                            {this.getImageName(this.state.ansImgOpt4Chk.replace(/^.*[\\\/]/, ''))} </a>
                                    </li>
                                    {this.state.addOption === true ? <li>
                                        <span className={this.state.Checked.includes("5") ? "tick_img" : "gry_tick"} name="correctOption" onClick={() => this.updateCorrectOption('opt5')}></span>
                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} checked={this.state.Checked.includes("5")} id="5" type="checkbox" value="E" onChange={this.handleChange} />
                                        <a className={this.state.Checked.includes("5") ? "tick_img" : "gry_tick"} onClick={() => this.checkBoxTick('5')} > <img src={circleGrn} /> </a>
                                        <input type="text" id="ansOpt5" name="ansOpt5" value={this.state.ansOpt5} onChange={this.updateFormField} placeholder="Answer option 5" />
                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} id="ansImgOpt5" type="file" name="ansImgOpt5" onChange={this.updateFormField} />
                                        {this.state.loader === true ? <div uk-spinner="ratio: 1"></div> : ''}

                                        <a className="media_img" onClick={() => this.quesImg('ansImgOpt5')} ><img src={Media} />  {this.getImageName(this.state.ansImgOpt5.replace(/^.*[\\\/]/, ''))} </a>
                                    </li> : ''}
                                    <li>
                                        <button onClick={this.addOptions}><span><img src={plusIcon} /></span>Add answer option</button>
                                    </li>
                                </ul>
                            </div>
                            {/* </li> */}
                            {/* </ul> */}
                        </div>

                        <div className="uk-modal-footer uk-text-right bg_color_gry modal_footer">
                            <div className="time_select">
                                <span><img src={clock} /></span>
                                <select className="uk-select" id="checkboxDuration" name="checkboxDuration" value={this.state.checkboxDuration} onChange={this.updateFormField}>
                                    <option value="">Duration</option>
                                    <option value="30">30 Seconds</option>
                                    <option value="40">40 Seconds</option>
                                </select>
                            </div>
                            <div className="">
                                <button className="uk-button uk-button-default uk-modal-close comn_btn cls_btn" type="button" onClick={this.onCancel}>Cancel</button>
                                {/* <button className="uk-button uk-button-primary comn_btn save_btn_grn" type="button" onClick={this.saveAssessmentQuestion}><img src={SaveW} /> Save</button> */}
                                <button className="uk-button uk-button-primary comn_btn save_btn_grn" type="button" onClick={this.state.editFlag === false ? this.saveAssessmentQuestion : this.editAssessmentQuestion}><img src={SaveW} /> {this.state.editFlag === false ? "Save" : "Edit"}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="fillblank_Modal" data-uk-modal>
                    <div className="uk-modal-dialog modal_dialog">
                        <div className="uk-modal-header bg_color_gry">
                            <div className="uk-inline modalDrpBtn">
                                <button className="uk-button uk-button-default" type="button">
                                    <span><img src={MultipleChoice} /></span>
                                    <span><img src={whiteDrop} /></span>
                                </button>
                                {this.ChangeModalDropDown()}
                            </div>
                            <h2 className="uk-modal-title">Fill-in-the-Blank Question</h2>
                        </div>
                        <div className="uk-modal-body">
                            {/* <ul data-uk-accordion>
                                <li class="">
                                    <a class="uk-accordion-title" href="#">Item 1</a> */}
                            <div class="uk-accordion-content">
                                <div className="quesn_box">
                                    <input type="text" id="fillbQuestion" name="fillbQuestion" value={this.state.fillbQuestion} onChange={this.updateFormField} placeholder="Write your question here" />
                                    <input style={{ visibility: "hidden", width: "1px", height: "1px" }} id="fillbQuestionImg" type="file" name="fillbQuestionImg" onChange={this.imageUrl} />
                                    {this.state.loader === true && this.state.fieldName === 'fillbQuestionImg' ? <div uk-spinner="ratio: 1"></div> : ''}

                                    <a className="media_img" onClick={() => this.quesImg('fillbQuestionImg')} >
                                        {this.state.loader === true && this.state.fieldName === 'fillbQuestionImg' ? <div uk-spinner="ratio: 1"></div> : <img src={this.state.fillbQuestionImg !== '' ? this.state.fillbQuestionImg : Media} width="28.8" height="23.98" />}
                                        {this.getImageName(this.state.fillbQuestionImg.replace(/^.*[\\\/]/, ''))} </a>

                                    {/* <input type="text" id="fillbQuestion" name="fillbQuestion" placeholder="Write your question here" onChange={this.updateFormField} />
                                    <span><img src={Media} /><p>Media</p></span> */}
                                </div>

                                <div className="quesn_box">
                                    <input type="text" id="fillbAnswer" name="fillbAnswer" value={this.state.fillbAnswer} onChange={this.updateFormField} placeholder="Write your answer here" />
                                    <input style={{ visibility: "hidden", width: "1px", height: "1px" }} id="fillbAnswerImg" type="file" name="fillbAnswerImg" onChange={this.imageUrl} />
                                    {this.state.loader === true && this.state.fieldName === 'fillbAnswerImg' ? <div uk-spinner="ratio: 1"></div> : ''}

                                    <a className="media_img" onClick={() => this.quesImg('fillbAnswerImg')} >
                                        {this.state.loader === true && this.state.fieldName === 'fillbAnswerImg' ? <div uk-spinner="ratio: 1"></div> : <img src={this.state.fillbAnswerImg !== '' ? this.state.fillbAnswerImg : Media} width="28.8" height="23.98" />}
                                        {this.getImageName(this.state.fillbAnswerImg.replace(/^.*[\\\/]/, ''))} </a>

                                    {/* <input type="text" id="fillbAnswer" name="fillbAnswer" placeholder="Write your answer here" onChange={this.updateFormField} />
                                    <span><img src={Media} /><p>Media</p></span> */}
                                </div>

                            </div>
                            {/* </li>
                            </ul> */}
                        </div>

                        <div className="uk-modal-footer uk-text-right bg_color_gry modal_footer">
                            <div className="time_select">
                                <span><img src={clock} /></span>
                                <select class="uk-select">
                                    <option value="">Duration</option>
                                    <option>30 Seconds</option>
                                    <option>40 Seconds</option>
                                </select>
                            </div>
                            <div className="">
                                <button className="uk-button uk-button-default uk-modal-close comn_btn cls_btn" type="button" onClick={this.onCancel}  >Cancel</button>
                                {/* <button className="uk-button uk-button-primary comn_btn save_btn_grn" type="button" onClick={() => this.saveSingleAnsAssessmentQuestion('fillblank')}><img src={SaveW} /> Save</button> */}
                                <button className="uk-button uk-button-primary comn_btn save_btn_grn" type="button"
                                    onClick={this.state.editFlag === false ? () => this.saveSingleAnsAssessmentQuestion('fillblank') : () => this.editSingleAnsAssessmentQuestion('fillblank')}>
                                    <img src={SaveW} /> {this.state.editFlag === false ? "Save" : "Edit"}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="openended_Modal" data-uk-modal>
                    <div className="uk-modal-dialog modal_dialog">
                        <div className="uk-modal-header bg_color_gry">
                            <div className="uk-inline modalDrpBtn">
                                <button className="uk-button uk-button-default" type="button">
                                    <span><img src={MultipleChoice} /></span>
                                    <span><img src={whiteDrop} /></span>
                                </button>
                                {this.ChangeModalDropDown()}
                            </div>
                            <h2 className="uk-modal-title">Open-Ended Question</h2>
                        </div>
                        <div className="uk-modal-body">
                            {/* <ul data-uk-accordion>
                                <li class="">
                                    <a class="uk-accordion-title" href="#">Item 1</a> */}
                            <div class="uk-accordion-content">
                                <div className="quesn_box">
                                    <input type="text" id="openEndQuestion" name="openEndQuestion" value={this.state.openEndQuestion} onChange={this.updateFormField} placeholder="Write your question here" />
                                    <input style={{ visibility: "hidden", width: "1px", height: "1px" }} id="openEndQuestionImg" type="file" name="openEndQuestionImg" onChange={this.imageUrl} />
                                    {this.state.loader === true && this.state.fieldName === 'openEndQuestionImg' ? <div uk-spinner="ratio: 1"></div> : ''}
                                    <a>
                                        {this.getImageName(this.state.openEndQuestionImg.replace(/^.*[\\\/]/, ''))}
                                    </a>

                                    {/* <input type="text" id="openEndQuestion" name="openEndQuestion" placeholder="Write your question here" onChange={this.updateFormField} />
                                    <span><img src={Media} /><p>Media</p></span> */}
                                </div>

                                <div className="quesn_box">
                                    {/* <input type="text" id="openEndAnswer" name="openEndAnswer" value={this.state.openEndAnswer} onChange={this.updateFormField} placeholder="Write your answer here" /> */}
                                    <textarea class="uk-textarea" rows="5" id="openEndAnswer" name="openEndAnswer" value={this.state.openEndAnswer} placeholder="Write your answer here" onChange={this.updateFormField}></textarea>
                                    <input style={{ visibility: "hidden", width: "1px", height: "1px" }} id="openEndAnswerImg" type="file" name="openEndAnswerImg" onChange={this.imageUrl} />
                                    {this.state.loader === true && this.state.fieldName === 'openEndAnswerImg' ? <div uk-spinner="ratio: 1"></div> : ''}
                                    <a>
                                        {this.getImageName(this.state.openEndAnswerImg.replace(/^.*[\\\/]/, ''))} </a>

                                    {/* <input type="text" id="openEndAnswer" name="openEndAnswer" placeholder="Write your answer here" onChange={this.updateFormField} />
                                    <span><img src={Media} /><p>Media</p></span> */}
                                </div>

                            </div>
                            {/* </li>
                            </ul> */}
                        </div>

                        <div className="uk-modal-footer uk-text-right bg_color_gry modal_footer">
                            <div className="time_select">
                                <span><img src={clock} /></span>
                                <select class="uk-select">
                                    <option value="">Duration</option>
                                    <option>30 Seconds</option>
                                    <option>40 Seconds</option>
                                </select>
                            </div>
                            <div className="">
                                <button className="uk-button uk-button-default uk-modal-close comn_btn cls_btn" type="button"  >Cancel</button>
                                {/* <button className="uk-button uk-button-primary comn_btn save_btn_grn" type="button" onClick={() => this.saveSingleAnsAssessmentQuestion('openend')}><img src={SaveW} /> Save</button> */}
                                <button className="uk-button uk-button-primary comn_btn save_btn_grn" type="button"
                                    onClick={this.state.editFlag === false ? () => this.saveSingleAnsAssessmentQuestion('openend') : () => this.editSingleAnsAssessmentQuestion('openend')}>
                                    <img src={SaveW} /> {this.state.editFlag === false ? "Save" : "Edit"}</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* --------------------------- CREATE QUESTION MODAL - START --------------------------- */}
                <div id="createQuestionModal" data-uk-modal>
                    <div className="uk-modal-dialog modal_dialog">
                        <div className="uk-modal-header bg_color_gry">
                            <div className="uk-inline modalDrpBtn">
                                <button className="uk-button uk-button-default" type="button">
                                    <span><img src={this.state.selbgImg} /></span>
                                    <span><img src={whiteDrop} /></span>
                                </button>
                                {this.ChangeModalDropDown()}
                                <h2 className="uk-modal-title">Question {this.state.questionsListArr.length + 1}</h2>
                            </div>
                            <div className="uk-modal-body">
                                {/* <ul data-uk-accordion> */}
                                {/* <li className=""> */}
                                {/* <a className="uk-accordion-title" href="#">Item 1</a> */}
                                <div className="uk-accordion-content">
                                    <div className="quesn_box">
                                        <input type="text" id="questiontxt" name="questiontxtMcq" value={this.state.questiontxtMcq} onChange={this.updateFormField} placeholder="Write your question here" />
                                        {/* <input style={{ visibility: "hidden", width: "1px", height: "1px" }} id="questionimg" type="file" name="questionimgMcq" value={this.state.questionimgMcq} onChange={this.imageUrl} />
                                    <a onClick={() => this.quesImg('questionimg')}>{this.state.questionimg ? this.state.questionimg.replace(/^.*[\\\/]/, '') : <img src={Media} />} </a> */}
                                        <input id="questionimg" type="file" style={{ visibility: "hidden", width: "1px", height: "1px" }} name="questionimgMcq" onChange={this.imageUrl} />
                                        <a className="media_img" onClick={() => this.quesImg('questionimg')} ><img src={Media} />  {this.getImageName(this.state.questionimgMcq.replace(/^.*[\\\/]/, ''))} </a>
                                    </div>
                                    <ul className="quesn_box_list">
                                        {/* -------------------- MCQ OPTION SECTION ------------------------- */}

                                        {this.state.createQuizType == "mcq" &&
                                            <>
                                                {this.state.mcqOptionArr.map((a, i) => (
                                                    <li>
                                                        <span className={this.state.checkedValue === a.optSelect ? "tick_img" : "gry_tick"} name="correctOption" onClick={() => this.updateCorrectOption(`opt${i + 1}`)}>
                                                        </span>
                                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} checked={this.state.checkedValue === a.optSelect} id={`${a.optName}_${i + 1}`} type="radio" value={a.optSelect} onChange={this.handleChange} />
                                                        <a className={this.state.checkedValue === a.optSelect ? "tick_img" : "gry_tick"} onClick={() => this.mcqQuestionTick(`${a.optName}_${i + 1}`, a.optSelect)} > <img src={circleGrn} /> </a>
                                                        <div className="delete_sec">
                                                            <input type="text" id="ansOpt1" name={a.optName} value={a.optValue} onChange={(e) => this.updateMcqOption(e.target.value, a)} placeholder={`Answer option ${i + 1}`} />
                                                            {
                                                                i > 1 ? <a className="delt_icon" onClick={() => this.removeOption(i)} ><img src={delet} /> </a> : ''
                                                            }
                                                        </div>

                                                        <input id={`mcqAnsImg${i}`} type="file" style={{ visibility: "hidden", width: "1px", height: "1px" }} name="ansImgOpt1Mcq" onChange={(e) => this.updateAnswerImage(e, a)} />
                                                        <a className="media_img" onClick={() => this.quesImg(`mcqAnsImg${i}`)} ><img src={Media} />  {a.optImage ? this.getImageName(a.optImage.replace(/^.*[\\\/]/, '')) : ""} </a>
                                                    </li>
                                                ))}
                                                <li>
                                                    <button onClick={() => this.addMcqOption()}><span><img src={plusIcon} /></span>Add answer option</button>
                                                </li>
                                            </>
                                        }

                                        {/* -------------------- CHECKBOX OPTION SECTION ------------------------- */}
                                        {this.state.createQuizType == "mcms" &&
                                            <>
                                                {this.state.mcqOptionArr.map((a, i) => (
                                                    <li>
                                                        <span className={this.state.Checked.includes(a.optSelect) ? "tick_img" : "gry_tick"} name="correctOption" onClick={() => this.updateCorrectOption(`opt${i + 1}`)}>
                                                        </span>
                                                        <input style={{ visibility: "hidden", width: "1px", height: "1px" }} checked={this.state.Checked.includes(a.optSelect)} id={`${a.optName}_${i + 1}`} type="checkbox" value={a.optSelect} onChange={this.handleChange} />
                                                        <a className={this.state.Checked.includes(a.optSelect) ? "tick_img" : "gry_tick"} onClick={() => this.checkBoxTick(a.optSelect)} > <img src={circleGrn} /> </a>
                                                        <div className="delete_sec">
                                                            <input type="text" id="ansOpt1" name={a.optName} value={a.optValue} onChange={(e) => this.updateMcqOption(e.target.value, a)} placeholder={`Answer option ${i + 1}`} />
                                                            {
                                                                i > 1 ? <a className="delt_icon" onClick={() => this.removeOption(i)} ><img src={delet} /> </a> : ''
                                                            }
                                                        </div>
                                                        <input id={`mcmsAnsImg${i}`} type="file" style={{ visibility: "hidden", width: "1px", height: "1px" }} name="ansImgOpt1Mcq" onChange={(e) => this.updateAnswerImage(e, a)} />
                                                        <a className="media_img" onClick={() => this.quesImg(`mcmsAnsImg${i}`)} ><img src={Media} />  {a.optImage ? this.getImageName(a.optImage.replace(/^.*[\\\/]/, '')) : ""} </a>
                                                    </li>
                                                ))}
                                                <li>
                                                    <button onClick={() => this.addMcqOption()}><span><img src={plusIcon} /></span>Add answer option</button>
                                                </li>
                                            </>
                                        }

                                        {/* -------------------- FILL IN BLANK OPTION SECTION ------------------------- */}
                                        {this.state.createQuizType == "fillblank" &&
                                            <>
                                                <li>
                                                    <input type="text" id="fillbAnswer" name="fillbAnswer" value={this.state.fillbAnswer} onChange={this.updateFormField} placeholder="Write your answer here" />
                                                    <input style={{ visibility: "hidden", width: "1px", height: "1px" }} id="fillbAnswerImg" type="file" name="fillbAnswerImg" onChange={this.imageUrl} />
                                                    <a className="media_img" onClick={() => this.quesImg('fillbAnswerImg')} ><img src={Media} />  {this.getImageName(this.state.fillbAnswerImg.replace(/^.*[\\\/]/, ''))} </a>
                                                </li>
                                            </>
                                        }
                                        {/* -------------------- SUBJECTIVE OPTION SECTION ------------------------- */}
                                        {this.state.createQuizType == "subjective" &&
                                            <>
                                                <li>
                                                    <textarea class="uk-textarea" rows="5" id="openEndAnswer" name="openEndAnswer" value={this.state.openEndAnswer} placeholder="Write your answer here" onChange={this.updateFormField}></textarea>
                                                    <input style={{ visibility: "hidden", width: "1px", height: "1px" }} id="openEndAnswerImg" type="file" name="openEndAnswerImg" onChange={this.imageUrl} />
                                                    <a className="media_img" onClick={() => this.quesImg('openEndAnswerImg')} ><img src={Media} />  {this.getImageName(this.state.openEndAnswerImg.replace(/^.*[\\\/]/, ''))} </a>
                                                </li>
                                            </>
                                        }
                                    </ul>
                                </div>
                                {/* </li> */}
                                {/* </ul> */}
                            </div>

                            <div className="uk-modal-footer uk-text-right bg_color_gry modal_footer">
                                <div className="time_select">
                                    <span><img src={clock} /></span>
                                    <select className="uk-select" id="mcqDuration" name="mcqDuration" value={this.state.mcqDuration} onChange={this.updateFormField}>
                                        <option value="">Duration</option>
                                        <option value="30">30 Seconds</option>
                                        <option value="40">40 Seconds</option>
                                    </select>
                                </div>
                                <div className="">
                                    <button className="uk-button uk-button-default uk-modal-close comn_btn cls_btn" type="button" onClick={this.onCancel}>Cancel</button>
                                    <button className="uk-button uk-button-primary comn_btn save_btn_grn" type="button" onClick={() => this.saveAssessmentQuestionGlobal()}><img src={SaveW} /> {this.state.editFlag === false ? "Save" : "Edit"}</button>
                                    {/* <button className="uk-button uk-button-primary comn_btn save_btn_grn" type="button" onClick={this.saveAssessmentQuestionMcq}><img src={SaveW} /> Save</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* --------------------------- CREATE QUESTION MODAL - START --------------------------- */}


                    {/* -----------------Edit quiz  modal----------------------------------------------------------- */}
                    <div id="updatequizmodal" data-uk-modal>
                        <div className="uk-modal-dialog uk-margin-auto-vertical uk-modal-body create_quize_modal">
                            <h2 className="quize_heading"><img src={Quiz} />Update Quiz</h2>
                            <form className="uk-form-stacked" onSubmit={this.onSubmit}>
                                <div className="uk-child-width-1-2" data-uk-grid>
                                    <div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label" for="form-stacked-select1">Select a Board</label>
                                            <div className="uk-form-controls">
                                                <select className="uk-select" id="boardId" name="boardId" value={this.state.board_id} onChange={this.editQuizUpdate}>
                                                    <option>Select Board</option>
                                                    {this.state.boardListArr.map((i, e) => {
                                                        return (
                                                            <option value={i._id} data-valname={i.boardName}>{i.boardName}</option>
                                                        )
                                                    })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <label className="uk-form-label" for="form-stacked-select2">Select Grade</label>
                                        <div className="uk-form-controls">
                                            <select className="uk-select" id="gradeId" name="gradeId" value={this.state.grade_id} onChange={this.editQuizUpdate}>
                                                <option>Select Grade</option>
                                                {this.state.gradeListArr.map((i, e) => {
                                                    return (
                                                        <option value={i._id} data-valname={i.value}>{i.grade_roman}</option>
                                                    )
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                {/* <div className="uk-child-width-1" data-uk-grid>
                                    <label className="uk-form-label" for="form-stacked-select2">
                                        Select Subject</label>
                                    <ul className="uk-margin chek_list">
                                        {this.state.subjectListArr.map((i, e) => {
                                            return (
                                                <li>
                                                    <input className="uk-radio" type="radio" name="subjectName" id={i._id} value={i.subject_name} onChange={this.editQuizUpdate} />
                                                    <label htmlFor={i._id}>{i.subject_name}</label>
                                                </li>
                                            )
                                        })}

                                    </ul>
                                </div> */}
                                <div className="uk-child-width-1" data-uk-grid>
                                    <label className="uk-form-label" for="form-stacked-select2">
                                        Select Subject</label>
                                    {/* <ul className="uk-margin chek_list">
                                        {this.state.subjectListArr.map((i, e) => {
                                            return (
                                                <li>
                                                    <input className="uk-radio" type="radio" name="subjectName" id={i._id} value={i.subject_name} onChange={this.handleFromUpdate} />
                                                    <label htmlFor={i._id}>{i.subject_name}</label>
                                                </li> 
                                            )
                                        })}
                                    </ul> */}
                                </div>
                                <div className="select_subject">
                                    {this.state.subjectListArr.map((i, e) => {
                                        return (
                                            <span style={{ padding: '2px' }}>
                                                <label htmlFor={i._id}>
                                                    <input style={{ marginRight: 4 }} type="radio" htmlFor={i._id}  id={i._id} name="subjectName" value={i.subject_name} onChange={this.editQuizUpdate} checked={this.state.subjectName === i.subject_name} /> 
                                                    <strong>{i.subject_name}</strong> 
                                                </label>
                                                {/* <input type="radio" id={i._id} name="subjectName" value={i.subject_name} onChange={this.editQuizUpdate} checked={this.state.subjectName === i.subject_name} />
                                                <label htmlFor={i._id} style={{ marginLeft: 4 }}>{i.subject_name}</label> */}
                                            </span>
                                        )
                                    })
                                    }
                                </div>
                                <div className="uk-margin">
                                    <label className="uk-form-label" for="form-stacked-text">Name this quiz</label>
                                    <div className="uk-form-controls">
                                        <input className="uk-input" id="form-stacked-text" type="text" name="quizName" value={this.state.quizName} placeholder="Quiz Name" onChange={this.editQuizUpdate} />
                                    </div>
                                </div>
                                {/* <div className="uk-margin">
                                    <label className="uk-form-label" for="form-stacked-text">Name this quiz</label>
                                    <div className="uk-form-controls">
                                        <input className="uk-input" id="form-stacked-text" type="text" name="quizName" value={this.state.quizName} placeholder="Quiz Name" onChange={this.editQuizUpdate} />
                                    </div>
                                </div> */}
                                {/* <div className="uk-margin">
                                <label className="uk-form-label" for="form-stacked-text"> Quiz Duration</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" id="form-stacked-text" type="text" name="duration" value={this.state.duration} placeholder="Quiz Duration"  />
                                </div>
                            </div> */}
                                <div className="uk-child-width-1-2" data-uk-grid>
                                    <div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label" for="form-stacked-select1">Quiz Duration</label>
                                            <div className="uk-form-controls">
                                                <select className="uk-select" id="duration" name="duration" value={this.state.duration} onChange={this.editQuizUpdate}>
                                                    <option>Select</option>
                                                    <option value="30">30</option>
                                                    <option value="45">45</option>
                                                    {/* {this.state.boardListArr.map((i, e) => {
                                                        return (
                                                            <option value={i._id} data-valname={i.boardName}>{i.boardName}</option>
                                                        )
                                                    })
                                                    } */}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label" for="form-stacked-select1">Publish</label>
                                            <div className="uk-form-controls">
                                                <select className="uk-select" id="publishflg" name="publishflg" value={this.state.publishflg} onChange={this.editQuizUpdate}>
                                                    <option>Select</option>
                                                    <option value={1}>Yes</option>
                                                    <option value={0}>No</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="uk-margin">
                                    <label className="uk-form-label" for="form-stacked-text">Quiz Description</label>
                                    <div className="uk-form-controls">
                                        {/* <input className="uk-input" id="form-stacked-text" type="text" name="description" value={this.state.description} placeholder="Quiz Description" onChange={this.handleFromUpdate} /> */}
                                        <textarea class="uk-textarea" rows="5" id="description" name="description" value={this.state.description} placeholder="Quiz Description" onChange={this.editQuizUpdate}></textarea>
                                    </div>
                                </div>

                                <div class="uk-modal-footer uk-text-right">
                                    <button className="uk-button uk-button-default uk-modal-close" type="button" onChange={this.onCancel} >Cancel</button>
                                    <button className="uk-button uk-button-default"   >Edit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </SiteLayout>
        )
    }
}

export default withRouter(QuizDetail)
