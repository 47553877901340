import Actions from "../constants/Actions";
import * as moment from "moment";

const initialState = {
    producers: [],
    lastLeave: null,
    roomID: null,
    consumers: [],
    consumersTimestamp: null,
    peers: {},
    increment: 0,
    callIncrement: 0,
    callData: null,
    answerIncrement: 0,
    answerData: 0,
    lastLeaveType: 'leave',
    counterpart: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.RTC_PRODUCER:
            return {
                ...state,
                producers: [...state.producers, action.data],
            };
        case Actions.RTC_PRODUCERS:
            return {
                ...state,
                producers: [...state.producers, ...action.producers],
            };
        case Actions.RTC_RESET_PRODUCERS:
            return {
                ...state,
                producers: [...action.producers],
                lastLeave: action.producerID || action.socketID,
                lastLeaveType: action.lastLeaveType || 'leave',
                increment: state.increment + 1,
            };
        case Actions.RTC_ROOM_ID:
            return {
                ...state,
                roomID: action.roomID,
            };
        case Actions.RTC_CONSUMERS:
            console.log(`Inside RTC Reducer :: ${state.consumers}`);
            console.log(`Inside RTC Reducer 111 :: ${action.consumers.content}`);
            if (state.consumersTimestamp && moment(state.consumersTimestamp).isAfter(moment(action.consumers.timestamp))) return state;
            if (!action.consumers.content) return state;
            return {
                ...state,
                consumers: action.consumers.content,
                peers: action.peers || state.peers,
                consumersTimestamp: action.consumers.timestamp,
            };
        case Actions.RTC_NEW_PEER:
            console.log(`Inside RTC New Peer :: ${state.consumers}`);
            return {
                ...state,
                peers: {
                    ...state.peers,
                    [action.data.socketID]: action.data,
                },
            };
        case Actions.RTC_CALL:
            console.log(`Inside RTC Call :: ${state.consumers}`);
            return {
                ...state,
                callIncrement: state.callIncrement + 1,
                callData: action.data,
            };
        case Actions.RTC_ANSWER:
            console.log(`Inside RTC Answer :: ${state.consumers}`);
            return {
                ...state,
                answerIncrement: state.answerIncrement + 1,
                answerData: action.data,
            };
        case Actions.RTC_SET_COUNTERPART:
            console.log(`Inside RTC Set Counterpart :: ${state.consumers}`);
            return {
                ...state,
                counterpart: action.counterpart,
            };
        case Actions.RTC_LEAVE:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
