import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import SiteLayout from 'layouts/SiteLayout';
import challenge from 'img/Challenge.svg';
import liveWhite from 'img/live-white.svg';
import assigned from 'img/assigned.svg';
import * as moment from 'moment'

/*Calender dependancies*/
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';

export class Reports extends Component {
    constructor(props) {
        super(props)

        this.state = {
            quizArr: [],
            allGradesArr: [],
            allDatesArr: [],
            showItems: 10,
            grade: '',
            date: '',
            currentPage: 1,
            loader:false,
            endDate: new Date(),
            startDate: new Date()
        }
    }

    getDetailsAsmt = (selectedRows) => {
        localStorage.setItem('selectedQuizId', selectedRows.id);
        localStorage.setItem('selectedQuizName', selectedRows.quizName);
        localStorage.setItem('selectedType', selectedRows.type);
        localStorage.setItem('selectedQuizParticipants', selectedRows.participants);
        localStorage.setItem('selectedQuizAccuracy', selectedRows.accuracy);
        localStorage.setItem('selectedQuiztotalQuestion', selectedRows.totalQuestion);
        this.props.history.push('/report-details');
    }

    getData = async (page) => {
        try {
            let userObj = JSON.parse(localStorage.getItem('user'));
            // userObj.id = 8901234
            // userObj.schoolId = 1
            console.log(userObj);
            // localStorage.setItem('user', JSON.stringify(userObj));

            // let teacherId = 8901234;
            // let schoolId = 1;
            let teacherId = userObj.id; 
            let schoolId = userObj.schoolId; 
            let reqObj = {
                teacherId: teacherId,
                schoolId: schoolId,
                page: page,
                date: this.state.date,
                grade: this.state.grade
            }
            let url = 'https://2juw9d7jic.execute-api.ap-south-1.amazonaws.com/PROD/viewallquiz'
            let result = await axios.post(url, reqObj)
            console.log(result);
            console.log(result.data.quiz);
            console.log(result.data.totalQuiz);

            this.setState({
                quizArr: Array.isArray(result.data.quiz) == true ? result.data.quiz : [],
                allGradesArr: Array.isArray(result.data.totalGrade) == true ? result.data.totalGrade : [],
                allDatesArr: Array.isArray(result.data.totalDates) == true ? result.data.totalDates : [],
                totalQuizCount: result.data.totalQuiz,
                loader:false
            })
        }
        catch (e) {
            console.log(e);
        }
    }


    getDataOnChange = async (page, grade, date) => {
        try {
            let userObj = JSON.parse(localStorage.getItem('user'));
            userObj.id = 8901234
            userObj.schoolId = 1
            console.log(userObj);
            localStorage.setItem('user', JSON.stringify(userObj));

            // let teacherId = 8901234;
            // let schoolId = 1;
            let teacherId = userObj.id; 
            let schoolId = userObj.schoolId; 
            let reqObj = {
                teacherId: teacherId,
                schoolId: schoolId,
                page: page,
                date: date,
                grade: grade
            }
            let url = 'https://2juw9d7jic.execute-api.ap-south-1.amazonaws.com/PROD/viewallquiz'
            let result = await axios.post(url, reqObj)
            console.log(result);
            console.log(result.data.quiz);

            this.setState({
                quizArr: Array.isArray(result.data.quiz) == true ? result.data.quiz : [],
            })
        }
        catch (e) {
            console.log(e);
        }
    }

    changeFilterGrade = async (e) => {
        e.preventDefault()
        // console.log("test",e.target);
        // console.log("test",e.target.value);
        console.log("test", e.target.getAttribute('value'));
        let grade = e.target.getAttribute('value')
        await this.setState({ grade: grade })
        console.log(this.state.currentPage);
        this.getDataOnChange(this.state.currentPage, grade)

    }
    changeFilterDate = async (e) => {
        e.preventDefault()
        // console.log("test",e.target);
        // console.log("test",e.target.value);
        console.log("test", e.target.getAttribute('value'));
        let date = e.target.getAttribute('value')
        await this.setState({ date: date })
        this.getDataOnChange(this.state.currentPage, this.state.grade, date)

    }

    handleButtonClick(e) {
        console.log('click left button', e);
    }

    handleMenuClick(e) {
        console.log('click', e);
    }

    componentDidMount() {
        this.setState({
            loader:true
        })
        this.getData(1);
    }

    handleClick = (e) => {
        this.getData(e.target.id)
        this.setState({
            currentPage: Number(e.target.id),
        });
    }

    handleNextClick = (e) => {
        let curnum = this.state.currentPage
        let finalNum = curnum + 1
        this.getData(finalNum)
        this.setState({
            currentPage: finalNum,
        });
    }

    handlePrevClick = (e) => {
        let curnum = this.state.currentPage
        let finalNum = curnum - 1
        this.getData(finalNum)
        this.setState({
            currentPage: finalNum,
        });
    }

    resetFilters = () =>{
        this.setState({
            date: '',
            grade: '',
            startDate:'',
            endDate:''
        },()=>{
            this.getData(1)
        })
       
    }

    handleDateSelect = (e) =>{
        console.log("calendar date changed : ", e);
        this.setState({
            startDate:e.selection.startDate,
            endDate:e.selection.endDate
        });
        var startDate = moment(e.selection.startDate).format('YYYY-MM-DD')
        var endDate = moment(e.selection.endDate).format('YYYY-MM-DD')
        console.log(startDate,endDate);

        let date = startDate +','+ endDate
        console.log(date);
        this.getDataOnChange(this.state.currentPage, this.state.grade, date)
    }



    render() {
        let pagination = Math.ceil(this.state.totalQuizCount / 10)
        const pageNumbers = [];
        let count = 0
        for (let i = 1; i <= pagination; i++) {
            count++
            pageNumbers.push(count);
        }
        const selectionRange = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            key: 'selection',
        }
        return (
            <SiteLayout>

                <div className="uk-container uk-container-small">
                    <div className="dropdown_sec">
                        <div className="drop_box">
                            <button className="uk-button uk-button-default" type="button">{this.state.grade !== '' ? this.state.grade:"Filter by class"} <span uk-icon="icon: chevron-down; ratio: 1"></span> </button>
                            <div data-uk-dropdown>
                                <ul className="uk-nav uk-dropdown-nav" name="grade" value={this.state.grade}>
                                    {this.state.allGradesArr.map((i, e) => {
                                        return (
                                            <li><a name="grade" value={i.grade} onClick={this.changeFilterGrade}>{i.grade}</a></li>
                                        )
                                    })}

                                    {/* // <li className="uk-active"><a href="#">Active</a></li>
                                    // <li><a href="#">Item</a></li>
                                    // <li><a href="#">Item</a></li>
                                    // <li><a href="#">Item</a></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="drop_box">
                            <button className="uk-button uk-button-default" type="button">{this.state.date !== '' ? this.state.date :"Filter by date"} <span uk-icon="icon: chevron-down; ratio: 1"></span> </button>
                            <div data-uk-dropdown className="datePicker">
                                <DateRangePicker
                                    showSelectionPreview={false} 
                                    showDateDisplay={false}                               
                                    ranges={[selectionRange]}
                                    onChange={this.handleDateSelect}
                                />
                                {/* <ul className="uk-nav uk-dropdown-nav">
                                    {this.state.allDatesArr.map((i, e) => {
                                        return (
                                            <li><a name="date" value={i} onClick={this.changeFilterDate}>{i}</a></li>
                                        )
                                    })} */}
                                    {/* // <li className="uk-active"><a href="#">Active</a></li>
                                    // <li><a href="#">Item</a></li>
                                    // <li><a href="#">Item</a></li>
                                    // <li><a href="#">Item</a></li> */}
                                {/* </ul> */}
                            </div>
                        </div>
                        <div className="drop_box">
                            <button className="uk-button uk-button-default" type="button" onClick={this.resetFilters}>
                                Reset
                            </button>
                        </div>
                    </div>
                    {/* dropdown_sec end */}
                    <div className="uk-overflow-auto">
                        <table className="uk-table uk-table-small uk-table-divider report_table">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Quiz Name</th>
                                    <th>Participants</th>
                                    <th>Accuracy</th>
                                    {/* <th>Code</th> */}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loader === false  ? this.state.quizArr.slice(0, this.state.showItems).map((i, e) => {
                                    return (
                                        <tr onClick={() => this.getDetailsAsmt(i)} key={e}>
                                            <td>
                                                <div className="name_icon">
                                                    <span className="bg_grn"><img src={challenge} /></span>
                                                    <p className="rep_text_grn">{i.quizName.split("_")[1]}</p>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="quize_name">{i.quizName}
                                                    {/* <span><strong>Running - </strong> started 3 minutes ago</span> */}
                                                </div>
                                            </td>
                                            <td>{i.participants}</td>
                                            <td><span className={i.accuracy > 50 ? 'grn_btn' : 'red_btn'}>{i.accuracy} %</span></td>
                                            <td>
                                                <button className="dots_div" type="button" onClick={() => this.getDetailsAsmt(i)}>...</button>
                                            </td>
                                        </tr>
                                    )
                                }): <div uk-spinner="ratio: 3" style={{  position: "absolute",
                                height: "100px",
                                width: "100px",
                                top: "50%",
                                left: "50%",
                                marginLeft: "-50px",
                                marginTop: "-50px",
                                backgroundSize: "100%"}}></div>}
                            </tbody>
                        </table>
                    </div>
                    {/* <ul className="uk-pagination uk-margin-top" uk-margin>
                        <li><a ><span data-uk-pagination-previous></span></a></li>
                        <li className="uk-active"><a >1</a></li>
                        <li><a ><span data-uk-pagination-next></span></a></li>
                    </ul> */}
                    <ul className="uk-pagination" uk-margin>
                        <li><a onClick={this.handlePrevClick} disabled={this.state.currentPage === 1 ? true : false}><span data-uk-pagination-previous></span></a></li>
                        {pageNumbers.map(number => {
                            console.log(this.state.currentPage);
                            console.log(number);

                            return (
                                <li
                                    className={this.state.currentPage === number ? `uk-active` : ''}
                                    key={number}
                                    id={number}
                                    value={number}
                                    onClick={this.handleClick}
                                ><a id={number} onClick={this.handleClick}>{number}</a></li>
                            );
                        })}
                        <li><a onClick={this.handleNextClick} disabled={this.state.currentPage === pageNumbers.length ? true : false}><span data-uk-pagination-next></span></a></li>
                    </ul>
                </div>
            </SiteLayout>
        )
    }
}

export default withRouter(Reports);
