import React, {useEffect} from 'react';
import {getGlobal, useGlobal} from 'reactn';
import { ToastProvider } from 'react-toast-notifications';
import './App.sass';
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Home from './pages/Home';
import Login from './pages/Login';
import Reports from './pages/Reports';
import ReportDetail from './pages/DetailReport'
import Assessments from './pages/Assessments'
import LiveClass from './pages/LiveClass'
import Chat from './pages/Chat'
import Drive from './pages/Drive'
import Doubts from './pages/Doubts'
import CreateQuiz from './pages/CreateQuiz';
import QuizDetail from './pages/CreateQuiz/components/QuizDetail'
import QuizListing from './pages/CreateQuiz/components/QuizListing'
import AssessmentSec from './pages/AssessmentSec';
import BoardCastClass from './pages/BroadCastClass';
import Config from './config';
import {useDispatch, useSelector} from "react-redux";
import loadEmojis from "./actions/loadEmojis";

import ClassRoomsHome from './pages/ClassRooms';

const App = () => {
    const dispatch = useDispatch();
    const io = useSelector(state => state.io.io);

    const token = useGlobal('token')[0];
    const setStartingPoint = useGlobal('entryPath')[1];

    const toHome = token && <Redirect to="/" />;
    const toLogin = !token && <Redirect to="/login" />;

    if (!['dark', 'light'].includes(Config.theme)) Config.theme = 'light';

    if (!window.loaded) {
        setStartingPoint(window.location.pathname);
        window.loaded = true
    }

    useEffect(() => {
        if (!io || !getGlobal().user || !token) return;
        let focusCount = 0;
        let interval = setInterval(() => {
            if (!document.hasFocus()) {
                focusCount++;
                if (focusCount === 10) {
                    io.emit('status', {status: 'away'});
                }
            }
            else if (focusCount !== 0) {
                focusCount = 0;
                io.emit('status', {status: 'online'});
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [io, token]);

    useEffect(() => {
        return () => {
            try {
                if (getGlobal().audioStream) getGlobal().audioStream.getTracks().forEach(track => track.stop());
            } catch (e) {}
            try {
                if (getGlobal().videoStream) getGlobal().videoStream.getTracks().forEach(track => track.stop());
            } catch (e) {}
        }
    }, []);

    return (
        <ToastProvider
            autoDismiss
            autoDismissTimeout={6000}
            placement="top-right"
        >
                                    {/* <Route exact path="/home/live-class" component={LiveClass} /> */}
                        {/* <Route exact path="/home/chat" component={Chat} /> */}
                        {/* <Route exact path="/home/drive" component={Drive} /> */}
                        {/* <Route exact path="/home/assessments" component={Assessments} /> */}
                        {/* <Route exact path="/home/doubts" component={Doubts} /> */}
                        {/* <Route exact path="/home/reports" component={Reports} />
                        <Route path="/home/report-detail" component={ReportDetail} /> */}
            <div className={`theme ${Config.theme}`}>
                <Router>
                    <Switch>
                        <Route exact path="/login">
                            {toHome}
                            {!toHome && <Login/>}
                        </Route>
                        <Route exact path="/reports">
                            {toLogin}
                            {!toLogin && <Reports/>}
                        </Route> 
                        <Route exact path="/create-quiz">
                            {toLogin}
                            {!toLogin && <CreateQuiz/>}
                        </Route>
                        <Route exact path="/quiz-detail">
                            {toLogin}
                            {!toLogin && <QuizDetail />}
                        </Route>
                       <Route exact path="/quiz-listing">
                            {toLogin}
                            {!toLogin && <QuizListing />}
                        </Route>
                         <Route exact path="/assessment-quiz">
                            {toLogin}
                            {!toLogin && <AssessmentSec />}
                        </Route>
                        <Route exact path="/report-details">
                            {toLogin}
                            {!toLogin && <ReportDetail/>}
                        </Route>
                        <Route exact path="/live-class">
                            {toLogin}
                            {!toLogin && <QuizListing/>}
                        </Route>
                        {/* <Route exact path="/live-class">
                            {toLogin}
                            {!toLogin && <LiveClass/>}
                        </Route> */}
                        <Route exact path="/chat">
                            {toLogin}
                            {!toLogin && <Chat/>}
                        </Route>
                        <Route exact path="/drive">
                            {toLogin}
                            {!toLogin && <Drive/>}
                        </Route>
                        <Route exact path="/assessments">
                            {toLogin}
                            {!toLogin && <Assessments/>}
                        </Route>
                        <Route exact path="/doubts">
                            {toLogin}
                            {!toLogin && <Doubts/>}
                        </Route>
                        {/* <Route exact path="/doubts">
                            {toLogin}
                            {!toLogin && <Reports/>}

                        </Route> */}
                        <Route exact path="/class-rooms/*">

                            {toLogin}
                            {!toLogin && <ClassRoomsHome/>}
                        </Route>
                        <Route exact path="/class-rooms/*">
                            {toLogin}
                            {!toLogin && <ClassRoomsHome/>}
                        </Route>
                        <Route exact path="/home/doubts">
                            {toLogin}
                            {!toLogin && <Doubts/>}
                        </Route>
                        <Route exact path="/home/doubts/*">
                            {toLogin}
                            {!toLogin && <Doubts/>}
                        </Route>
                        <Route exact path="/broadcast">
                            {toLogin}
                            {!toLogin && <BoardCastClass />}
                        </Route>
                        <Route path="/">
                            {toLogin}
                            {!toLogin && <Home/>}
                        </Route>
                    </Switch>
                </Router>
            </div>
        </ToastProvider>
    );
}

export default App;
